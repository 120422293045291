import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonLabel, IonList, 
  IonItem, IonButtons, IonButton, IonInput, IonSelect, IonSelectOption, IonIcon,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
  IonHeader, IonToolbar, IonSegment, IonSegmentButton, ModalController, IonModal } from '@ionic/angular/standalone';
import { MatInputModule } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { EntiteCompteur, PlageTarifaire, PlageTarifairePlage, PlageTarifairePlageHC, PlageTarifairePlagePointe } from 'src/app/services/EntiteProjet';
import { ProjetService } from 'src/app/services/projet.service';
import { ProjetPage } from 'src/app/Pages/projet/projet.page';

@Component({
  selector: 'app-options-tarifaires',
  templateUrl: './options-tarifaires.component.html',
  styleUrls: ['./options-tarifaires.component.scss'],
  standalone: true,
  imports: [
    CommonModule, MatFormFieldModule, MatInputModule, MatCheckbox, MatRadioButton, MatRadioGroup,
    IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonButtons,
    IonLabel, IonList, IonItem, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon, 
    IonHeader, IonToolbar, IonSegment, IonSegmentButton, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonModal 
  ],
})
export class OptionsTarifairesComponent  implements OnInit {

  @Input() id_compteur!: string;
  libelleTarif: string = 'BASE';
  choixTarif: number = 0;
  compteur: EntiteCompteur | null = null;
  couleurIconeSaisie: string = 'primary';

  HC1debVal = '22:00';
  HC1finVal = '06:00';
  HC2debVal = '12:00';
  HC2finVal = '17:00';

  P1debVal = '22:00';
  P1finVal = '06:00';
  P2debVal = '12:00';
  P2finVal = '17:00';

  tarifBASE = 0;
  tarifHC = 0;
  tarifHP = 0;
  tarifP = 0;
  tarifHCB = 0;
  tarifHPB = 0;
  tarifHCH = 0;
  tarifHPH = 0;
  
  @ViewChild(IonSelect) optionTarif!: IonSelect;
  @ViewChild('HC1deb') HC1deb!: ElementRef;
  @ViewChild('HC1fin') HC1fin!: ElementRef;
  @ViewChild('HC2deb') HC2deb!: ElementRef;
  @ViewChild('HC2fin') HC2fin!: ElementRef;

  @ViewChild('P1deb') P1deb!: ElementRef;
  @ViewChild('P1fin') P1fin!: ElementRef;
  @ViewChild('P2deb') P2deb!: ElementRef;
  @ViewChild('P2fin') P2fin!: ElementRef;

  @ViewChild('tarif_BASE') tarif_BASE!: ElementRef;
  @ViewChild('tarif_HC') tarif_HC!: ElementRef;
  @ViewChild('tarif_HP') tarif_HP!: ElementRef;
  @ViewChild('tarif_HCH') tarif_HCH!: ElementRef;
  @ViewChild('tarif_HPH') tarif_HPH!: ElementRef;
  @ViewChild('tarif_HCB') tarif_HCB!: ElementRef;
  @ViewChild('tarif_HPB') tarif_HPB!: ElementRef;
  @ViewChild('tarif_P') tarif_P!: ElementRef;

  constructor(protected _parent: ProjetPage, private modalCtrl: ModalController, private ps: ProjetService) {}

  ngOnInit() {
    //console.log('ngOnInit => Id compteur :', this.id_compteur);
    if (parseInt(this.id_compteur) > 0) {
      this.ps.getCompteur(parseInt(this.id_compteur)).then(data => {
        this.compteur = data;
        //console.log('ngOnInit => Compteur ' + this.id_compteur + ' récupéré :', this.compteur);
        if (data?.plage_tarifaire)
          this.initialiseInterface(data.plage_tarifaire, data.tarif_fourniture);
        //console.log('tarif_fourniture est un tableau ?', Array.isArray(data?.tarif_fourniture));
        if ( Array.isArray(data?.tarif_fourniture) && data.tarif_fourniture.length == 0)
          this.couleurIconeSaisie = 'danger';
      });
    }
    else
      this.ajusteInterface(this.libelleTarif);
  }

  async onWillDismiss(ev: Event) {
    //console.log('Dissmiss', ev);
    await this.modalCtrl.dismiss(null, 'annule');
  }

  initialiseInterface(plages: PlageTarifaire[], tarifs: any) {
    //console.log('initialiseInterface =>', plages);
    this.libelleTarif = 'BASE';
    if (plages.length > 0) {
      plages.forEach(p => {
        //console.log('initialiseInterface =>', p);
        if (p.libelle != this.libelleTarif)
          this.libelleTarif = p.libelle;
        if (p.libelle == 'HP/HC') {
          this.choixTarif = 1;
          let phc1 = p.plages[0] as PlageTarifairePlageHC;
          this.HC1debVal = phc1.deb;
          this.HC1finVal = phc1.fin;
          if (p.plages.length > 2)
            this.secondeHC = true;
            let phc2 = p.plages[1] as PlageTarifairePlageHC;
            this.HC2debVal = phc2.deb;
            this.HC2finVal = phc2.fin;
        }
        if (p.saison == 'POINTE') {
          this.choixPointeF = true;
          this.pointeBool = true;
          let pp1 = p.plages[0] as PlageTarifairePlagePointe;
          this.P1debVal = pp1.deb;
          this.P1finVal = pp1.fin;
          if (p.plages.length > 1) {
            this.secondePointe = true;
            let pp2 = p.plages[1] as PlageTarifairePlagePointe;
            this.P2debVal = pp2.deb;
            this.P2finVal = pp2.fin;
          }
        } else if (p.saison == 'HAUTE') {
          this.choixTarif = 2;
          let phc1 = p.plages[0] as PlageTarifairePlageHC;
          this.HC1debVal = phc1.deb;
          this.HC1finVal = phc1.fin;
          if (p.plages.length > 2) {
            this.secondeHC = true;
            let phc2 = p.plages[1] as PlageTarifairePlageHC;
            this.HC2debVal = phc2.deb;
            this.HC2finVal = phc2.fin;
          }
        }
      });
    }
    if (tarifs.BASE)
      this.tarifBASE = tarifs.BASE;
    if (tarifs.HC)
      this.tarifHC = tarifs.HC;
    if (tarifs.HP)
      this.tarifHP = tarifs.HP;
    if (tarifs.HCH)
      this.tarifHCH = tarifs.HCH;
    if (tarifs.HPH)
      this.tarifHPH = tarifs.HPH;
    if (tarifs.HCB)
      this.tarifHCB = tarifs.HCB;
    if (tarifs.HPB)
      this.tarifHPB = tarifs.HPB;
    if (tarifs.P)
      this.tarifP = tarifs.P;
  }

  async valideOption() {
    //console.log('valideOption', this.optionTarif.value);
    const option = this.optionTarif.value;
    this.libelleTarif = option;
    let plage_tarifaire: any = null;
    let tarifs: any = {};
    if (option == 'HP/HC') {
      let plage: PlageTarifaire = {libelle: option, saison: '', periodes: [], plages: []};
      if (this.secondeHC) {
        this.HC1debVal = this.HC1deb.nativeElement.value;
        this.HC1finVal = this.HC1fin.nativeElement.value;
        this.HC2debVal = this.HC2deb.nativeElement.value;
        this.HC2finVal = this.HC2fin.nativeElement.value;
        plage.plages = [
          {'deb': this.HC1debVal, 'fin': this.HC1finVal, 'horosaison': 'HC', 'plage': 'HC'},
          {'deb': this.HC2debVal, 'fin': this.HC2finVal, 'horosaison': 'HC', 'plage': 'HC'},
          {'default': true, 'horosaison': 'HP', 'plage': 'HP'}
        ];
      }
      else {
        this.HC1debVal = this.HC1deb.nativeElement.value;
        this.HC1finVal = this.HC1fin.nativeElement.value;
        plage.plages = [
          {'deb': this.HC1debVal, 'fin': this.HC1finVal, 'horosaison': 'HC', 'plage': 'HC'},
          {'default': true, 'horosaison': 'HP', 'plage': 'HP'}
        ];
      }
      //console.log('valideOption => JSON généré :', plage);
      plage_tarifaire = [];
      plage_tarifaire.push(plage);
      tarifs.HC = parseFloat(this.tarif_HC.nativeElement.value);
      tarifs.HP = parseFloat(this.tarif_HP.nativeElement.value);
      this.tarifHC = tarifs.HC;
      this.tarifHP = tarifs.HP;
    }
    else if (option == 'P/HCH/HPH/HCB/HPB') {
      let plage: PlageTarifaire[] = [];
      let haute: PlageTarifaire = {libelle: option, saison: 'HAUTE', periodes: [{"deb": "11-01", "fin": "04-01"}], plages: []};
      if (this.secondeHC) {
        this.HC1debVal = this.HC1deb.nativeElement.value;
        this.HC1finVal = this.HC1fin.nativeElement.value;
        this.HC2debVal = this.HC2deb.nativeElement.value;
        this.HC2finVal = this.HC2fin.nativeElement.value;
        haute.plages = [
          {'deb': this.HC1debVal, 'fin': this.HC1finVal, 'horosaison': 'HCH', 'plage': 'HC'},
          {'deb': this.HC2debVal, 'fin': this.HC2finVal, 'horosaison': 'HCH', 'plage': 'HC'},
          {'default': true, 'horosaison': 'HPH', 'plage': 'HP'}
        ];
      } else {
        this.HC1debVal = this.HC1deb.nativeElement.value;
        this.HC1finVal = this.HC1fin.nativeElement.value;
        haute.plages = [
          {'deb': this.HC1debVal, 'fin': this.HC1finVal, 'horosaison': 'HCH', 'plage': 'HC'},
          {'default': true, 'horosaison': 'HPH', 'plage': 'HP'}
        ];
      }
      // Plages Eté
      let basse: PlageTarifaire = {libelle: option, saison: 'BASSE', periodes: [], plages: []};
      if (this.secondeHC) {
        this.HC1debVal = this.HC1deb.nativeElement.value;
        this.HC1finVal = this.HC1fin.nativeElement.value;
        this.HC2debVal = this.HC2deb.nativeElement.value;
        this.HC2finVal = this.HC2fin.nativeElement.value;
        basse.plages = [
          {'deb': this.HC1debVal, 'fin': this.HC1finVal, 'horosaison': 'HCB', 'plage': 'HC'},
          {'deb': this.HC2debVal, 'fin': this.HC2finVal, 'horosaison': 'HCB', 'plage': 'HC'},
          {'default': true, 'horosaison': 'HPB', 'plage': 'HP'}
        ];
      } else {
        basse.plages = [
          {'deb': this.HC1debVal, 'fin': this.HC1finVal, 'horosaison': 'HCB', 'plage': 'HC'},
          {'default': true, 'horosaison': 'HPB', 'plage': 'HP'}
        ];
      }
      // Pointe fixe
      if (this.choixPointeF) {
        let pointe: PlageTarifaire = {libelle: option, saison: 'POINTE', periodes: [{"deb": "12-01", "fin": "03-01"}], plages: []};
        if (this.secondePointe) {
          this.P1debVal = this.P1deb.nativeElement.value;
          this.P1finVal = this.P1fin.nativeElement.value;
          this.P2debVal = this.P2deb.nativeElement.value;
          this.P2finVal = this.P2fin.nativeElement.value;
          pointe.plages = [
            { deb: this.P1debVal, fin: this.P1finVal, horosaison: 'POINTE', plage: 'POINTE', jours: [1,2,3,4,5,6] },
            { deb: this.P2debVal, fin: this.P2finVal, horosaison: 'POINTE', plage: 'POINTE', jours: [1,2,3,4,5,6] },
          ];
        } else {
          this.P1debVal = this.P1deb.nativeElement.value;
          this.P1finVal = this.P1fin.nativeElement.value;
          pointe.plages = [
            { deb: this.P1debVal, fin: this.P1finVal, horosaison: 'POINTE', plage: 'POINTE', jours: [1,2,3,4,5,6] }
          ];
        }
        plage.push(pointe);
      }
      plage.push(haute);
      plage.push(basse);
      //console.log('valideOption => JSON généré :', plage);
      plage_tarifaire = plage;
      if (this.choixPointeF) {
        tarifs.P = parseFloat(this.tarif_P.nativeElement.value);
        this.tarifP = tarifs.P;
      }
      tarifs.HCH = parseFloat(this.tarif_HCH.nativeElement.value);
      tarifs.HPH = parseFloat(this.tarif_HPH.nativeElement.value);
      tarifs.HCB = parseFloat(this.tarif_HCB.nativeElement.value);
      tarifs.HPB = parseFloat(this.tarif_HPB.nativeElement.value);
      this.tarifHCH = tarifs.HCH;
      this.tarifHPH = tarifs.HPH;
      this.tarifHCB = tarifs.HCB;
      this.tarifHPB = tarifs.HPB;
    }
    else {  // BASE
      let plage: PlageTarifaire = {libelle: option, saison: '', periodes: [], plages: [{ default: true, horosaison: 'BASE', plage: 'BASE'}]};
      //console.log('valideOption => JSON généré :', plage);
      plage_tarifaire = [];
      plage_tarifaire.push(plage);
      tarifs.BASE = parseFloat(this.tarif_BASE.nativeElement.value);
      this.tarifBASE = tarifs.BASE;
    }
    
    if (this.compteur) {
      let data = {
        plage_tarifaire: plage_tarifaire,
        tarif_fourniture: tarifs
      } as any;
      this.ps.majDataCompteur(parseInt(this.id_compteur), data).then(res => {
        if (res) {
          this.compteur = res;
          this.couleurIconeSaisie = 'primary';
          this._parent.mapCompteurs.set(res.id, res);
          this._parent.getStepProjetState('fichiers');
        }
      });
    }
    await this.modalCtrl.dismiss(null, 'annule');
  }

  ajusteInterface(mode: string) {
    switch (mode) {
      case "BASE":
          this.choixTarif = 0;
          this.choixPointeF = false;
          this.secondePointe = false;
          this.pointeBool = false;
        break;
      case "HP/HC":
          this.choixTarif = 1;
          this.choixPointeF = false;
          this.secondePointe = false;
          this.pointeBool = false;
        break;
      case "P/HCH/HPH/HCB/HPB":
          this.choixTarif = 2;
        break;
      default:
        this.choixTarif = 0;
        break;
    }
  }

  choixOptionTarifaire(event : any){
    this.ajusteInterface(event.detail.value);
    this.libelleTarif = event.detail.value;
    this.couleurIconeSaisie = 'primary';
  }
  secondeHC: boolean = false;
  togglePeriode(){
    this.secondeHC = !this.secondeHC;
  }
  pointeBool: boolean = false;
  togglePointe(){
    this.pointeBool = !this.pointeBool;
    if (!this.pointeBool){
      this.choixPointeF = false;
      this.secondePointe = false;
    }
  }
  choixPointeF : boolean = false;
  choixPointe(event : any){
    switch (event.value) {
      case "pointeF":
          this.choixPointeF = true;
        break;
      case "pointeM":
        this.choixPointeF = false;
        break;
      default:
        this.choixPointeF = false;
        break;
    }
  }
  
  secondePointe: boolean = false; 
  togglePeriodePointe(){
    this.secondePointe = !this.secondePointe;
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthentificationService } from './authentification.service';
import { environment } from '../../environments/environment';
import { ConfigService, ApiActions, UploadTypes} from '../services/config.service';
import { ProjetService } from './projet.service';
import { firstValueFrom } from 'rxjs';

export type EntiteListeTurpe = {
  debutApplication: string;
  version: string;
}
export type ListeTurpe = EntiteListeTurpe[];

// Définition des composantes du Turpe selon la version
export type TurpeV6 = {
  CGC: {
    CC: number;
    CG_CARD: number;
    CG_CU: number;
  },
  CSF: {
    HCB: number;
    HCH: number;
    HPB: number;
    HPH: number;
    POINTE: number;
  },
  CSV: {
    HCB: number;
    HCH: number;
    HPB: number;
    HPH: number;
    POINTE: number;
  }
}

export type ComposanteTurpe = TurpeV6;  // | TurpeV7 pour rajouter les versions ultérieures quand elles seront définies ...;

export type EntiteTurpe = {
  id: number;
  categorie: string;
  sousCategorie: string;
  debutApplication: string;
  type: string;
  version: string;
  composantes: ComposanteTurpe;
}

export enum AcronymesTurpe {
  CSV = "Composante de Soutirage Variable",
  CSF = "Composante de Soutirage Fixe",
  CGC = "Composante de Gestion et Comptage",
}

export type EntiteTaxes = {
  accise: {date: string, valeur: number};
  CTA: {date: string, valeur: number};
  TVA: {date: string, valeur: number};
  seuil_IFER: {date: string, valeur: number};
  IFER: {date: string, valeur: number};
  IS: {date: string, valeur: {variable: string, value: number, seuil_min: number|null, seuil_max: number|null}[]};
  CVAE: {date: string, valeur: {variable: string, value: number, seuil_min: number|null, seuil_max: number|null}[]};
}

export type EntiteTaxesHisto = {
  variable: string;
  valeur: string|{variable: string, value: number, seuil_min: number|null, seuil_max: number|null}[];
  date: string;
  dateApplication: string;
  auteur : {nom : string, prenom : string};
}

export type EntiteVariableFinancieres = {
  taux_actualisation: {date: string, valeur: number};
  taux_emprunt:  {date: string, valeur: number};
  duree_emprunt:  {date: string, valeur: number};
  hausse_annuelle_CAPEX:  {date: string, valeur: number};
  hausse_annuelle_elec:  {date: string, valeur: number};
  baisse_annuelle_perf_module:  {date: string, valeur: number};
  puissance_min_certif_capacite:  {date: string, valeur: number};
  cout_certif_capacite:  {date: string, valeur: number};
  hausse_annuelle_marche_capacite:  {date: string, valeur: number};
  duree_contrat_OA:  {date: string, valeur: number};
  revalorisation_annuelle_OA:  {date: string, valeur: number};
  revalorisation_annuelle_OA_surplus:  {date: string, valeur: number};
  tarif_achat_OA_surplus:  {date: string, valeur: number};
  hausse_annuelle_OPEX:  {date: string, valeur: number};
}
export type HistoriqueTaxes = EntiteTaxesHisto[];

export type EntiteTarifOA = {
  tarif_achat_VT:  
  {
    date :string,
    valeur : { seuil_min : number, seuil_max : number, value : number ,variable : string}[]
  },
  tarif_rachat_surplus:  
  {
    date :string,
    valeur : 
      { seuil_min : number, seuil_max : number, value : number ,variable : string}[]
  },
  prime_investissement:
  {
    date :string,
    valeur : { seuil_min : number, seuil_max : number, value : number ,variable : string}[]
  },

  Kn: {date : string, valeur : number},
  Bn: {date : string, valeur : number},

  plafond_prod_100k:        {date : string, valeur : number},
  tarif_plafond_prod_100k:  {date : string, valeur : number},
  plafond_prod_500k:        {date : string, valeur : number},
  tarif_plafond_prod_500k:  {date : string, valeur : number}
}

export type HistoriqueTarifOA = EntiteTaxesHisto[];
@Injectable({
  providedIn: 'root'
})
export class ConfigurationApiService {
  EntiteTarifOA() {
    throw new Error('Method not implemented.');
  }

  constructor(private auth: AuthentificationService, private http: HttpClient, private conf: ConfigService, private ps: ProjetService) {} 

  // ============ TURPE ============
  async listeTurpe(): Promise<ListeTurpe | null> {
    const requestOptions = await this.ps.prepareHeaders('json');
    const res =  await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/configuration/turpe`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async detailsTurpe(date: string): Promise<EntiteTurpe[] | null> {
    const requestOptions = await this.ps.prepareHeaders('json');
    const res =  await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/configuration/turpe/${date}`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async creationTurpe(date: string, version: string): Promise<any> {
    const requestOptions = await this.ps.prepareHeaders('json');
    const data = {
      debutApplication: date,
      version: version
    };
    const res =  await firstValueFrom(this.http.post<any>(`${environment.apiHost}api/configuration/turpe`, data, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async setVariableTurpe(idTurpe: number, variable: string, valeur: number): Promise<any> {
    const requestOptions = await this.ps.prepareHeaders('json');
    let data = {} as any;
    if (variable != '')
      data[variable] = valeur;
    const res =  await firstValueFrom(this.http.put<any>(`${environment.apiHost}api/configuration/turpe/${idTurpe}`, data, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }


  // ============ Taxes ============
  async detailsTaxes(): Promise<EntiteTaxes | null> {
    const requestOptions = await this.ps.prepareHeaders('json');
    const res =  await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/configuration/variables/taxes`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      console.log(this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null));
    return null;
  }

  
  async historiqueTaxes(): Promise<HistoriqueTaxes | null> {
    const requestOptions = await this.ps.prepareHeaders('json');
    const res =  await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/configuration/variables/taxes/historique`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      console.log(this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null));
    return null;
  }

  async setVariableTaxes(accise: number, CTA: number, TVA: number,seuil_IFER: number, IFER: number, IS_T1: number, IS_T2: number, CVAE_T1: number, CVAE_T2: number, CVAE_T3: number, CVAE_T4: number, CVAE_T5: number, accise_date: string, CTA_date: string, TVA_date: string, seuil_IFER_date: string, IFER_date: string, IS_date: string, CVAE_date: string): Promise<any> {
    const requestOptions = await this.ps.prepareHeaders('json');
    const data = {
      accise: {'date' : accise_date?.substring(0, 10), 'valeur' : accise},
      CTA: {'date' : CTA_date?.substring(0, 10), 'valeur' : CTA?.toString().replace(',','.')},
      TVA: {'date' : TVA_date?.substring(0, 10), 'valeur' : TVA},
      seuil_IFER: {'date' : seuil_IFER_date?.substring(0, 10), 'valeur' : seuil_IFER},
      IFER: {'date' : IFER_date?.substring(0, 10), 'valeur' : IFER},
      IS: {'date' : IS_date?.substring(0, 10), 'valeur' : [
        {'variable' : 'IS_T1', 'value' : IS_T1, 'seuil_min' : null, 'seuil_max' : 38120},
        {'variable' : 'IS_T2', 'value' : IS_T2, 'seuil_min' : 38121, 'seuil_max' : null},
      ]},
      CVAE: {'date' : CVAE_date?.substring(0, 10), 'valeur' : [
        {'variable' : 'CVAE_T1', 'value' : CVAE_T1, 'seuil_min' : null, 'seuil_max' : 500000},
        {'variable' : 'CVAE_T2', 'value' : CVAE_T2, 'seuil_min' : 500001, 'seuil_max' : 3000000},
        {'variable' : 'CVAE_T3', 'value' : CVAE_T3, 'seuil_min' : 3000001, 'seuil_max' : 10000000},
        {'variable' : 'CVAE_T4', 'value' : CVAE_T4, 'seuil_min' : 10000001, 'seuil_max' : 50000000},
        {'variable' : 'CVAE_T5', 'value' : CVAE_T5, 'seuil_min' : 50000001, 'seuil_max' : null}
      ]}
    };
    // console.log('setVariableTaxes -> data', data);
    const res =  await firstValueFrom(this.http.post<any>(`${environment.apiHost}api/configuration/variables/taxes`, data, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }
  // ========== Variable Financieres ==========
  async detailsVariableFinancieres(): Promise<EntiteVariableFinancieres | null> {
    const requestOptions = await this.ps.prepareHeaders('json');
    const res =  await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/configuration/variables/plan-financier`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      console.log(this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null));
    return null;
  }

  async setVariableFinancieres(taux_actualisation: number, taux_emprunt: number, duree_emprunt: number,hausse_annuelle_elec: number, baisse_annuelle_perf_module: number, puissance_min_certif_capacite: number, cout_certif_capacite: number,hausse_annuelle_marche_capacite: number, duree_contrat_OA: number, revalorisation_annuelle_OA: number, revalorisation_annuelle_OA_surplus: number, tarif_achat_OA_surplus: number, hausse_annuelle_OPEX : number): Promise<any> {
    const requestOptions = await this.ps.prepareHeaders('json');
    const data = {
      taux_actualisation:  {'date' : null, 'valeur' : parseFloat(taux_actualisation.toString())},
      taux_emprunt:  {'date' : null, 'valeur' : parseFloat(taux_emprunt.toString())},
      duree_emprunt:   {'date' : null, 'valeur' : parseFloat(duree_emprunt.toString())},
      hausse_annuelle_elec:   {'date' : null, 'valeur' : parseFloat(hausse_annuelle_elec.toString())},
      baisse_annuelle_perf_module:   {'date' : null, 'valeur' : parseFloat(baisse_annuelle_perf_module.toString())},
      puissance_min_certif_capacite:   {'date' : null, 'valeur' : parseFloat(puissance_min_certif_capacite.toString())},
      cout_certif_capacite:   {'date' : null, 'valeur' : parseFloat(cout_certif_capacite.toString())},
      hausse_annuelle_marche_capacite:   {'date' : null, 'valeur' : parseFloat(hausse_annuelle_marche_capacite.toString())},
      duree_contrat_OA:   {'date' : null, 'valeur' : parseFloat(duree_contrat_OA.toString())},
      revalorisation_annuelle_OA:   {'date' : null, 'valeur' : parseFloat(revalorisation_annuelle_OA.toString())},
      revalorisation_annuelle_OA_surplus:  {'date' : null, 'valeur' : parseFloat(revalorisation_annuelle_OA_surplus.toString())},
      tarif_achat_OA_surplus:  {'date' : null, 'valeur' : parseFloat(tarif_achat_OA_surplus.toString())},
      hausse_annuelle_OPEX:  {'date' : null, 'valeur' : parseFloat(hausse_annuelle_OPEX.toString())},
    };
    // console.log('setVariableFinancieres -> data', data);
    const res =  await firstValueFrom(this.http.post<any>(`${environment.apiHost}api/configuration/variables/plan-financier`, data, requestOptions));
    // console.log('setVariableFinancieres -> res', res);
    
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }




//----------------Variable TARIF OA----------------


 async detailsVariableTarifOA(): Promise<EntiteTarifOA | null> {
   const requestOptions = await this.ps.prepareHeaders('json');
   const res =  await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/configuration/variables/tarif-OA`, requestOptions));
   if (res.statut.toUpperCase() == 'OK')
     return res.reponse;
   else
     console.log(this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null));
   return null;
 }

  async setVariableTarifOA(data : EntiteTarifOA ): Promise<EntiteTarifOA | null> {
    const requestOptions = await this.ps.prepareHeaders('json');
    
    // console.log('setVariableTarifOA -> data', data);
    const res =  await firstValueFrom(this.http.post<any>(`${environment.apiHost}api/configuration/variables/tarif-OA`, data, requestOptions));
    // console.log('setVariableTarifOA -> res', res);
    
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async historiqueTarifOA(): Promise<HistoriqueTarifOA | null> {
    const requestOptions = await this.ps.prepareHeaders('json');
    const res =  await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/configuration/variables/tarif-OA/historique`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      console.log(this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null));
    return null;
  }

  
 async detailsVariableTarifOADate(date : string): Promise<EntiteTarifOA | null> {
  // console.log("detailsVariableTarifOADate(date)", date);
  const requestOptions = await this.ps.prepareHeaders('json');
  const res =  await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/configuration/variables/tarif-OA/`+ date, requestOptions));
  if (res.statut.toUpperCase() == 'OK')
    return res.reponse;
  else
    console.log(this.ps.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null));
  return null;
}
}
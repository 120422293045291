import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonLabel, IonList, IonListHeader,
  IonItem, IonAccordionGroup, IonAccordion, IonButtons, IonButton, IonInput, IonSelect, IonSelectOption, IonIcon,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonLoading,IonCheckbox, IonSegment, 
  IonSegmentButton, IonToggle, IonDatetime, IonModal, IonPopover, IonFabButton, IonFab, IonSpinner } from '@ionic/angular/standalone';
import { ConfigurationApiService } from 'src/app/services/configuration-api.service';
import { EntiteConsommateurScenario, EntiteScenario } from 'src/app/services/EntiteProjet';
import { ProjetService } from 'src/app/services/projet.service';
import { MatRadioButton, MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-plan-financier-par-consommateur',
  templateUrl: './plan-financier-par-consommateur.component.html',
  styleUrls: ['./plan-financier-par-consommateur.component.scss'],
  standalone: true,
  imports: [IonSpinner, IonFab, IonFabButton, IonPopover, IonModal, MatRadioButton, MatRadioGroup, MatRadioModule,FormsModule,
    CommonModule, IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonButtons,
    IonLabel, IonList, IonListHeader, IonItem, IonAccordionGroup, IonAccordion, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon,
   IonSegment, IonSegmentButton, IonLoading,IonCheckbox,
    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonToggle, IonDatetime]
})

export class PlanFinancierParConsommateurComponent  implements OnInit {
  @Input() idx_scenario!: number;
  @Input() id_participant!: number;
  @Input() id_consommateur!: number;
  @Input() scenario!: EntiteScenario;
  @Input() type_projet!: string | undefined;

  @ViewChild('conso_globale_annuelle') conso_globale_annuelle!: IonInput;
  @ViewChild('hausse_annuelle_elec') hausse_annuelle_elec!: IonInput;
  @ViewChild('reduction_acc') reduction_acc!: IonInput;

  @ViewChild('tarifBASE') tarifBASE!: ElementRef;
  @ViewChild('tarifHP') tarifHP!: ElementRef;
  @ViewChild('tarifHC') tarifHC!: ElementRef;
  @ViewChild('tarifP') tarifP!: ElementRef;
  @ViewChild('tarifHPH') tarifHPH!: ElementRef;
  @ViewChild('tarifHCH') tarifHCH!: ElementRef;
  @ViewChild('tarifHPB') tarifHPB!: ElementRef;
  @ViewChild('tarifHCB') tarifHCB!: ElementRef;
  
  @ViewChild('accise_acc') accise_acc!: boolean;

  mode_valorisation!: string;
  consommateur! : EntiteConsommateurScenario;

  tarifBASE_four : number = 10;
  tarifHP_four : number = 20;
  tarifHC_four : number = 30;
  tarifP_four : number = 40;
  tarifHPH_four : number = 50;
  tarifHCH_four : number = 60;
  tarifHPB_four : number = 70;
  tarifHCB_four : number = 80;

  tarifBASE_acc! : number;
  tarifHP_acc! : number;
  tarifHC_acc! : number;
  tarifP_acc! : number;
  tarifHPH_acc! : number;
  tarifHCH_acc! : number;
  tarifHPB_acc! : number;
  tarifHCB_acc! : number;

  constructor(private cas: ConfigurationApiService, private ps: ProjetService) { 
    //console.log("composant PF constructor");
  }

  ngOnInit() {
    // console.log("composant PF");
    // console.log("scenario", this.scenario);
    // console.log("id_participant", this.id_participant);
    // console.log("id_producteur", this.id_producteur);
  }
  ngAfterViewInit() {
    this.scenario.consommateurs.forEach( conso => {
      if(conso.idConsommateur == this.id_consommateur)
      {        
        this.conso_globale_annuelle.value   = -1;  // -------------A modifier avec le retour du back quand il sera prêt
        this.mode_valorisation              = conso.tarification_acc; // A modifier tarification par valorisation avec le retour du back quand il sera prêt
        this.accise_acc                     = conso.taxes.accise_acc;
        this.hausse_annuelle_elec.value     = conso.taxes.hausse_annuelle_elec;
        this.reduction_acc.value            = conso.reduction_acc;
        // TODO -> initialiser les valeurs des tarifs acc lorsque mode_valorisation = prix
      }
    })
    this.majInterface();
  }
  majInterface(){
    if (this.mode_valorisation == "don")
      this.reduction_acc.value = 100;
    
    if(this.mode_valorisation == "prix")
    {
      this.tarifBASE_acc  = this.tarifBASE.nativeElement.value;
      this.tarifHP_acc    = this.tarifHP.nativeElement.value;
      this.tarifHC_acc    = this.tarifHC.nativeElement.value;
      this.tarifP_acc     = this.tarifP.nativeElement.value;
      this.tarifHPH_acc   = this.tarifHPH.nativeElement.value;
      this.tarifHCH_acc   = this.tarifHCH.nativeElement.value;
      this.tarifHPB_acc   = this.tarifHPB.nativeElement.value;
      this.tarifHCB_acc   = this.tarifHCB.nativeElement.value;
    }else{
      this.tarifBASE.nativeElement.value  = this.tarifBASE_acc  = this.tarifBASE_four - ((this.tarifBASE_four * <number>this.reduction_acc.value)/100);
      this.tarifHP.nativeElement.value    = this.tarifHP_acc    = this.tarifHP_four - ((this.tarifHP_four * <number>this.reduction_acc.value)/100);
      this.tarifHC.nativeElement.value    = this.tarifHC_acc    = this.tarifHC_four - ((this.tarifHC_four * <number>this.reduction_acc.value)/100);
      this.tarifP.nativeElement.value     = this.tarifP_acc     = this.tarifP_four - ((this.tarifP_four * <number>this.reduction_acc.value)/100);
      this.tarifHPH.nativeElement.value   = this.tarifHPH_acc   = this.tarifHPH_four - ((this.tarifHPH_four * <number>this.reduction_acc.value)/100);
      this.tarifHCH.nativeElement.value   = this.tarifHCH_acc   = this.tarifHCH_four - ((this.tarifHCH_four * <number>this.reduction_acc.value)/100);
      this.tarifHPB.nativeElement.value   = this.tarifHPB_acc   = this.tarifHPB_four - ((this.tarifHPB_four * <number>this.reduction_acc.value)/100);
      this.tarifHCB.nativeElement.value   = this.tarifHCB_acc   = this.tarifHCB_four - ((this.tarifHCB_four * <number>this.reduction_acc.value)/100);
    }
  }
  async enregistrerVariableFinancieres(){
    console.log("tarifBASE_acc", this.tarifBASE_acc);
    //----------------------------------------------------------------------------------------------------------------
    //TODO le code en dessous vient du composant Plan Financier par producteur, il faut l'adapter pour le consommateur
    //----------------------------------------------------------------------------------------------------------------

  //   const data = {
  //     financement : {
  //       CAPEX: this.capex.value,
  //       OPEX: this.opex.value,
  //       taux_actualisation: this.taux_actualisation.value ,
  //       taux_emprunt: this.taux_emprunt.value,
  //       duree_emprunt: this.duree_emprunt.value,
  //       hausse_annuelle_OPEX: this.hausse_annuelle_OPEX.value,
  //       IFER : this.IFER.value,
  //       coef_degradation_performance : this.coef_degradation_performance.value,
  //       duree_ammortissement : this.duree_ammortissement.value,
  //       part_apport : this.part_apport.value
  //     },
  //     revenus : {
  //       use_tarif_OA : this.use_tarif_OA.checked,
  //       tarif_achat_OA_VT : this.tarif_achat_OA_VT.value,
  //       use_complement_remuneration : this.use_complement_remuneration.checked,
  //       revalorisation_annuelle_OA : this.revalorisation_annuelle_OA.value,
  //       tarif_achat_OA_surplus : this.tarif_achat_OA_surplus.value
  //     }
  //   };
  //   console.log('setVariableFinancieres -> data', data);

  //   this.ps.majDataScenarioProducteur(this.idx_scenario, this.id_consommateur, data).then(res => {
  //     //this.taux_actualisation.value = res?.producteurs[0].financement.taux_actualisation;
  //     if (res)
  //       res.producteurs.forEach( prod => {
  //         if(prod.idProducteur == this.id_consommateur)
  //         {
  //       console.log("enregistrerVariableFinancieres", res);
  //     }});
  //   });
  }
}

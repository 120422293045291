<ion-grid>
  <ion-row style="margin-bottom: 20px;">
    <ion-button size="small" class="ion-float-left" (click)="enregistrerVariableFinancieres()" >Enregistrer</ion-button>
  </ion-row>
  <ion-row>
    <ion-col lg="3">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            Taxes
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <!-- <ion-item >
              <ion-input type="number" label="Consommation globale annuelle" labelPlacement="floating" #conso_globale_annuelle></ion-input>
            </ion-item> -->
            <ion-item >
              <ion-input type="number" label="Hausse prix élec (en %) " labelPlacement="floating" #hausse_annuelle_elec></ion-input>
            </ion-item>
            <ion-item >
              <ion-checkbox #accise_acc>Accise (part ACC)<ion-label><p>décocher pour exonérer</p></ion-label></ion-checkbox>
            </ion-item>
            <ion-item >
              <ion-label>Mode valorisation ACC</ion-label>
              <mat-radio-group [(ngModel)]="mode_valorisation" (change)="majInterface()">
                <mat-radio-button color="primary" value="reduction">Taux réduction</mat-radio-button>
                <mat-radio-button color="primary" value="prix">Prix</mat-radio-button>
                <mat-radio-button color="primary" value="don">Gratuit (don)</mat-radio-button>
              </mat-radio-group>
            </ion-item>
            <ion-item>
              @let edit_tarif_reduction = (type_projet == "ACC" && mode_valorisation == "reduction") ? true : false;
              <ion-input type="number" label="Réduction tarif ACC (en %)" labelPlacement="floating" [value]=reduction_acc #reduction_acc [disabled]="!edit_tarif_reduction" (ionChange)="majInterface()"></ion-input>
            </ion-item>
               <!-- SAISIE DES TARIFS DISTRIBUTEUR -->
              <ion-row>
                <ion-col size="8"><ion-label>Tarif du kWh (c€)</ion-label></ion-col>
                <ion-col size="2"><ion-label>Fournisseur</ion-label></ion-col>
                <ion-col size="2"><ion-label>ACC</ion-label></ion-col>
              </ion-row>
              <ion-list class="saisie-tarif" >
                @if (libelleTarif === 'BASE') {
                <ion-item>
                  <ion-col size="8"><ion-label>Base :</ion-label></ion-col>
                  <ion-col size="2"><input matInput type="number" [value]="tarifBASE_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifBASE type="number" step="0.01" matInput [disabled]="mode_valorisation != 'prix'" (blur)="majInterface()" (ngModelChange)="getValue()" [ngModel]="tarifBASE_acc_display"></ion-col>
                </ion-item>
                }
                @if (libelleTarif === 'HP/HC') {
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Pleines :</ion-label></ion-col>
                  <ion-col size="2"><input matInput type="number" [value]="tarifHP_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHP type="number" step="0.01" matInput [disabled]="mode_valorisation != 'prix'" (blur)="majInterface()" (ngModelChange)="getValue()" [ngModel]="tarifHP_acc_display"></ion-col>
                </ion-item>
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Creuses :</ion-label></ion-col>
                  <ion-col size="2"><input matInput type="number" [value]="tarifHC_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHC type="number" step="0.01" matInput [disabled]="mode_valorisation != 'prix'" (blur)="majInterface()" (ngModelChange)="getValue()" [ngModel]="tarifHC_acc_display"></ion-col>
                </ion-item>
                }
                @if (libelleTarif === 'P/HCH/HPH/HCB/HPB') {
                  @if (compteur?.tarif_fourniture.POINTE) {
                  <ion-item>
                    <ion-col size="8"><ion-label>Heures de Pointe :</ion-label></ion-col>
                    <ion-col size="2"><input matInput [value]="tarifP_four | number" disabled></ion-col>
                    <ion-col size="2"><input #tarifP type="number" step="0.01" matInput [disabled]="mode_valorisation != 'prix'" (blur)="majInterface()" (ngModelChange)="getValue()" [ngModel]="tarifP_acc_display"></ion-col>
                  </ion-item>
                }
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Pleines Haute saison :</ion-label></ion-col>
                  <ion-col size="2"><input type="number" matInput [value]="tarifHPH_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHPH type="number"  step="0.01" matInput [disabled]="mode_valorisation != 'prix'" (blur)="majInterface()" (ngModelChange)="getValue()" [ngModel]="tarifHPH_acc_display"></ion-col>
                </ion-item>
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Creuses Haute saison :</ion-label></ion-col>
                  <ion-col size="2"><input type="number" matInput [value]="tarifHCH_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHCH type="number" step="0.01" matInput [disabled]="mode_valorisation != 'prix'" (blur)="majInterface()" (ngModelChange)="getValue()" [ngModel]="tarifHCH_acc_display"></ion-col>
                </ion-item>
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Pleines Basse saison :</ion-label></ion-col>
                  <ion-col size="2"><input type="number" matInput [value]="tarifHPB_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHPB type="number" step="0.01" matInput [disabled]="mode_valorisation != 'prix'" (blur)="majInterface()" (ngModelChange)="getValue()" [ngModel]="tarifHPB_acc_display"></ion-col>
                </ion-item>
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Creuses Basse saison :</ion-label></ion-col>
                  <ion-col size="2"><input matInput [value]="tarifHCB_four | number" disabled></ion-col>
                  <ion-col size="2"><input #tarifHCB type="number" step="0.01" matInput [disabled]="mode_valorisation != 'prix'" (blur)="majInterface()" (ngModelChange)="getValue()" [ngModel]="tarifHCB_acc_display"></ion-col>
                </ion-item>
                }
              </ion-list>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, effect, ViewChild } from '@angular/core';
import { ProjetService } from 'src/app/services/projet.service';
import { IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonLabel, IonList, 
  IonItem, IonButtons, IonButton, IonInput, IonSelect, IonSelectOption, IonIcon,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
  IonHeader, IonToolbar, IonSegment, IonSegmentButton, ModalController, IonModal } from '@ionic/angular/standalone';
import { ReponseApi } from 'src/app/services/EntiteProjet';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { TooltipComponent,  GridComponent, LegendComponent } from "echarts/components";
import { ECharts, EChartsOption, dataTool } from 'echarts';
import { ExtrapolateurComponent } from '../extrapolateur/extrapolateur.component';
import { CallbackDataParams } from 'echarts/types/dist/shared';

@Component({
  selector: 'app-dialogue-graphe',
  templateUrl: './dialogue-graphe.component.html',
  styleUrls: ['./dialogue-graphe.component.scss'],
  standalone: true,
  imports: [
    CommonModule, IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonButtons,
    IonLabel, IonList, IonItem, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon, 
    IonHeader, IonToolbar, IonSegment, IonSegmentButton, NgxEchartsDirective, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonModal, ExtrapolateurComponent 
  ],
  providers: [ provideEcharts() ]
})

export class DialogueGrapheComponent  implements OnInit {
  @Input() id_courbe!: number;
  @Input() prod?: boolean;
  @ViewChild('extrapolateur') extrapolateur !: ExtrapolateurComponent;

  retourApi : ReponseApi | any;
  aucuneDonneeDisponible: boolean = false;
  titreModal? :string;
  echartsOptions: EChartsOption = {};
  ecInstance: ECharts | null = null;
  libelleAbcisse: string = '';
  libelleOrdonnee: string = '';
  rotationAbcisse: number = 0;
  hauteurTexteAbcisse: number = 24;
  afficheGraphe: boolean = false;
  dataTooltip : number[] = [];
  totalFichier : number = 0;

  constructor(private modalCtrl: ModalController, private ps: ProjetService) { 
    effect( () => {   // Recharger les données du graphe sur la sollicitation du signal 
      const idCourbeModifiee = this.ps.courbeModifiee();
      if (idCourbeModifiee == this.id_courbe) {
        console.log('Le signal courbeModifiee vaut ' + idCourbeModifiee);
        this.recupereData(this.id_courbe).then(() => {
          console.log('DialogueGrapheComponent => Données rechargées.');
          this.rearmeSignal();
        });
      }
    });
  }

  ngOnInit() {
    this.titreModal = (this.prod) ? " de production" : " de charge";
    this.libelleAbcisse = (this.prod) ? "Mois de production" : "Période de consommation";
    this.libelleOrdonnee = (this.prod) ? "Production (kWh)" : "Consommation (kWh)";
    if (! this.prod) {
      this.rotationAbcisse = 25;
      this.hauteurTexteAbcisse = 48;
    }
    this.recupereData(this.id_courbe);
  }

  onChartInit(ec: any) {
    console.log('onChartInit()', ec);
    this.ecInstance = ec;
  }

  rearmeSignal() {
    this.ps.courbeModifiee.set(-1);
  }

  async recupereData(id :number){
    let dataXaxis: string[] = [];
    let dataSeriesLecture : number[] = [];
    let dataSeriesDecalage : number[] = [];
    let dataSeriesExtrapolee : number[] = [];
    let dataSeriesTotal : number[] = [];
    let dataTooltip : number[] = [];
    let formatteNombre: any = this.ps.formatteNombre;
    let formateurNombreFr = new Intl.NumberFormat('fr-FR');

    this.retourApi = await this.ps.donneesCourbe(id);
    this.aucuneDonneeDisponible = (this.retourApi.length == 0) ? true : false;
    if  (this.aucuneDonneeDisponible)
      return;

  // console.log(this.retourApi[0]);
    this.retourApi.forEach((data: { date: string, valeurs : { lecture : number, decalage : number, estimation : number, total : number}, points : { estimes : number, lus : number, manquants : number, total : number} }) => {
      dataXaxis.push( (this.prod) ? data.date.substring(5) : data.date );
      dataSeriesLecture.push(data.valeurs.lecture);
      dataSeriesDecalage.push(data.valeurs.decalage);
      dataSeriesExtrapolee.push(data.valeurs.estimation);
      dataSeriesTotal.push(data.valeurs.total);
      dataTooltip.push(data.points.manquants);
      this.totalFichier += data.valeurs.total;
    });
    this.dataTooltip = dataTooltip;

    this.echartsOptions = {
      tooltip: {
        trigger: "item",
        formatter:  function (params:any) {
          return `${params.name}<br/>
                  ${formatteNombre(dataSeriesTotal[params.dataIndex], 0, formateurNombreFr)} kWh (Total)<br/>
                  <hr>
                  ${formatteNombre(dataSeriesDecalage[params.dataIndex], 0, formateurNombreFr)} kWh (Reconstruit)<br/>
                  ${formatteNombre(dataSeriesExtrapolee[params.dataIndex], 0, formateurNombreFr)} kWh (Extrapolé)<br/>
                  ${formatteNombre(dataSeriesLecture[params.dataIndex], 0, formateurNombreFr)} kWh (Brut)<br/>
                  <hr>
                  ${formatteNombre(dataTooltip[params.dataIndex], 0, formateurNombreFr)} donnée(s) manquante(s)`;
        },
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "8%",
        top: "13%",
        containLabel: true
      },
      xAxis: {
        type: "category",
        name: this.libelleAbcisse,
        nameLocation: "middle",
        nameTextStyle: {
          fontWeight: 'bolder',
          lineHeight: this.hauteurTexteAbcisse
        },
        data: dataXaxis,
        axisLabel: {
          interval: 0,
          rotate : this.rotationAbcisse
        }
      },
      yAxis: {
        type: "value",
        name: this.libelleOrdonnee,
        nameTextStyle: {
          align: "left",
          fontWeight: 'bolder'
        },
        axisLabel: {
          formatter: v => this.ps.formatteNombre(v.toString(), 2)
        }
      },
      series: [
        {
          name: "Brute",
          type: "bar",
          stack: 'energie',
          data: dataSeriesLecture,
          itemStyle: {
            color: (seriesIndex) => this.determineBarCouleur(seriesIndex)
          },
        },
        {
          name: 'Extrapolée',
          type: 'bar',
          stack: 'energie',
          data: dataSeriesExtrapolee,
          itemStyle: {
            color: '#00559E'
          },
        },
        {
          name: 'Reconstruite',
          type: 'bar',
          stack: 'energie',
          data: dataSeriesDecalage,
          itemStyle: {
            color: '#A3BCD2'
          },
        }
      ]
    };
    this.afficheGraphe = true;
    if (this.extrapolateur)
      this.extrapolateur.genereLignesSaisie(this.retourApi);
  }

  determineBarCouleur(d: CallbackDataParams) {
    const trous = this.dataTooltip[d.dataIndex];
    return (trous > 0) ? '#A61D50' : '#213B73';
  }

  onChartClick(ev: any) {
    console.log('onChartClick', ev);
  }

  async fermeModal() {
    await this.modalCtrl.dismiss(null, 'annule');
   }
}



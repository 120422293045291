<div class="echart-legend-container">
    <div id="chart-flux-container" echarts [options]="echartFluxOption" (chartInit)="onChartInit($event)" (chartClick)="onChartClick($event)"></div>
    <ul id="legend">
        <li class="legend-item">
            <span class="legend-color node-producteur"></span>
            <span class="legend-text">Producteurs</span>
        </li>
        <li class="legend-item">
            <span class="legend-color flux-from-soutirage"></span>
            <span class="legend-text">Conso reseau</span>
        </li>
        <li class="legend-item">
            <span class="legend-color flux-aci"></span>
            <span class="legend-text">ACI</span>
        </li>
        <li class="legend-item">
            <span class="legend-color flux-from-prod"></span>
            <span class="legend-text">ACC</span>
        </li> 
        <li class="legend-item">
            <span class="legend-color flux-to-surplus"></span>
            <span class="legend-text">Surplus</span>
        </li>
        <li class="legend-item">
            <span class="legend-color node-consommateur"></span>
            <span class="legend-text">Consommateurs</span>
        </li>
    </ul>
</div>
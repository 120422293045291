<ion-grid>
  <ion-row class="ion-align-items-center">
    <ion-col>
      <input type="file" [accept]="formats_acceptes" (change)="onChange($event)" #fileupload/>
    </ion-col>
  </ion-row>
<!--
  <ion-row *ngIf="file">
    <ion-col>
      <ion-list class="file-info">
        <ion-item><ion-label>Type : </ion-label>{{ fileType }}</ion-item>
        <ion-item><ion-label>Taille : </ion-label>{{ fileSize }} octets</ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
-->
  <ion-row *ngIf="file && type_fichier != 'Projet'">
    <ion-col>
      <ion-list class="file-info">
        <ion-item>
          <ion-select label="Participant : " name="idParticipant" *ngIf="projet && projet.participants" value="0" interface="popover">
            <ion-select-option value="0">Créer un nouveau participant</ion-select-option>  
          <ion-select-option *ngFor="let p of projet.participants" [value]="p.id">{{ p.nom }}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="type_fichier == 'Projet'">
    <ion-col>
      <ion-list class="file-info">
        <ion-item>
          <ion-input type="number" label="Année de référence de la consommation" labelPlacement="floating" #annee (keyup)="changeAnnee($event)"></ion-input>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="file && enabledUpload">
    <ion-col class="ion-text-center">
      <ion-button *ngIf="file" (click)="onUpload()">Téléverser</ion-button>
    </ion-col>
    <ion-col>
      <div *ngIf="file">
        <section [ngSwitch]="status">
          <div *ngSwitchCase="'uploading'" class="ion-text-center">
<!--            <ion-icon name="alarm" color="tertiary" size="large"></ion-icon> -->
            <ion-label color="tertiary">Téléchargement en cours ...</ion-label>
            <ion-progress-bar [buffer]="progressBuffer" [value]="progressUpload" color="tertiary"></ion-progress-bar>
            <ion-label color="tertiary">{{ etatUpload }}</ion-label>
          </div>
          <ion-icon name="thumbs-up" *ngSwitchCase="'success'" color="success" size="large"></ion-icon> 
          <ion-label *ngSwitchCase="'success'" color="success">OK</ion-label>
          <ion-icon name="thumbs-down" *ngSwitchCase="'fail'" color="danger" size="large"></ion-icon> 
          <ion-label *ngSwitchCase="'fail'" color="danger"><strong>Erreur !</strong><span *ngIf="msgErreur !== ''"><br />{{ msgErreur }}</span></ion-label>
          <ion-icon name="return-down-forward" *ngSwitchDefault color="warning" size="large"></ion-icon> 
          <ion-label *ngSwitchDefault color="warning">En attente du téléversement ...</ion-label>
        </section>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-item>
  <ion-label>Option tarifaire : {{libelleTarif}}</ion-label>
  <ion-icon id="open-modal-{{id_compteur}}" name="pencil" [color]="couleurIconeSaisie"></ion-icon>
</ion-item>

<ion-modal trigger="open-modal-{{id_compteur}}" (willDismiss)="onWillDismiss($event)">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Choix des Options Tarifaires</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="valideOption()" [strong]="true">Sauvegarder</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-item>
        <ion-select #optionTarif id="optionTarif" placeholder="Choisir une option" (ionChange)="choixOptionTarifaire($event);" value="{{libelleTarif}}" interface="popover">
          <ion-select-option value="BASE">BASE</ion-select-option>
          <ion-select-option value="HP/HC">HP/HC</ion-select-option>
          <ion-select-option value="P/HCH/HPH/HCB/HPB">P/HCH/HPH/HCB/HPB</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="choixTarif > 0">
        <ion-label>Plages Heures Creuses :</ion-label>
      </ion-item>
      <ion-item *ngIf="choixTarif > 0">
        <mat-form-field>
          <mat-label>Début</mat-label>
          <input #HC1deb matInput type="time" [value]="HC1debVal">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fin</mat-label>
          <input #HC1fin matInput type="time" [value]="HC1finVal">
        </mat-form-field>
        <ion-button (click)="togglePeriode()" class="bfl" [disabled]="secondeHC">Ajout période</ion-button>
      </ion-item>
      <ion-item *ngIf="choixTarif > 0 && secondeHC">
        <mat-form-field>
          <mat-label>Début</mat-label>
          <input #HC2deb matInput type="time" [value]="HC2debVal">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fin</mat-label>
          <input #HC2fin matInput type="time" [value]="HC2finVal">
        </mat-form-field>
        <ion-button (click)="togglePeriode()" class="bfl">Supprime période</ion-button>
      </ion-item>

      <ion-item *ngIf="choixTarif > 1">
        <ion-label>La saison tarifaire « Haute » s'étend du 1er novembre au 31 mars inclus ; la saison tarifaire « Basse » s'étend du 1er avril inclus au 31 octobre inclus. (Enedis)</ion-label>
      </ion-item>

      <ion-item *ngIf="choixTarif > 1">
        <mat-checkbox color="primary" (click)="togglePointe()" [checked]="pointeBool">Période de Pointe</mat-checkbox>

        <mat-radio-group *ngIf="pointeBool" (change)="choixPointe($event);">
          <mat-radio-button color="primary" value="pointeF" [checked]="choixPointeF">Pointe Fixe</mat-radio-button>
          <mat-radio-button color="primary" value="pointeM" disabled>Pointe Mobile</mat-radio-button>
        </mat-radio-group>
      </ion-item>
      <ion-item *ngIf="choixPointeF">
        <ion-label>2h le matin et 2h le soir, de décembre à février inclus, hors dimanche</ion-label>
      </ion-item>

      <ion-item *ngIf="choixPointeF">
        <ion-label>Plages Pointes :</ion-label>
      </ion-item>
      <ion-item *ngIf="choixPointeF">
        <mat-form-field>
          <mat-label>Début</mat-label>
          <input #P1deb matInput type="time" [value]="P1debVal">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fin</mat-label>
          <input #P1fin matInput type="time" [value]="P1finVal">
        </mat-form-field>
        <ion-button (click)="togglePeriodePointe()" class="bfl" [disabled]="secondePointe">Ajout période</ion-button>
      </ion-item>
      <ion-item *ngIf="choixPointeF && secondePointe">
        <mat-form-field>
          <mat-label>Début</mat-label>
          <input #P2deb matInput type="time" [value]="P2debVal">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fin</mat-label>
          <input #P2fin matInput type="time" [value]="P2finVal">
        </mat-form-field>
        <ion-button (click)="togglePeriodePointe()" class="bfl">Supprime période</ion-button>
      </ion-item>
      <!-- SAISIE DES TARIFS DISTRIBUTEUR -->
      <ion-list class="saisie-tarif">
        <ion-row>
          <ion-col size="6"><ion-label></ion-label></ion-col>
          <ion-col size="3"><ion-label>Puissance souscrite (kVA)</ion-label></ion-col>
          <ion-col size="3"><ion-label>Tarif (c€/kWh)</ion-label></ion-col>
        </ion-row>
        @if (libelleTarif === 'BASE') {
        <ion-item>
          <ion-col size="6"><ion-label>Base :</ion-label></ion-col>
          <ion-col size="3"><input #puissance_BASE matInput [value]="puissanceBASE"></ion-col>
          <ion-col size="3"><input #tarif_BASE matInput [value]="tarifBASE"></ion-col>
        </ion-item>
        }
        @if (libelleTarif === 'HP/HC') {        
        <ion-item>
          <ion-col size="6"><ion-label>Heures Pleines :</ion-label></ion-col>
          <ion-col size="3"><input #puissance_HP matInput [value]="puissanceHP"></ion-col>
          <ion-col size="3"><input #tarif_HP matInput [value]="tarifHP"></ion-col>
        </ion-item>
        <ion-item>
          <ion-col size="6"><ion-label>Heures Creuses :</ion-label></ion-col>
          <ion-col size="3"><input #puissance_HC matInput [value]="puissanceHC"></ion-col>
          <ion-col size="3"><input #tarif_HC matInput [value]="tarifHC"></ion-col>
        </ion-item>
        }
        @if (libelleTarif === 'P/HCH/HPH/HCB/HPB') {
          @if (choixPointeF) {          
          <ion-item>
            <ion-col size="6"><ion-label>Heures de Pointe :</ion-label></ion-col>
            <ion-col size="3"><input #puissance_P matInput [value]="puissanceP"></ion-col>
            <ion-col size="3"><input #tarif_P matInput [value]="tarifP"></ion-col>
          </ion-item>
        }        
        <ion-item>
          <ion-col size="6"><ion-label>Heures Pleines Haute saison :</ion-label></ion-col>
          <ion-col size="3"><input #puissance_HPH matInput [value]="puissanceHPH"></ion-col>
          <ion-col size="3"><input #tarif_HPH matInput [value]="tarifHPH"></ion-col>
        </ion-item>
        <ion-item>
          <ion-col size="6"><ion-label>Heures Creuses Haute saison :</ion-label></ion-col>
          <ion-col size="3"><input #puissance_HCH matInput [value]="puissanceHCH"></ion-col>
          <ion-col size="3"><input #tarif_HCH matInput [value]="tarifHCH"></ion-col>
        </ion-item>
        <ion-item>
          <ion-col size="6"><ion-label>Heures Pleines Basse saison :</ion-label></ion-col>
          <ion-col size="3"><input #puissance_HPB matInput [value]="puissanceHPB"></ion-col>
          <ion-col size="3"><input #tarif_HPB matInput [value]="tarifHPB"></ion-col>
        </ion-item>
        <ion-item>
          <ion-col size="6"><ion-label>Heures Creuses Basse saison :</ion-label></ion-col>
          <ion-col size="3"><input #puissance_HCB matInput [value]="puissanceHCB"></ion-col>
          <ion-col size="3"><input #tarif_HCB matInput [value]="tarifHCB"></ion-col>
        </ion-item>
        }
      </ion-list>
    </ion-content>
  </ng-template>
</ion-modal>
import { timer } from 'rxjs';
import { Component, ComponentRef, Signal, ViewChild, booleanAttribute, computed, effect } from '@angular/core';
import { CommonModule, LocationStrategy } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkStep, CdkStepLabel, CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ItemReorderEventDetail } from '@ionic/angular';
import { IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonLabel, IonList, IonListHeader,
  IonItem, IonAccordionGroup, IonAccordion, IonButtons, IonButton, IonInput, IonSelect, IonSelectOption, IonIcon,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, ModalController, IonLoading,
  IonHeader, IonToolbar, IonSearchbar, IonSegment, IonSegmentButton, IonToggle, ModalOptions, IonDatetime, IonModal, IonPopover, IonFabButton, IonFab, IonSpinner, IonItemDivider } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { barChartOutline, barChartSharp, eyeOutline, eyeSharp, flashOutline, flashSharp, trashOutline, addCircleOutline } from 'ionicons/icons';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';

import { environment } from 'src/environments/environment';
import { ProjetService } from 'src/app/services/projet.service';
import { ListeProjets, TypeProjet, EntiteDocument, EntiteProjet, TypeDocument, ListeDocuments, EntiteCourbe, 
  ListeCourbes, ListeParticipants, ListeConsommateurs, ListeProducteurs, ListeGenerateurs, ListeCompteurs, 
  EntiteConsommateur, EntiteProducteur, EntiteParticipant, EntiteCompteur, EntiteGenerateur, EntiteScenario, 
  ListeScenarios, ListeDispositifs, EntiteBilanEnergetique, ReponseApi, RepartitionProd, RepartitionConso } from 'src/app/services/EntiteProjet';
import { AuthTokenInfo, AuthentificationService } from 'src/app/services/authentification.service';

import { UploadFileComponent } from '../../composants/upload-file/upload-file.component';
import { TpvStepperComponent } from '../../composants/tpv-stepper/tpv-stepper.component';
import { DialogueCreationProjetComponent } from '../../composants/dialogue-creation-projet/dialogue-creation-projet.component'
import { DialogueImportProjetComponent } from 'src/app/composants/dialogue-import-projet/dialogue-import-projet.component';
import { DialogueGrapheComponent } from '../../composants/dialogue-graphe/dialogue-graphe.component'
import { OptionsTarifairesComponent } from 'src/app/composants/options-tarifaires/options-tarifaires.component';
import { GraphesBilanConsommateurComponent } from 'src/app/composants/graphes-bilan-consommateur/graphes-bilan-consommateur.component';
import { ScenarioProducteursComponent } from 'src/app/composants/scenario-producteurs/scenario-producteurs.component';
import { DialogNewScenarioComponent } from 'src/app/composants/dialog-new-scenario/dialog-new-scenario.component';
import { VflowGestionParticipantsComponent } from 'src/app/composants/vflow-gestion-participants/vflow-gestion-participants.component';

import { MapType } from '@angular/compiler';
import { OverlayEventDetail } from '@ionic/core/components';
import { ListKeyManager } from '@angular/cdk/a11y';
import { DialogueHeatmapComponent } from 'src/app/composants/dialogue-heatmap/dialogue-heatmap.component';
import { ConfigService } from 'src/app/services/config.service';
import { BilanGlobalFluxGraphComponentComponent } from "../../composants/bilan-global-flux-graph-component/bilan-global-flux-graph-component.component";
import { PlanFinancierParProducteurComponent } from 'src/app/composants/plan-financier-par-producteur/plan-financier-par-producteur.component';
import { PlanFinancierParConsommateurComponent } from 'src/app/composants/plan-financier-par-consommateur/plan-financier-par-consommateur.component';
import { BilanFinancierComponent } from 'src/app/composants/bilan-financier/bilan-financier.component';

@Component({
  selector: 'app-projet',
  templateUrl: './projet.page.html',
  styleUrls: ['./projet.page.scss'],
  standalone: true,
  imports: [IonItemDivider, IonSpinner, IonFab, IonFabButton, IonPopover, IonModal,
    CommonModule, IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonButtons,
    IonLabel, IonList, IonListHeader, IonItem, IonAccordionGroup, IonAccordion, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon,
    IonHeader, IonToolbar, IonSearchbar, IonSegment, IonSegmentButton, IonLoading,
    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonToggle, IonDatetime,
    CdkStep, CdkStepLabel, MatFormFieldModule, MatInputModule, MatCheckbox, MatRadioButton, MatRadioGroup,
    UploadFileComponent, TpvStepperComponent, ScenarioProducteursComponent, OptionsTarifairesComponent, GraphesBilanConsommateurComponent, 
    DialogNewScenarioComponent, MatTooltipModule, VflowGestionParticipantsComponent, 
    BilanGlobalFluxGraphComponentComponent, PlanFinancierParProducteurComponent, PlanFinancierParConsommateurComponent, BilanFinancierComponent ]
})

export class ProjetPage {
  authInfo!: AuthTokenInfo;
  listeProjets: ListeProjets;
  idProjet: number = -1;
  scenarioCourant: number = 0;
  newScenarioModalControl: boolean = false;
  // Copies locales des données du Projet
  projetCourant: EntiteProjet | null = null;
  mapParticipants: Map<number, EntiteParticipant>;
  mapScenarios: Map<number,EntiteScenario>;
  mapDocuments: Map<number, EntiteDocument>;
  mapDispositifs: Map<number, EntiteCompteur | EntiteGenerateur>;
  mapCompteurs: Map<number, EntiteCompteur>;
  mapCourbes: Map<number, EntiteCourbe>
  showToggleOrderingProd: boolean = true;
  disableOrdering: boolean = true;
  nbProducteurs: number = 0;
  camembertsVisible: number[] = [];
  accordionEnergieStatus: number = 0;
  accordionFinancierStatus: number = 0;
  accordionBilanStatus: number = 0;
  isLoading: {[key: number]: boolean} = {};
  isDownloadComplete: {[key: number]: boolean} = {};
  lien_sharepoint: string = "";
  lien_odoo: string = "";

  listeTypesProjet: string[] = [];
  listeModeRepartitionProd: [string, RepartitionProd][] = [];
  listeModeRepartitionConso: [string, RepartitionConso][] = [];
  currentStep: number = 0;            //////// Bascule vers l'étape ... pour les tests !!!! (-1 pour désactiver le timer)
  currentStepParticipant: number = 0; //////// Bascule vers l'onglet X de la gestion des Participants
  currentStepEnergie: number = 0;     //////// Bascule vers l'onglet X du scénario Energie
  currentStepBilan: number = 0;       //////// Bascule vers l'onglet X du Bilan
  classErreurNbConsommateurs: string = '';
  @ViewChild('popover') popover: any;
  popoverIsOpen: boolean = false;

  @ViewChild('stepper') stepper: CdkStepper | undefined; // Etapes Projet
  @ViewChild('stepper_participants') stepperParticipants: CdkStepper | undefined; // Etapes Gestion des Participants
  @ViewChild('stepper_energie') stepperEnergie: CdkStepper | undefined; // Etapes Scénarios Energie
  @ViewChild('stepper_financier') stepperFinancier: CdkStepper | undefined; // Etapes Scénarios Financier
  @ViewChild('stepper_bilan') stepperBilan: CdkStepper | undefined; // Etapes Scénarios Energie

  @ViewChild('step1_1') step1_1: CdkStep | undefined; // Etape Variables Générales
  @ViewChild('step1_2') step1_2: CdkStep | undefined; // Etape Participants [ anciennement Fichiers de données ]
  @ViewChild('step1_3') step1_3: CdkStep | undefined; // Etape Scénarios Energie
  @ViewChild('step1_4') step1_4: CdkStep | undefined; // Etape Scénarios Financier
  @ViewChild('step1_5') step1_5: CdkStep | undefined; // Etape Bilans

  @ViewChild('step2_1') step2_1: CdkStep | undefined; // Etape Participants Consommateurs
  @ViewChild('step2_2') step2_2: CdkStep | undefined; // Etape Participants Producteurs
  @ViewChild('step2_3') step2_3: CdkStep | undefined; // Etape Participants Synthèses

  @ViewChild('step3_1') step3_1: CdkStep | undefined; // Etape Finances TODO
  @ViewChild('step3_2') step3_2: CdkStep | undefined; // Etape Energie TODO

  @ViewChild('step4_1') step4_1: CdkStep | undefined; // Etape Bilan Energie
  @ViewChild('step4_2') step4_2: CdkStep | undefined; // Etape Bilan Facturation
  @ViewChild('step4_3') step4_3: CdkStep | undefined; // Etape Bilan Financier TODO

  @ViewChild('calculEnCours') calculEnCours: IonLoading | undefined;
  
  @ViewChild('accise') accise!: IonInput;
  @ViewChild('CTA') CTA!: IonInput;
  @ViewChild('TVA') TVA!: IonInput;

  constructor(protected conf: ConfigService, private auth: AuthentificationService, public ps: ProjetService, 
    private activatedRoute : ActivatedRoute, private router: Router, private modalCtlr: ModalController) {
    addIcons({ eyeOutline, eyeSharp, flashOutline, flashSharp, trashOutline, barChartOutline, barChartSharp, addCircleOutline });
    this.listeProjets = [];
    this.listeTypesProjet = Object.keys(TypeProjet);
    this.listeModeRepartitionProd = Object.entries(RepartitionProd);
    this.listeModeRepartitionConso = Object.entries(RepartitionConso);
    this.mapCourbes = new Map();
    this.mapDispositifs = new Map();
    this.mapCompteurs = new Map();
    this.mapDocuments = new Map();
    this.mapParticipants = new Map();
    this.mapScenarios = new Map();

    effect( () => {   // Recharger le projetCourant sur la sollicitation du signal 
      const etat = this.ps.projetModifie();
      if (etat) {
        console.log('Le signal ProjetModifié passe à TRUE !');
        this.recupereInfosProjet();
      }
    });
   }

   EnvironnementProd() : boolean {
    return environment.production;
   }

   formatteNombre(valeur : number, precision: number){
    return this.ps.formatteNombre(<string><unknown>valeur, precision);
   }

  delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async dialogueCreationProjet() {
    const dialogue = await this.modalCtlr.create({ component: DialogueCreationProjetComponent });
    dialogue.onDidDismiss().then( (res) => {
      if (res.role == 'confirme') {
        this.ps.creerProjet(res.data).then(id  => {
          if (id > 0) {
            timer(200).subscribe(() => this.router.navigateByUrl('/projet/'+ id) );
          }
        });
      }
    });
    return await dialogue.present();
  }
  
  async dialogueImportProjet() {
    const dialogue = await this.modalCtlr.create({component: DialogueImportProjetComponent,componentProps:{idProjet: this.idProjet}});
    dialogue.onDidDismiss().then( (res) => {
      if (res.role == 'confirme') {
        console.log("import projet OK");
        // this.ps.creerProjet(res.data).then(id  => {
        //   if (id > 0) {
        //     timer(200).subscribe(() => this.router.navigateByUrl('/projet/'+ id) );
        //   }
        // });
      }
    });
    return await dialogue.present();
  }

  async ionViewDidEnter() {
    this.idProjet = Number(this.activatedRoute.snapshot.paramMap.get('idProjet') as string);
    //console.log('Entrée dans ProjetPage::ionViewDidEnter() idProjet => ', this.idProjet);
    if (this.idProjet > 0) {
      await this.recupereInfosProjet();
      this.auth.headerTitreSignal.set('Projet [' + this.projetCourant?.libelle + ']');
      this.auth.menuLateralSignal.set('false');
    } else {
      this.auth.headerTitreSignal.set('Liste des Projets');
      this.rafraichitListeProjets();
      this.auth.menuLateralSignal.set('lg');
    }
    
    if (this.currentStep >= 0)
      timer(100).subscribe(() =>{
        if (this.stepper && this.currentStep >= 0)
          this.stepper.selectedIndex = this.currentStep;
        if (this.stepperParticipants && this.currentStepParticipant > 0)
          this.stepperParticipants.selectedIndex = this.currentStepParticipant;
        if (this.stepperEnergie && this.currentStepEnergie > 0)
          this.stepperEnergie.selectedIndex = this.currentStepEnergie;
        if (this.stepperBilan && this.currentStepBilan > 0)
          this.stepperBilan.selectedIndex = this.currentStepBilan;
        this.showToggleOrderingProd = (this.nbProducteurs > 1 && this.currentStepEnergie == 0) ? true : false;
      });
  }

  // ============== Projets ============================================
  async recupereInfosProjet() {
    if (this.idProjet < 1)
      return;
    const p = await this.ps.detailProjet(this.idProjet);
    if (p) {
      console.log('ProjetPage::recupereInfosProjet() => ', p);
      //console.log('ProjetPage::recupereInfosProjet() => ', p.scenarios[0].bilanFacturation);
      this.projetCourant = p;
      // Vérifier si le nbre de consommateurs est OK
      this.classErreurNbConsommateurs = (this.ps.valideAttributProjet(p, 'nbConsommateurs', p.nbConsommateurs)) ? '' : 'ng-pristine ng-invalid ng-touched ion-pristine ion-invalid ion-touched';
      // Initialiser les Maps
      this.mapCourbes.clear();
      p.courbes.forEach(c => this.mapCourbes.set(c.id, c));
      this.mapDispositifs.clear();
      p.dispositifs.forEach(d => {
        this.mapDispositifs.set(d.id, d);
        //console.log('recupereInfosProjet => dispositif ...', d);
        if (d.type == 'Compteur') {
          this.ps.getCompteur(d.id).then(data => {
            if (data)
              this.mapCompteurs.set(d.id, data);
          });
        }
      });
      this.mapDocuments.clear();
      p.documents.forEach(d => this.mapDocuments.set(d.id, d));
      this.mapParticipants.clear();
      this.nbProducteurs = 0;
      p.participants.forEach(p => {
        this.mapParticipants.set(p.id, p); 
        if (p.producteur && p.producteur.id > 0)
          this.nbProducteurs++;
      });
      this.showToggleOrderingProd = (this.nbProducteurs > 1 && this.currentStepEnergie == 0) ? true : false;
      this.mapScenarios.clear();
      p.scenarios.forEach(s => this.mapScenarios.set(s.id, s));
      
      p.variables.forEach(v => {
        this.lien_sharepoint = (v.variable == "URL_sharepoint") ? v.valeur : this.lien_sharepoint;
        this.lien_odoo = (v.variable == "URL_odoo") ? v.valeur : this.lien_odoo;
      })

      timer(500).subscribe(() => {
        this.ps.projetModifie.set(false);
        console.log("projetModifie => FALSE");
      });
    }
  }
  
  async rafraichitListeProjets() {
    let liste = await this.ps.listeProjets();
    if (liste)
      this.listeProjets = liste.sort( this.triProjetsParIdDesc );
  }

   getColorType(type:string) : string {
    let col = 'primary';
    switch(type) {
      case 'VT' : col = 'success'; break;
      case 'ACT' : col = 'primary'; break;
      case 'ACI' : col = 'secondary'; break;
      case 'ACC' : col = 'tertiary'; break;
    }
    return col;
   }

   getLibelleType(index: number) {
    let libs = Object.values(TypeProjet);
    return libs[index];
   }

   majProjet(ev : CustomEvent) {
    let el = ev.target as HTMLElement;
    let attr = (ev.target as Element).getAttribute('name');
    if (this.projetCourant && attr && ! this.ps.valideAttributProjet(this.projetCourant, attr, ev.detail.value)) {
      console.error('PageProjet::majProjet() => ' + attr + ' invalide (' + ev.detail.value + ')');
      this.setInputInvalid(el);
      return;
    }
    this.setInputValid(el);
    let data = {} as any;
    let reqOK: boolean = false;
    switch(attr) {
      case 'libelle':
        data[attr] = ev.detail.value;
        console.log('PageProjet::majProjet()', data);
        this.auth.headerTitreSignal.set('Projet [' + ev.detail.value + ']');
        reqOK = true;
        break;
      case 'type':
        data[attr] = ev.detail.value;
        reqOK = true;
        break;
      case 'nbConsommateurs':
        data[attr] = parseInt(ev.detail.value);
        reqOK = true;
        break;
      case 'URL_sharepoint':
        data["variables"] = {URL_sharepoint : decodeURIComponent(ev.detail.value)};
        reqOK = true;
        break;
      case 'URL_odoo':
        data["variables"] = {URL_odoo : decodeURIComponent(ev.detail.value)};
        reqOK = true;
        break;
      default:
        console.error('PageProjet::majProjet() => ' + attr + ' non traité !');
    }
    if (reqOK)
      this.ps.majDataProjet(this.idProjet, data).then(p => { 
        if (p){
          this.projetCourant = p;
          timer(500).subscribe(() => {
            this.ps.projetModifie.set(true);
            console.log("projetModifie => TRUE");
          });
        }
      });
   }

   afficheProjet(id: number) {
    console.log('Afficher le projet '+id);
    this.router.navigateByUrl('/projet/' + id);
    this.auth.menuLateralSignal.set('false');
   }

   async supprimeProjet(event: Event, id: number, libelle: string) {
    event.stopPropagation();
    if (confirm('Veuillez confirmer la suppression du Projet ' + libelle + '( ID: ' + id + ')')) {
      console.log('Supprimer le projet ' + id);
      this.ps.supprimeProjet(id).then(p => { 
        if (p) {
          this.rafraichitListeProjets();
        } 
      });
      
      let liste = await this.ps.listeProjets();
        if (liste)
          this.listeProjets = liste.sort( this.triProjetsParIdDesc );
    }
   }

   // ============== Scénarios =========================================
   evitePropagEvent(ev: Event, cause: string  | undefined) {
    //console.log('evitePropagEvent', ev);
    ev.stopPropagation();
    ev.stopImmediatePropagation();
   }

   variableScenario(idScenario: number, idParticipant: number, nomVar: string): number | null {
    let res: number | null = null;
    this.mapScenarios.forEach(s => {
      if (s.id == idScenario)
        s.generateurs.forEach(p => {
          if (p.idParticipant == idParticipant) {
            for (const [key, val] of Object.entries(p)) {
              if (key == nomVar && typeof(val) == 'number') {
                res = val;
                break;
              }
            }
          }
      });
    });
    return res;
   }

   majScenario(ev : CustomEvent, idSCenario: number) {
    // ev.stopPropagation();
    this.evitePropagEvent(ev, '');
    if (ev.detail && ev.detail.value) {
      let attr = (ev.target as Element).getAttribute('name');
      if (attr && ! this.ps.valideAttributScenario(attr, ev.detail.value)) {
        console.error('PageProjet::majScenario() => ' + attr + ' invalide (' + ev.detail.value + ')');
        return;
      }
      let data = {} as any;
      let reqOK: boolean = false;
      switch(attr) {
        case 'libelle':
          data[attr] = ev.detail.value;
          reqOK = true;
          break;
        case 'nbConsommateurs':
          data[attr] = parseInt(ev.detail.value);
          reqOK = true;
          break;
        case 'repartitionProduction':
        case 'repartitionConsommation':
          data[attr] = ev.detail.value;
          reqOK = true;
          break;
        default:
          console.error('PageProjet::majScenario() => ' + attr + ' non traité !');
      }
      if (reqOK)
        this.ps.majDataScenario(this.idProjet, idSCenario, data).then(p => { 
          if (p) {
            console.log('Retour API =>', p);
            //this.ps.projetCourant.set(p);
            this.projetCourant?.scenarios.forEach( (sc, index) => {
              if (sc.id == idSCenario && this.projetCourant?.scenarios[index])
                this.projetCourant.scenarios[index] = p;
            });
            this.mapScenarios.set(idSCenario, p);
          }
        });
    }
   }

   participationPConsommateurScenario(idSCenario: number, idParticipant: number): boolean {
    let res: boolean = false;
    let s = this.mapScenarios.get(idSCenario);
    if (s) {
      s.compteurs.forEach(p => {
        if (p.idParticipant == idParticipant)
          res = (p.idCompteur) ? true : false;
      });
    }
    return res;
   }

   generateurScenarioActif(idSCenario: number, idDispositif: number): boolean {
    let res: boolean = false;
    let s = this.mapScenarios.get(idSCenario);
    if (s) {
      s.generateurs.forEach(g => {
        if (g.idGenerateur == idDispositif)
          res = g.actif;
      });
    }
    return res;
   }

   compteurScenarioActif(idSCenario: number, idDispositif: number): boolean {
    let res: boolean = false;
    let s = this.mapScenarios.get(idSCenario);
    if (s) {
      s.compteurs.forEach(c => {
        if (c.idCompteur == idDispositif)
          res = c.actif;
      });
    }
    return res;
   }

   getPuissanceFromId(id: number): number | undefined {
    let cpt = this.mapDispositifs.get(id);
    if (cpt)
      return cpt.puissance;
    return undefined;
   }

   async activationDispositifScenario(event: CustomEvent, idSCenario: number, idDispositif: number) {
    event.stopPropagation();
    console.log('activationGenerateurScenario', event.detail);
    let res = await this.ps.majDataScenarioDispositif(idSCenario, idDispositif, {"actif": event.detail.checked});
    if (res && res.id == idSCenario) {
      this.mapScenarios.set(idSCenario, res);
      this.recupereInfosProjet();
      timer(500).subscribe(() => {
        this.ps.bilanEnergieModifie.set(true);
      });
    }
   }

   async majDispositifScenario(event: CustomEvent, idSCenario: number, idDispositif: number) {
    event.stopPropagation();
    //console.log('majDispositifScenario', event.detail);
    const el = event.target as HTMLInputElement;
    const attr = el.name;
    let val;
    switch(attr) {
      case 'pcSouhait': val=parseFloat(event.detail.value); break;
      case 'pOndSouhait': val=parseFloat(event.detail.value); break;
      case 'pcOptMin': val=parseFloat(event.detail.value); break;
      case 'pcOptMax': val=parseFloat(event.detail.value); break;
      case 'pcOptDelta': val=parseFloat(event.detail.value); break;
      case 'repartition_prod': console.log(event.detail.value); break;
      default: val=event.detail.value;
    }
    let data: any = {}
    data[attr] = val; 
    //console.log('majDispositifScenario :', data);
    let res = await this.ps.majDataScenarioDispositif(idSCenario, idDispositif, data);
    if (res && res.id == idSCenario) {
      //console.log('majDispositifScenario => retour API :', res);
      this.mapScenarios.set(idSCenario, res);
      this.projetCourant?.scenarios.forEach((sc, index) => {
        if (sc.id == idSCenario && this.projetCourant?.scenarios[index] && res != null)
          this.projetCourant.scenarios[index] = res;
      });
    }
   }

   toggleOrdering () {
    this.disableOrdering = ! this.disableOrdering;
   }

   ReordonneProducteurs(idSCenario: number, ev: CustomEvent<ItemReorderEventDetail>) {
    console.log('ReordonneProducteurs', ev);
   }
  
   // =============== Participants =====================================
   private _getParticipantFromJSON(id: number) : EntiteParticipant | null {
    let res: EntiteParticipant | null = null;
    this.projetCourant?.participants.forEach(p => {
      if (p.id == id) {
        res = p;
        return;
      }
    });
    return res;
   }

   getParticipant(id: number) : EntiteParticipant | null {
    const p = this.mapParticipants.get(id);
    //console.log('getParticipant(' + id + ')', this.mapParticipants, p);
    if (p)
      return p;
    return this._getParticipantFromJSON(id);
   }

   majParticipant(ev: CustomEvent, idParticipant: number, infoMaj?: string) {
    ev.stopPropagation();
    const el = ev.target as HTMLElement;
    let attr = (ev.target as Element).getAttribute('name');
    if (attr) {
      if (! this.ps.valideAttributParticipant(attr, ev.detail.value)) {
        console.error('PageProjet::majProducteur() => ' + attr + ' invalide (' + ev.detail.value + ')');
        this.setInputInvalid(el);
        return;
      }
      this.setInputValid(el);
      let data = {} as any;
      let reqOK: boolean = false;
      switch(attr) {
        case 'nom':
          data[attr] = ev.detail.value;
          //console.log('majParticipant => ', data);
          reqOK = true;
          break;
        default:
          console.error('PageProjet::majParticipant => Traiter la propriété ' + attr);
      }
      if (reqOK) {
        this.ps.majDataParticipant(idParticipant, data).then(res => { 
          if (res) {
            //console.log('Retour API majParticipant =>', res);
            this.setInputValid(el);
            this.mapParticipants.set(idParticipant, res);
            this.projetCourant?.participants.forEach((p, index) => {
              if (p.id == idParticipant && this.projetCourant?.participants[index])
                this.projetCourant.participants[index] = res;
            });
            this.ps.participantModifie.set(true);
            timer(200).subscribe(() => {      // Réarmer le signal pour parer à une modification ultérieure...
              this.ps.participantModifie.set(false);
              //console.log('ProjetPage => réarmement du signal participantModifié.');
            });
          }
        });
      }
    }
   }

   getCompteursParticipant(idParticipant: number): ListeCompteurs {
    let res: ListeCompteurs = [];
    const p = this.getParticipant(idParticipant);
    if (p && p.consommateur)
      res = p.consommateur.compteurs;
    return res;
   }

   CatTurpeParticipant(idParticipant: number) : string {
    let res = '';
    const cc = this.getCompteursParticipant(idParticipant);
    if (cc.length > 0) {
      const c = this.mapCompteurs.get(cc[0].id);
      if (c)
        res = c.config_turpe.sous_categorie_puissance;
    }
    return res;
   }

   getProducteurParGenerateur(idGenerateur: number): EntiteParticipant | null {
    let res: EntiteParticipant | null = null;
    this.projetCourant?.participants.forEach(p => {
      if (p.producteur && p.producteur.generateurs.find(g => g.id == idGenerateur)) {
        const prod = this.getParticipant(p.id);
        if (prod) {
          res = prod;
          return;
        }
      }
    });
    return res;
   }

   // =============== Producteurs ======================================
   producteursLiesAuDocument(idDoc: number): ListeParticipants {
    let t : ListeParticipants = [];
    this.projetCourant?.dispositifs.forEach(dispositif => {
      let g = dispositif as EntiteGenerateur;
      if (g.idDocument == idDoc) {
        this.projetCourant?.participants.forEach (p => {
          if (p.producteur && p.producteur.generateurs.length > 0)
            p.producteur.generateurs.forEach(gg => {
              if (gg.id == g.id)
                t.push(p);
            });
        });
      }
    });
    return t;
   }

   filtreProducteurs(idScenario: number) : ListeParticipants {
    let t : ListeParticipants = []; 
    this.mapParticipants.forEach(p => {
      if (p.producteur)
        t.push(p);
    });
    return t;
  }

  filtreProducteursActifs(idScenario: number) : ListeParticipants {
    let t : ListeParticipants = [];
    let s = this.mapScenarios.get(idScenario);
    if (s) {
      s.generateurs.forEach(g => {
        if (g.actif)
          t.push(this.getParticipant(g.idParticipant)!);
      });
    }
    return t;
  }

  getProducteurScenarioFromParticipant (idParticipant : number){
    this.mapScenarios;
  }

  supprimeProducteur(idParticipant: number) {
  alert('TODO : Supprimer le producteur ...');
  }

  // ================ Consommateurs ===================================
  ConsommateursLiesCourbe(idCourbe : number): ListeParticipants {
    let t : ListeParticipants = [];
    const c = this.mapCourbes.get(idCourbe);
    if (c) {
      const cpt = this.mapDispositifs.get(c.idDispositif);
      this.mapParticipants.forEach(p => {
        if (p.consommateur && p.consommateur.compteurs.length > 0)
          p.consommateur.compteurs.forEach( cc => {
            if (cc.id == cpt?.id)
              t.push(p);
        });
      });
    }
    return t;
  }

  filtreConsommateurs(idScenario: number) : ListeParticipants {
    let t : ListeParticipants = [];
    this.mapParticipants.forEach(p => {
      if (p.consommateur)
        t.push(p);
    });
    return t;
  }

  filtreConsommateursActifs(idScenario: number) : ListeParticipants {
    let t : ListeParticipants = [];
    let s = this.mapScenarios.get(idScenario);
    if (s) {
      s.compteurs.forEach(c => {
        if (c.actif)
          t.push(this.getParticipant(c.idParticipant)!);
      });
    }
    return t;
  }

  consommateurScenarioActif(idParticipant : number, idScenario: number){
    let res: boolean = false;
    let s = this.mapScenarios.get(idScenario);
    if (s) {
      s.compteurs.forEach(c => {
        if (c.idParticipant == idParticipant)
          res = c.actif;
      });
    }
    return res;
  }
  supprimeConsommateur(idParticipant: number) {
    alert('TODO : Supprimer le consommateur ...');
  }
  // ================ Dispositifs =====================================
  majGenerateur(ev: CustomEvent, idGenerateur: number) {
    ev.stopPropagation();
    const el = ev.target as HTMLElement;
    let attr = (ev.target as Element).getAttribute('name');
    if (attr) {
      if (! this.ps.valideAttributGenerateur(attr, ev.detail.value)) {
        console.error('PageProjet::majGenerateur() => ' + attr + ' invalide (' + ev.detail.value + ')');
        this.setInputInvalid(el);
        return;
      }
      this.setInputValid(el);
      let data = {} as any;
      let reqOK: boolean = false;
      switch(attr) {
        case 'puissance':
          data[attr] = parseFloat(ev.detail.value);
          reqOK = true;
          break;
        default:
          console.error('PageProjet::majGenerateur => Traiter la propriété ' + attr);
      }
      if (reqOK) {
        this.ps.majDataGenerateur(idGenerateur, data).then(p => { 
          if (p) {
            this.setInputValid(el);
            this.mapDispositifs.set(p.id, p);
          }
        });
      }
    }
  }

  majCompteur(ev: CustomEvent, idCompteur: number) {
    ev.stopPropagation();
    const el = ev.target as HTMLElement;
    let attr = (ev.target as Element).getAttribute('name');
    if (attr) {
      if (! this.ps.valideAttributCompteur(attr, ev.detail.value)) {
        console.error('PageProjet::majCompteur() => ' + attr + ' invalide (' + ev.detail.value + ')');
        this.setInputInvalid(el);
        return;
      }
      this.setInputValid(el);
      let data = {} as any;
      let reqOK: boolean = true;
      let majMapDispositifs: boolean = false;
      switch(attr) {
        case 'puissance':
        case 'accise':
        case 'CTA':
        case 'TVA':
          data[attr] = parseFloat(ev.detail.value);
          reqOK = true;
          majMapDispositifs = false;
          break;
        case 'categorie_turpe': 
          let c1: EntiteCompteur | undefined = this.mapCompteurs.get(idCompteur);
          if (c1) {
            if (attr == 'categorie_turpe')
              c1.config_turpe.categorie_puissance = ev.detail.value;
              c1.config_turpe.sous_categorie_puissance = "";
              
            this.mapCompteurs.set(idCompteur, c1);
            data.config_turpe = c1.config_turpe;
            reqOK = true;
          }
          else
            console.error('Impossible de récupérer le compteur ' + idCompteur + ' depuis le dictionnaire mapCompteurs !', this.mapCompteurs);
        break;
        case 'sous_categorie_turpe':
        case 'repartition_turpe' :
          let c: EntiteCompteur | undefined = this.mapCompteurs.get(idCompteur);
          if (c) {
            if (attr == 'sous_categorie_turpe')
              c.config_turpe.sous_categorie_puissance = ev.detail.value;
            // else
            //   c.config_turpe.puissances_soucrites = ev.detail.value;
            this.mapCompteurs.set(idCompteur, c);
            data.config_turpe = c.config_turpe;
            reqOK = true;
          }
          else
            console.error('Impossible de récupérer le compteur ' + idCompteur + ' depuis le dictionnaire mapCompteurs !', this.mapCompteurs);
          break;
        default:
          console.error('PageProjet::majCompteur => Traiter la propriété ' + attr);
      }
      if (reqOK) {
        this.ps.majDataCompteur(idCompteur, data).then(p => { 
          if (p) {
            this.setInputValid(el);
            if (majMapDispositifs)
              this.mapDispositifs.set(p.id, p);
            this.getStepProjetState('participants');
          }
        });
      }
    }
  }

  dispositifAttacheDocument(idDocument: number) : EntiteCompteur | EntiteGenerateur | null {
    let res: EntiteCompteur | EntiteGenerateur | null = null;
    const doc = this.mapDocuments.get(idDocument);
    if (doc) {
      const idCourbe = doc.listeCourbes[0];
      const courbe = this.mapCourbes.get(idCourbe);
      if (courbe) {
        const dispositif = this.mapDispositifs.get(courbe.idDispositif);
        if (dispositif)
          res = dispositif;
      }
    }
    return res;
  }

  generateursAttachesDocument(idDocument: number) : EntiteGenerateur[] {
    let res: EntiteGenerateur[] = [];
    const doc = this.mapDocuments.get(idDocument);
    if (doc) {
      doc.listeCourbes.forEach(idCourbe => {
        const courbe = this.mapCourbes.get(idCourbe);
      if (courbe) {
        const dispositif = this.mapDispositifs.get(courbe.idDispositif);
        if (dispositif && dispositif.type == 'Generateur')
          res.push(dispositif as EntiteGenerateur);
      }
      });
    }
    return res;
  }

  getCompteurFromDispositif(idDispositif: number) {
    return this.mapDispositifs.get(idDispositif) as EntiteCompteur;
  }

  verifiePuissanceSouscrite(idDispositif : number): string {
    let res = 'input-scenario-energie';
    const c = this.mapCompteurs.get(idDispositif);
    if (c) {
      if ( c.puissance < 3 || c.puissance >= 999999999) {
        res += ' input-scenario-energie ng-pristine ng-invalid ng-touched ion-pristine ion-invalid ion-touched';
      }
    }
    return res;
  }

  verifieCategorieTurpe(idDispositif: number): string {
    let res = '';
    const infos = this.mapCompteurs.get(idDispositif)?.config_turpe;
    if (infos) {
      if ( ! this.conf.CatTurpe6().includes(infos.categorie_puissance)) {
        res = 'ng-pristine ng-invalid ng-touched ion-pristine ion-invalid ion-touched';
      }
    }
    return res;
  }

  verifieSouscategorieTurpe(idDispositif : number): string {
    let res = '';
    const infos = this.mapCompteurs.get(idDispositif)?.config_turpe;
    if (infos) {
      if ( ! this.conf.sousCatTurpe6(infos.categorie_puissance).includes(infos.sous_categorie_puissance)) {
        res = 'ng-pristine ng-invalid ng-touched ion-pristine ion-invalid ion-touched';
      }
    }
    return res;
  }

  removeIonSpinners(divElement: HTMLElement, object: string) {
    const spinner = divElement.querySelector(object);
    console.log('removeIonSpinners', spinner);
    // spinners.forEach(spinner => {
      spinner?.remove();
    // });
  }

  async uploadCsvData(idDispositif: number, event: Event) {
    let button = event.target as HTMLIonIconElement;
    this.isLoading[idDispositif] = true;
    this.ps.recupereDataCsv(idDispositif).then(csv => {
      if (csv) {
        // console.log('uploadCsvData', csv);
        let fileName = 'donnees_' + idDispositif + '.csv';
        const disp = this.mapDispositifs.get(idDispositif);
        if (disp)
          fileName = 'donnees_' + disp.code + '.csv';
    
        const url = window.URL.createObjectURL(csv);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        // Changed line below
        document.body.appendChild(link); // This will add your link to the DOM
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
        this.isLoading[idDispositif] = false;
        this.isDownloadComplete[idDispositif] = true;
      }
    });
  }

  // ======== Documents ============================================
  
  documentsProd() : ListeDocuments {
    let res: ListeDocuments = this.projetCourant?.documents.filter(p => p.type == TypeDocument.production) as ListeDocuments;
    this.projetCourant?.documents.filter(p => p.type == TypeDocument.projet).forEach(d => {
      let ajout = false;
      if (this.courbesDocument(d.id, 'prod').length > 0)
          res.push(d);
    });
    //console.log('documentsProd', res);
    return res;
  }

  documentsConso() : ListeDocuments {
    let res: ListeDocuments = this.projetCourant?.documents.filter(p => p.type == TypeDocument.conso) as ListeDocuments;
    this.projetCourant?.documents.filter(p => p.type == TypeDocument.projet).forEach(d => {
      let ajout = false;
      if (this.courbesDocument(d.id, 'conso').length > 0)
        res.push(d);
    });
    //console.log('documentsConso', res);
    return res;
  }

  courbesDocument(idDocument: number, type: 'prod' | 'conso' | 'tout'): ListeCourbes {
    let res: ListeCourbes = [];
    const d = this.mapDocuments.get(idDocument);
    if (d)
      d.listeCourbes.forEach(id => {
        const c = this.mapCourbes.get(id);
        if (c) {
          const dispositif = this.mapDispositifs.get(c.idDispositif);
          if (type == 'prod' && dispositif && dispositif.type == 'Generateur')
            res.push(c);
          if (type == 'conso' && dispositif && dispositif.type == 'Compteur')
            res.push(c);
        }
      });
    return res;
  }

  docInfos(idDoc: number) : EntiteDocument | null {
    if (! this.projetCourant)
      return null;
    let pc = this.projetCourant;
    for (let i=0; i < pc.documents.length; i++)
      if (pc.documents[i].id == idDoc)
        return pc.documents[i];
    return null;
  }

  async supprimeDocument(idDoc: number) {
    let d = this.docInfos(idDoc);
    if (d && (confirm('Veuillez confirmer la suppression du document ' + d.nom) )) {
      const res = await this.ps.supprimeDocument(d.id);
      console.log('Document supprimé : ', res);
      if (res) { // Rafraichir la page ...
        this.projetCourant = await this.ps.detailProjet(this.idProjet);
        this.ps.projetModifie.set(true);
      }
    }
    else
      console.error('ID de document inconnu : ' + idDoc);
  }

  async afficheGrapheCourbe(idCourbe: number, prod : boolean = false) {
    let options: ModalOptions = (prod) ? {
        component: DialogueGrapheComponent,
        componentProps:{id_courbe: idCourbe, prod: prod}
      } : {
      component: DialogueGrapheComponent,
      componentProps:{id_courbe: idCourbe, prod: prod},
      //initialBreakpoint: 1,
      //breakpoints: [0.25, 0.5, 0.75, 1],
      //handleBehavior: "cycle",
    };

    const dialogue = await this.modalCtlr.create(options);

    dialogue.onDidDismiss().then( (res) => {
      console.log("onDidDismiss()",res);
    });
    return await dialogue.present();
  }

  async dialogNewScenario(value:boolean) {
    this.newScenarioModalControl = value;
  }

  async onScenarioCreated() {
    this.projetCourant = await this.ps.detailProjet(this.idProjet);
    this.ps.projetModifie.set(true);
    this.delay(200).then(() => this.accordionEnergieStatus = 0);
  }

  async deleteScenario(idScenario: number){
    this.accordionEnergieStatus = -1;
    if (idScenario) {
      let res = await this.ps.supprimeScenario(idScenario);
      if (res) {
        this.projetCourant = await this.ps.detailProjet(this.idProjet);
        this.ps.projetModifie.set(true);
        this.delay(200).then(() => this.accordionEnergieStatus = 0);
      }
    }
  }

  countScenarios(myScenarios: any): number{
    return myScenarios.length;
  }
  
  // ========== Bilans ============================================
  async calculBilan(ev: MouseEvent, idScenario: number) {
    this.accordionBilanStatus = -1;
    ev.stopPropagation();
    this.calculEnCours?.present();
    console.log('Onglet BILAN en cours =>', this.currentStepBilan);
    if (this.currentStepBilan == 0) { // Si on est sur l'onglet 0 => Calcul du Bilan Energétique !
      this.ps.lanceCalculBilanEnergetique(idScenario).then((data) => {
        this.calculEnCours?.dismiss();
          console.log('PageProjet::calculBilanEnergetique()',data);
          if (data) {
            this.camembertsVisible = [];
            // Mise à jour du Map des scénarios
            let sc = this.mapScenarios.get(idScenario);
            if (sc) {
              sc.bilanEnergetique = data;
              this.mapScenarios.set(idScenario, sc);
            }
            this.projetCourant?.scenarios.forEach( (s, i) => {
              if (s.id == idScenario) {
                s.bilanEnergetique = data;
              }
            });
            //console.log('window.location.reload();');
            this.ps.bilanEnergieModifie.set(true);
          }
      });
    }
    if (this.currentStepBilan == 1) { // Si on est sur l'onglet 1 => Calcul du Bilan Facturation !
      this.ps.lanceCalculBilanFacturation(idScenario).then((data) => {
        this.calculEnCours?.dismiss();
          console.log('PageProjet::calculBilanFacturation()',data);
          if (data) {
            // Mise à jour du Map des scénarios
            let sc = this.mapScenarios.get(idScenario);
            if (sc) {
              sc.bilanFacturation = data;
              this.mapScenarios.set(idScenario, sc);
            }
            this.projetCourant?.scenarios.forEach( (s, i) => {
              if (s.id == idScenario) {
                s.bilanFacturation = data;
              }
            });
            //console.log('window.location.reload();');
          }
      });
    }
    
    if (this.currentStepBilan == 2) { // Si on est sur l'onglet 1 => Calcul du Bilan Financier !
      this.ps.lanceCalculBilanFinancier(idScenario).then((data) => {
        this.calculEnCours?.dismiss();
          console.log('PageProjet::calculBilanFinancier()',data);
          if (data) {
            // Mise à jour du Map des scénarios
            let sc = this.mapScenarios.get(idScenario);
            if (sc) {
              sc.bilanFinancier = data;
              this.mapScenarios.set(idScenario, sc);
            }
            this.projetCourant?.scenarios.forEach( (s, i) => {
              if (s.id == idScenario) {
                s.bilanFinancier = data;
              }
            });
            //console.log('window.location.reload();');
          }
      });
    }
    this.delay(200).then(() => this.accordionEnergieStatus = 0);
  }

  async afficheHeatmap(idScenario: number) {
    let idCourbe:number = 0;
    this.mapScenarios.get(idScenario)?.sorties.forEach((s, i) => {
      if(s.grandeur == "surplus_acc_global")
        idCourbe = s.id;
    })
    const dialogue = await this.modalCtlr.create({component: DialogueHeatmapComponent,componentProps:{id_courbe: idCourbe}, cssClass: 'modalHeatmap'});

    dialogue.onDidDismiss().then( (res) => {
      console.log("onDidDismiss()",res);
    });
    return await dialogue.present();
  }
  // Formate la date de calcul d'un bilan
  dateBilan(idScenario: number): string {
    let res = '---';
    const sc = this.mapScenarios.get(idScenario);

    if (this.currentStepBilan == 0) { // Si on est sur l'onglet 0 => Calcul du Bilan Energétique !
      if (sc && sc.bilanEnergetique?.date) {
        let d = new Date(sc.bilanEnergetique.date);
        res = d.toLocaleDateString() + ' à ' + d.toLocaleTimeString();
      }
    }
    if (this.currentStepBilan == 1) { // Si on est sur l'onglet 1 => Calcul du Bilan Facturation !
      if (sc && sc.bilanFacturation?.date) {
        let d = new Date(sc.bilanFacturation.date);
        res = d.toLocaleDateString() + ' à ' + d.toLocaleTimeString();
      }
    }
    if (this.currentStepBilan == 2) { // Si on est sur l'onglet 2 => Calcul du Bilan Financier !
      if (sc && sc.bilanFinancier?.date) {
        let d = new Date(sc.bilanFinancier.date);
        res = d.toLocaleDateString() + ' à ' + d.toLocaleTimeString();
      }
    }
    return res;
  }

  // Retourne la valeur d'un résultat de la simulation
  indicateurBilanGlobal(indexScenario: number, indicateur: string, precision: number): string {
    let res: string = '---'; 
    if (! this.projetCourant?.scenarios[indexScenario].bilanEnergetique || ! this.projetCourant?.scenarios[indexScenario].bilanEnergetique?.indicateurs.global)
      return res;
    const indicateurs: any = this.projetCourant?.scenarios[indexScenario].bilanEnergetique.indicateurs.global;
    //console.log(indicateurs);
    let v = Object.values
    if (indicateurs && indicateurs[indicateur]) {
      res = this.ps.formatteNombre(indicateurs[indicateur], precision);
    }
    return res;
  }
  
indicateurBilanParticipant(indexScenario: number, idParticipant: number, indicateur: string, precision: number): string {
    let res: string = '---';     
    this.projetCourant?.scenarios[indexScenario].bilanEnergetique.indicateurs.participants.forEach( (bilan: any) => {
      if (bilan.id == idParticipant && bilan[indicateur]) {
        res = this.ps.formatteNombre(bilan[indicateur], precision);
      }
    });
    return res;
  }

  accordeonBilanChanged(ev: CustomEvent) {
    console.log('accordeonBilanChanged', ev);
    //ev.stopImmediatePropagation();
    //this.camenbertVisible  = (ev.detail.value.length == 0) ? false : true;
    this.camembertsVisible = [];
    ev.detail.value.forEach( (s: any) => {
      this.camembertsVisible.push(parseInt(s));
    });
    console.log('accordeonBilanChanged - camembertsVisible', this.camembertsVisible);
    ev.stopPropagation();
  }

  camenbertVisible(idParticipant: number) : boolean {
    let res: boolean = false;
    this.camembertsVisible.forEach(id => {
      if (id == idParticipant)
        res = true;
    });
    return res;
  }

  formateBilanFacturation(n: number | null | undefined, precision: number) : string {
    if (n)
      return this.ps.formatteNombre(n.toString(), precision);
    return '0';
  }

  // ============ Etapes ============================================
  getStepProjetState(lequel: string) {  // Retourne l'un des états StepperState : 'number' | 'edit' | 'done' | 'error'
    if (! this.projetCourant || (this.projetCourant.id < 1) || (this.idProjet < 1) )
      return 'edit';
    switch(lequel) {
      case 'variables':
        return this.ps.valideProjet(this.projetCourant);
      case 'participants':
        let state = this.ps.valideEtapeParticipantsProjet(this.projetCourant);
        this.mapCompteurs.forEach(c => {
          if ( Array.isArray(c.tarif_fourniture) && c.tarif_fourniture.length == 0) {
            state = 'error';
            return;
          }
          if (! c.config_turpe || this.verifieSouscategorieTurpe(c.id) != '' || c.puissance < 3 || c.puissance >= 999999999) {
            state = 'error';
            return;
          }
        });
        return state;
      case 'energie':
        return this.ps.valideEtapeScenarioEnergie(this.projetCourant);
      case 'financier':
        return this.ps.valideEtapeScenarioFinancier(this.projetCourant);
      case 'bilan':
        return this.ps.valideEtapeBilan(this.projetCourant);
      default:
        return 'edit';
    }
  }

  changeEtape(ev: StepperSelectionEvent) {
    //console.log('PageProjet::changeEtape()', ev);
  }

  changeEtapeScenarioParticipants(ev: StepperSelectionEvent) {
    this.currentStepParticipant = ev.selectedIndex;
  }
  changeEtapeScenarioEnergie(ev: StepperSelectionEvent) {
    //console.log('PageProjet::changeEtapeScenarioEnergie()', ev);
    this.showToggleOrderingProd = (this.nbProducteurs > 1 && ev.selectedIndex == 0) ? true :  false;
  }

  changeEtapeScenarioFinancier(ev: StepperSelectionEvent) {
    //console.log('PageProjet::changeEtapeScenarioEnergie()', ev);
  }

  changeEtapeScenarioBilan(ev: StepperSelectionEvent) {
    //console.log('PageProjet::changeEtapeScenarioBilan()', ev);
    this.currentStepBilan = ev.selectedIndex;
  }

  memoriseScenarioCourant(ev: any) {
    if (ev.detail.value)
      this.scenarioCourant = ev.detail.value;
  }

  // ===== méthodes utilitaires (valide ou invalide un ion-input) ====
  setInputValid(el: HTMLElement) {
    ['ng-pristine', 'ng-invalid', 'ng-touched', 'ion-pristine', 'ion-invalid', 'ion-touched'].forEach(s => el.className = el.className.replace(s, ''));
  }

  setInputInvalid(el: HTMLElement) {
    el.className += 'ng-pristine ng-invalid ng-touched ion-pristine ion-invalid ion-touched';
  }

  triProjetsParIdDesc(a : EntiteProjet, b: EntiteProjet): number { 
    return -(a.id - b.id); 
  }

  reductionTexte(str: string | undefined | null, max?: number) {
    if (! max)
      max = 40;
    if (! str)
      return '';
    if (str && str.length > max) {
      let deb: string = '';
      let fin: string = '';
      let res: string = '';
      let i=0;
      while (res.length < max) {
        deb += str[i];
        fin = str[str.length-i-1] + fin;
        res = deb + ' ... ' + fin;
        i++;
      }
      return res;
    }
    return str;
   }
}

<ion-header>
  <ion-toolbar>
    <ion-title>Diagramme{{titreModal}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="fermeModal()" [strong]="true">Fermer</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div *ngIf="aucuneDonneeDisponible"><strong>Aucune donnée disponible !</strong></div>
  <div *ngIf="! aucuneDonneeDisponible"><strong>Total fichier : {{totalFichier | number : '0.0-0':'fr'}} kWh</strong></div>
  <div class="graphe-annuel" *ngIf="! aucuneDonneeDisponible && this.afficheGraphe" echarts [options]="echartsOptions" (chartInit)="onChartInit($event)" (chartClick)="onChartClick($event)"></div>
  <app-extrapolateur #extrapolateur *ngIf="!prod" [id_courbe]="id_courbe"></app-extrapolateur>
</ion-content>
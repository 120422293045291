import { timer } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild, effect } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IonGrid, IonRow, IonCol, IonList, IonItem, IonButton, IonLabel, IonIcon, IonSelect, IonSelectOption, IonProgressBar, IonInput } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import * as ionIcons from 'ionicons/icons';

import { AuthentificationService } from 'src/app/services/authentification.service';
import { ProjetService} from '../../services/projet.service';
import { EntiteProjet, ListeParticipants, ReponseApi } from 'src/app/services/EntiteProjet';
import { environment } from 'src/environments/environment';
import { SSE, SSEHeaders, SSEOptions } from 'sse.js';

export enum TypesFichiersTelecharges {
  prod = 'Production',
  conso = 'Consommation',
  projet = 'Projet'
};

@Component({ 
  standalone: true,
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss' ],
  imports: [ IonGrid, IonRow, IonCol, IonList, IonItem, IonButton, IonLabel, IonIcon, IonSelect, IonSelectOption, IonProgressBar, CommonModule, IonInput ]
})
export class UploadFileComponent  implements OnInit {
  @Input() type_fichier!: string;
  @Input() formats_acceptes!: string;
  @Input() id_projet!: number;
  @ViewChild(IonSelect) choixParticipant!: IonSelect;
  @ViewChild('annee') annee!: IonInput;
  
  status: "initial" | "uploading" | "success" | "fail" = "initial";
  etatUpload: string = '...';
  progressBuffer: number = 0.2;
  progressUpload: number = 0;
  msgErreur: string = '';
  file: File | null = null;
  fileName: string = '';
  fileType: string = '';
  fileSize: number = 0;
  typeFichier: string = '';
  projet : EntiteProjet | null = null;
  enabledUpload : boolean = false;

  constructor(private http: HttpClient, private auth: AuthentificationService, private ps: ProjetService) {
    addIcons(ionIcons);
    
    effect( () => {   // Recharger le projetCourant sur la sollicitation du signal 
      const etat = this.ps.participantModifie();
      if (etat) {
        //console.log('Le signal participantModifie passe à TRUE !');
        this.chargeProjet(true);
      }
    });
   }

  ngOnInit() {
    //console.log('UploadFileComponent::ngOnInit => type de fichier =' + this.type_fichier + ', id_projet=' + this.id_projet);
    if  (this.type_fichier) {
      switch(this.type_fichier) {
        case TypesFichiersTelecharges.conso: this.typeFichier = TypesFichiersTelecharges.conso; break;
        case TypesFichiersTelecharges.prod: this.typeFichier = TypesFichiersTelecharges.prod; break;
        case TypesFichiersTelecharges.projet: this.typeFichier = TypesFichiersTelecharges.projet; break;
        default: this.typeFichier = '';
      }
    }
    this.chargeProjet(false);
  }

  chargeProjet(majSignal:boolean) {
    if (this.id_projet)
      this.ps.detailProjet(this.id_projet).then(p => this.projet = p);
  }

  onChange(event: any) {
    console.log('FileUploadComponent => onChange()', event); 
    const file: File = event.target.files[0];
    if (file) {
      this.status = "initial";
      this.file = file;
      this.fileName = file.name;
      this.fileType = file.type;
      this.fileSize = file.size;
      if (this.type_fichier == TypesFichiersTelecharges.prod) {
        this.enabledUpload = true;
        console.log('FileUploadComponent onChange => CdC ' + this.type_fichier + ' renseigné !');
      }
      else if (this.type_fichier == TypesFichiersTelecharges.conso) {
        this.enabledUpload = true;
        console.log('FileUploadComponent onChange => CdC ' + this.type_fichier + ' renseigné !');
      }
      else if (this.type_fichier == TypesFichiersTelecharges.projet) {
        this.annee.setFocus();
        console.log('FileUploadComponent onChange => CdC ' + this.type_fichier + ' renseigné !');
      }
    }
  }
  changeAnnee(event : any){
    this.enabledUpload = (event.target.value > 2000) ? true : false ;
  }
  async onUpload() { 
    if (this.file) {
      let token = await this.auth.getToken();

      let headers : SSEHeaders = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      };

      const formData = new FormData();
      formData.append("file", this.file, this.file.name);
      let url: string = '';
      
      if (this.typeFichier == TypesFichiersTelecharges.prod) {
        formData.append('participant', this.choixParticipant.value);
        url = `${environment.apiHost}api/projet/${this.id_projet}/upload/production`;
      } else if (this.typeFichier == TypesFichiersTelecharges.conso){
        formData.append('participant', this.choixParticipant.value);
        url = `${environment.apiHost}api/projet/${this.id_projet}/upload/consommation`;
      } else if (this.typeFichier == TypesFichiersTelecharges.projet){
        if (this.annee.value) formData.append('annee', this.annee.value.toString());
        url = `${environment.apiHost}api/projet/${this.id_projet}/upload/projet`;
      }
      /*
      let headerDict = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
      let requestOptions = {                                                                                                                                                                                
        headers: new HttpHeaders(headerDict),
      };

      console.log('Upload du fichier ' + this.fileName + ' vers ' + url);
      const upload$ = this.http.post<ReponseApi>(url, formData, requestOptions);
      this.status = "uploading";
      upload$.subscribe({
        next: (res) => {
          console.log('Upload du fichier => res :', res);
          if (res.statut.toUpperCase() == 'OK') {
            this.status = "success";
            this.ps.projetModifie.set(true);
            this.file = null;
            this.status = 'initial';
          } else {
            this.status = "fail";
            this.msgErreur = res.message + ' (' + res.details + ')';
          }
        },
        error : (err) => { console.log('Erreur upload fichier !')}
      });
      */
      const options : SSEOptions = {
        headers: headers,
        payload: formData,
        method: 'POST',
        withCredentials: false,
        start: true,
        debug: true
      };
      this.status = "uploading";
      const sse = new SSE(url, options);
      sse.addEventListener('message', (e: any) => {
        if (e.data && e.data != '') {
          const res = JSON.parse(e.data);
          console.log('SSE message : ', res);
          if (res.statut == 'progression') {
            if(res.message)
              this.etatUpload = (res.progres) ? res.message + ' (' + res.progres + ')' : res.message;
            if (res.progres)
              this.progressUpload = parseInt(res.progres)/100;
          }
          else if (res.statut == 'erreur') {
            this.status = "fail";
            this.msgErreur = res.details ;
          }
          else if (res.statut.toUpperCase() == 'OK') {
            timer(2000).subscribe( () => {
              this.status ="success";
              this.ps.projetModifie.set(true);
              // this.file = null;
              // this.status = 'initial';
            });
          }
        }
      });
    }
  }
}

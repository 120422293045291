<ion-grid>
  <ion-row style="margin-bottom: 20px;">
    <ion-button size="small" class="ion-float-left" (click)="enregistrerVariableFinancieres()" >Enregistrer</ion-button>
  </ion-row>
  <ion-row>
    <ion-col lg="3">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            Taxes
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-item >
              <ion-input type="number" label="Consommation globale annuellle" labelPlacement="floating" #conso_globale_annuelle></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Hausse prix élec (en %) " labelPlacement="floating" #hausse_annuelle_elec></ion-input>
            </ion-item>
            <ion-item >
              <ion-label>Accise (part ACC)</ion-label>
              <mat-radio-group value="false" #accise_acc>
                <mat-radio-button color="primary" value="true" [checked]="accise_acc">Oui</mat-radio-button>
                <mat-radio-button color="primary" value="false" [checked]="!accise_acc">Non</mat-radio-button>
              </mat-radio-group>
            </ion-item>
            <ion-item >
              <ion-label>Mode valorisation ACC</ion-label>
              <mat-radio-group value="vente" [(ngModel)]="mode_valorisation" (change)="majInterface()">
                <mat-radio-button color="primary" value="reduction">Taux réduction</mat-radio-button>
                <mat-radio-button color="primary" value="prix">Prix</mat-radio-button>
                <mat-radio-button color="primary" value="don">Gratuit (don)</mat-radio-button>
              </mat-radio-group>
            </ion-item>
            <ion-item>
              @let edit_tarif_reduction = (type_projet == "ACC" && mode_valorisation == "reduction") ? true : false;
              <ion-input type="number" label="Réduction tarif ACC (en %)" labelPlacement="floating" [value]=reduction_acc #reduction_acc [disabled]="!edit_tarif_reduction" (ionChange)="majInterface()"></ion-input>
            </ion-item>
               <!-- SAISIE DES TARIFS DISTRIBUTEUR -->
              <ion-row>
                <ion-col size="8"><ion-label>Tarif du kWh (c€)</ion-label></ion-col>
                <ion-col size="2"><ion-label>Fournisseur</ion-label></ion-col>
                <ion-col size="2"><ion-label>ACC</ion-label></ion-col>
              </ion-row>
              <ion-list class="saisie-tarif" >
                <!-- @if (libelleTarif === 'BASE') { -->
                <ion-item>
                  <ion-col size="8"><ion-label>Base :</ion-label></ion-col>
                  <ion-col size="2"><input matInput [value]="tarifBASE_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifBASE matInput [(value)]="tarifBASE_acc" [disabled]="mode_valorisation != 'prix'" (change)="majInterface()"></ion-col>
                </ion-item>
                <!-- }
                @if (libelleTarif === 'HP/HC') { -->
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Pleines :</ion-label></ion-col>
                  <ion-col size="2"><input matInput [value]="tarifHP_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHP matInput [(value)]="tarifHP_acc" [disabled]="mode_valorisation != 'prix'" (change)="majInterface()"></ion-col>
                </ion-item>
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Creuses :</ion-label></ion-col>
                  <ion-col size="2"><input matInput [value]="tarifHC_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHC matInput [(value)]="tarifHC_acc" [disabled]="mode_valorisation != 'prix'" (change)="majInterface()"></ion-col>
                </ion-item>
                <!-- }
                @if (libelleTarif === 'P/HCH/HPH/HCB/HPB') {
                  @if (choixPointeF) { -->
                  <ion-item>
                    <ion-col size="8"><ion-label>Heures de Pointe :</ion-label></ion-col>
                    <ion-col size="2"><input matInput [value]="tarifP_four" disabled></ion-col>
                    <ion-col size="2"><input #tarifP matInput [(value)]="tarifP_acc" [disabled]="mode_valorisation != 'prix'" (change)="majInterface()"></ion-col>
                  </ion-item>
                <!-- } -->
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Pleines Haute saison :</ion-label></ion-col>
                  <ion-col size="2"><input matInput [value]="tarifHPH_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHPH matInput [(value)]="tarifHPH_acc" [disabled]="mode_valorisation != 'prix'" (change)="majInterface()"></ion-col>
                </ion-item>
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Creuses Haute saison :</ion-label></ion-col>
                  <ion-col size="2"><input matInput [value]="tarifHCH_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHCH matInput [(value)]="tarifHCH_acc" [disabled]="mode_valorisation != 'prix'" (change)="majInterface()"></ion-col>
                </ion-item>
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Pleines Basse saison :</ion-label></ion-col>
                  <ion-col size="2"><input matInput [value]="tarifHPB_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHPB matInput [(value)]="tarifHPB_acc" [disabled]="mode_valorisation != 'prix'" (change)="majInterface()"></ion-col>
                </ion-item>
                <ion-item>
                  <ion-col size="8"><ion-label>Heures Creuses Basse saison :</ion-label></ion-col>
                  <ion-col size="2"><input matInput [value]="tarifHCB_four" disabled></ion-col>
                  <ion-col size="2"><input #tarifHCB matInput [(value)]="tarifHCB_acc" [disabled]="mode_valorisation != 'prix'" (change)="majInterface()"></ion-col>
                </ion-item>
                <!-- } -->
              </ion-list>
            <!-- </ion-item> -->
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-col>
    <!-- <ion-col lg="3">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            Revenus
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-item >
              <ion-checkbox #use_tarif_OA>Utilisation Tarifs OA</ion-checkbox>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Revalorisation annuelle du tarif (en %)" labelPlacement="floating" #revalorisation_annuelle_OA></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Tarif vente de surplus (c€/kWh)" labelPlacement="floating" #tarif_achat_OA_VT></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Tarif achat agrégateur surplus (c€/kWh) " labelPlacement="floating" #tarif_achat_OA_surplus></ion-input>
            </ion-item>
            <ion-item >
              <ion-checkbox #use_complement_remuneration>Utilisation complément rémunération</ion-checkbox>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-col> -->
  </ion-row>
</ion-grid>
import { Routes, mapToCanActivate } from '@angular/router';
import { hasAdminRoleGuard, hasNoValidTokenGuard, checkTokenValidityAndRefreshIfNeededGuard } from './services/authentification.service';
import { HomePage } from './Pages/home/home.page';
import { ProjetPage } from './Pages/projet/projet.page';
import { CreationProjetPage } from './Pages/creation-projet/creation-projet.page';
import { ConnexionPage } from './Pages/connexion/connexion.page';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'projet',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomePage,
    canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard])
  },
  {
    path: 'projet/:idProjet',
    component: ProjetPage,
    canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard]),
  },
  {
    path: 'projet',
    component: ProjetPage,
    canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard]),
    children: [
      {
        path: 'creation',
        component: CreationProjetPage,
        canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard, hasAdminRoleGuard])
      }
    ]
  },
  {
    path: 'creation-projet',
    component: CreationProjetPage,
    canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard, hasAdminRoleGuard])
  },
  {
    path: 'connexion',
    component: ConnexionPage,
    canActivate: mapToCanActivate([hasNoValidTokenGuard])
  },
  {
    path: 'config',
    loadComponent: () => import('./Pages/config/config.page').then( m => m.ConfigPage)
  },
  {
    path: 'config/turpe',
    loadComponent: () => import('./Pages/config/turpe/turpe.page').then( m => m.TurpePage)
  },
  {
    path: 'config/taxes',
    loadComponent: () => import('./Pages/config/taxes/taxes.page').then( m => m.TaxesPage)
  },
  {
    path: 'config/plan-financier',
    loadComponent: () => import('./Pages/config/plan-financier/plan-financier.page').then( m => m.PlanFinancierPage)
  },
  {
    path: 'config/tarif-oa',
    loadComponent: () => import('./Pages/config/tarif-oa/tarif-oa.page').then( m => m.TarifOaPage)
  },




];

/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { CommonModule } from '@angular/common';
import { Component, OnInit, AfterViewInit, DoCheck, ViewChild, TemplateRef, ViewContainerRef, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { Edge, Node, VflowComponent, VflowModule, Connection, ConnectionSettings, EdgeChange, NodeChange, Point } from 'ngx-vflow';
import { ProjetPage } from 'src/app/Pages/projet/projet.page';
import { EntiteParticipant, ListeDispositifs } from 'src/app/services/EntiteProjet';
import { ProjetService } from 'src/app/services/projet.service';

@Component({
  selector: 'app-vflow-gestion-participants',
  templateUrl: './vflow-gestion-participants.component.html',
  styleUrls: ['./vflow-gestion-participants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [VflowModule, CommonModule]
})
export class VflowGestionParticipantsComponent  implements OnInit, DoCheck, AfterViewInit {

  @ViewChild(VflowComponent) vflow!: VflowComponent;
  /* private nodeGroups: Node[] = [
    {
      id: 'generateurs',
      point: { x: 10, y: 10},
      type: 'default-group',
      width: 250,
      height: 780,
      color: 'red'
    },
    {
      id: 'participants',
      point: { x: 300, y: 10},
      type: 'default-group',
      width: 250,
      height: 780,
      color: 'green'
    },
    {
      id: 'compteurs',
      point: { x: 600, y: 10},
      type: 'default-group',
      width: 250,
      height: 780,
      color: 'blue'
    }
  ]; */
  public nodes: Node[] = [];
  public edges: Edge[] = [];  /* { id: string, source: string, target: string, type: 'template'} */
  private espacementY = 110;
  private xGenerateurs = 10;
  private xParticipants = 310;
  private xCompteurs = 560;
  private nodesOk: boolean = false;

  constructor(private _parent: ProjetPage, private ps: ProjetService) {
    //this.nodes = [...this.nodeGroups];
   }

   private findIndexdNode(id: string) : number {
    let res = -1;
    if (! this.nodes)
      return res;
    this.nodes.forEach( (n, index) => {
      if (n.id == id) {
        res = index;
        return;
      }
    });
    return res;
   }

  ngOnInit() {
    //console.log('VflowGestionParticipantsComponent::ngOnInit', this._parent.mapDispositifs);
  }

  ngAfterViewInit(): void {
    //console.log('VflowGestionParticipantsComponent::ngAfterViewInit', this.vflow);
    this.vflow.nodesChange$.subscribe((changes) => {
      //console.log('VflowGestionParticipantsComponent::nodesChange', changes);
/*      if (changes.length < 1)
        return;
      const c: NodeChange = changes[0];
      if (c.type != 'position')
        return;
      let i = this.findIndexdNode(c.id);
      if (i < 0)
        return;
      const n = this.nodes[i] as any;
      if (n && n.data.type) {
        let xMin: number = 10;
        switch(n.data.type) {
          case 'generateur':  xMin = this.xGenerateurs; break;
          case 'participant': xMin = this.xParticipants; break;
          case 'compteur': xMin = this.xCompteurs; break;
        }
        if (c.point.x != xMin) {
          const p : Point = {x: xMin, y: c.point.y};
          this.nodes[i].point = p;
          console.log('VflowGestionParticipantsComponent::nodesChange coordonnée corrigée !', p);

        }
      }
*/
    });

    //this.vflow.edgesChange$.subscribe((changes) => {
    //  console.log('edges', changes);
    //});
  }

  ngDoCheck(): void {
    if (this.nodesOk || this._parent.mapParticipants.size == 0)
        return;
    this.initialiseGraphe();
  }

  initialiseGraphe(): void {
    //console.log('VflowGestionParticipantsComponent::ngDoCheck', this._parent.mapParticipants);
    let nbCompteurs: number = 0;
    let nbGenerateurs: number = 0;
    let nbParticipants: number = 0;
    let idNodes: string[] = [];
    this._parent.mapParticipants.forEach(p => {
      //console.log('VflowGestionParticipantsComponent::ngDoCheck::participant', p);
      let typeParticipant = '';
      if (p.producteur && p.producteur.generateurs.length > 0 && p.consommateur && p.consommateur.compteurs.length > 0)
        typeParticipant = 'Participant ACI';
      else if (p.producteur && p.producteur.generateurs.length > 0)
        typeParticipant = 'Participant - Injection Totale';
      else if (p.consommateur && p.consommateur.compteurs.length > 0)
        typeParticipant = 'Participant ACC';
      this.nodes.push({ // Node Participant
        id: 'p'+p.id,
        point: { x: this.xParticipants, y: 10 + nbParticipants * this.espacementY },
        type: 'html-template',
        data: {
          nom: p.nom,
          type: typeParticipant,
          class: 'participant',
          leftTemplate: 'handleTemplate',
          rightTemplate: 'handleTemplate'
        }
      });
      idNodes.push('p'+p.id);
      // Ajouter les nodes générateurs et les liaisons correspondantes au participant
      if (p.producteur && p.producteur.generateurs.length > 0) {
        p.producteur.generateurs.forEach(g => {
          //console.log('VflowGestionParticipantsComponent::ngDoCheck', g);
          this.nodes.push({ // Node générateur
            id: 'g'+g.id,
            point: { x: this.xGenerateurs, y: 10 + nbParticipants * this.espacementY},
            type: 'html-template',
            data: {
              nom: g.document,
              type: 'Production',
              class: 'generateur',
              leftTemplate: 'hiddenHandleTemplate',
              rightTemplate: 'handleTemplate'
            }
          });
          nbGenerateurs++;
          idNodes.push('g'+g.id);
          this.edges.push({ // Edge generateur -> producteur
            id: 'g' + g.id + ' -> p' + p.id,
            source: 'g' + g.id,
            target: 'p' + p.id,
            type: 'template'
          });
        });
      }
      if (p.consommateur && p.consommateur.compteurs.length > 0) {
        p.consommateur.compteurs.forEach(c => {
          this.nodes.push({ // Node compteur
            id: 'c'+c.id,
            point: { x: this.xCompteurs, y: 10 + nbParticipants * this.espacementY},
            type: 'html-template',
            data: {
              nom: c.code,
              type: 'Consommation',
              class: 'compteur',
              leftTemplate: 'handleTemplate',
              rightTemplate: 'hiddenHandleTemplate'
            }
          });
          nbCompteurs++;
          idNodes.push('c'+c.id);
          this.edges.push({ // Edge participant -> compteur
            id: 'p' + p.id + ' -> c' + c.id,
            source: 'p' + p.id,
            target: 'c' + c.id,
            type: 'template'
          });
        });
      }
      nbParticipants++;
    });
    // Rechercher les dispositifs non reliés
      if (nbParticipants > nbCompteurs)
        nbCompteurs = nbParticipants;
      this._parent.mapDispositifs.forEach(d => {
      if (d.type == 'Compteur') {
        const idCompteur = 'c' + d.id;
        //console.log('Vérification compteur ' + idCompteur);
        if (! idNodes.includes(idCompteur)) {
          this.nodes.push({
            id: 'c'+d.id,
            point: { x: this.xCompteurs, y: 10 + this.premierePositionLibre('Compteur') * this.espacementY},
            type: 'html-template',
            data: {
              nom: d.code,
              type: 'Consommation',
              class: 'compteur',
              leftTemplate: 'handleTemplate',
              rightTemplate: 'hiddenHandleTemplate'
            }
          });
          nbCompteurs++;
          idNodes.push(idCompteur);
          //console.log('Compteur inséré :', this.nodes[this.nodes.length-1]);
        }
      }
      else if (d.type == 'Generateur') {
        const idGenerateur = 'g' + d.id;
        if (! idNodes.includes(idGenerateur)) {
          this.nodes.push({
            id: 'g'+d.id,
            point: { x: this.xGenerateurs, y: 10 + nbGenerateurs * this.espacementY},
            type: 'html-template',
            data: {
              nom: d.code,
              type: 'Production',
              class: 'generateur',
              leftTemplate: 'hiddenHandleTemplate',
              rightTemplate: 'handleTemplate'
            }
          });
          nbGenerateurs++;
          idNodes.push(idGenerateur);
        }
      }
    });
    this.nodesOk = true;
    if (this.nodes.length > 0)
      this.vflow.nodes = this.nodes;
    if (this.edges.length > 0)
      this.vflow.edges = this.edges;
    if ( (this.vflow.nodes && this.vflow.nodes.length > 0) || (this.vflow.edges && this.vflow.edges.length > 0) )
      this.vflow.panTo({x:0,y:0});
  }

  public CreeConnexion(ev: any) {
    if (ev.source && (ev.source.match(/^p[0-9]+$/) || ev.source.match(/^g[0-9]+$/))) {
      console.log('VflowGestionParticipantsComponent::CreeConnexion', ev);
      let creation = false;
      let idParticipant = -1;
      let idDispositif = -1;
      if (ev.source.match(/^g[0-9]+$/) && ev.target.match(/^p[0-9]+$/)) {         // Créer la liaison générateur -> Producteur
        idDispositif = ev.source.replace(/[^0-9]/g, '');
        idParticipant = ev.target.replace(/[^0-9]/g, '');
      } else if (ev.source.match(/^p[0-9]+$/) && ev.target.match(/^c[0-9]+$/)) {  // Créer la liaison Consommateur -> Compteur
        idDispositif = ev.target.replace(/[^0-9]/g, '');
        idParticipant = ev.source.replace(/[^0-9]/g, '');
      }
      console.log('Vérifier si le dispositif ' + idDispositif + ' est déjà relié à un participant ...');
      let trouve = false;
      this.edges.forEach(e => {
        console.log('test rattachement p' + idParticipant + ' avec source=' + e.source + ' ou target=' + e.target);
        if (e.source.match(/^p[0-9]+$]/) && e.target.match(/^c[0-9]+$/)) {
          trouve = true;
        } else if (e.target.match(/^p[0-9]+$]/) && e.source.match(/^c[0-9]+$/)) {
          trouve = true;
        }
      });
      if (trouve) {
        alert('Impossible de relier plusieurs participants au même dispositif !')
        return;
      }
      console.log('Créer la liaison entre le dispositif ' + idDispositif + ' et le participant ' + idParticipant);
      const part = this._parent.getParticipant(idParticipant);
      if (part) {
        console.log('Créer la liaison entre le dispositif + ' + idDispositif + ' et le participant ', part);
        this.edges.push({
          id: ev.source + ' -> ' + ev.target,
          source: ev.source,
          target: ev.target,
          type: 'template'
        });
        this.vflow.edges = this.edges;
        this.ps.majDataGenerateur(idDispositif, {idParticipant: idParticipant}).then( res => {
          //if (res.statut == 'ok')
        });
      }
      else
        console.log('Participant ' + idParticipant + ' non trouvé :', this._parent.mapParticipants);
    }
  }

  public deleteNode(node: Node) {
    console.log('VflowGestionParticipantsComponent::deleteNode', node);
    let suppression = true;
    if (node.id.match(/^p[0-9]+$/)) { // Demande de suppression d'un participant
      const idPart = parseInt(node.id.replace(/[^0-9]/g, ''));
      const part = this._parent.getParticipant(idPart);
      if (part) {
        if (part?.consommateur && part.consommateur.compteurs.length > 0)
          suppression = false;
        if (part?.producteur && part.producteur.generateurs.length > 0)
          suppression = false;
        if (suppression)
          this.supprimeParticipant(node.id, idPart);
        else
          alert("La suppression d'un participant encore relié à un dispositif n'est pas autorisée.");
      }
    }
    else if (node.id.match(/^[cg][0-9]+$/)) {  // Demande de suppression d'un dispositif
      const type = (node.id.match(/^c[0-9]+$/)) ? 'compteur' : 'generateur';
      const idDispositif = parseInt(node.id.replace(/[^0-9]/g, ''));
      const disp = this._parent.mapDispositifs.get(idDispositif);
      if (disp) {
        if (type == 'compteur') {   // Vérifier si le compteur est rattaché à un participant
          const c = this._parent.getCompteurFromDispositif(idDispositif);
          this._parent.projetCourant?.participants.forEach(p => {
            if (p?.consommateur && p.consommateur.compteurs.length > 0) {
              p.consommateur.compteurs.forEach(cc => {
                if (cc.id == c.id)
                  suppression = false;
              });
            }
          });
        } else {  // Vérifier si le générateur est rattaché à un participant
          const g = this._parent.getGenerateurFromDispositif(idDispositif);
          this._parent.projetCourant?.participants.forEach(p => {
            if (p.producteur && p.producteur.generateurs.length > 0) {
              p.producteur.generateurs.forEach(gg => {
                if (gg.id == g.id)
                  suppression =  false;
              });
            }
          });
        }
        if (suppression)
          this.supprimeDispositif(node.id, idDispositif);
        else
          alert("La suppression d'un dispositif encore relié à un participant n'est pas autorisée.");
      }
    }
  }

  public deleteEdge(edge: Edge) {
    console.log('VflowGestionParticipantsComponent::deleteEdge', edge);
    let idDispositif: number = -1;
    let idParticipant: number = -1;
    let dataUpdate = null;
    let suppression: boolean = false;
    if (edge.source.match(/^g[0-9]+$/) && edge.target.match(/^p[0-9]+$/)) {         // Suppression d'une liaison entre un générateur et le participant
      idDispositif = parseInt(edge.source.replace(/[^0-9]/g, ''));
      idParticipant = parseInt(edge.target.replace(/[^0-9]/g, ''));
      //const part = this._parent.mapParticipants.get(idParticipant);
      const part = this._parent.getParticipant(idParticipant);
      if (part && part.producteur && part.producteur.id > 0) {
        dataUpdate = { idProducteur : -1 };
        console.log('Supprimer la relation entre le générateur '+ idDispositif + ' et le participant ', part);
      }
      
    } else if (edge.source.match(/^p[0-9]+$/) && edge.target.match(/^c[0-9]+$/)) {  // Suppression d'une liaison entre un compteur et le participant
      idDispositif = parseInt(edge.target.replace(/[^0-9]/g, ''));
      idParticipant = parseInt(edge.source.replace(/[^0-9]/g, ''));
      //const part = this._parent.mapParticipants.get(idParticipant);
      const part = this._parent.getParticipant(idParticipant);
      if (part && part.consommateur && part.consommateur.id > 0) {
        dataUpdate = { idConsommateur : -1 };
        console.log('Supprimer la relation entre le compteur '+ idDispositif + ' et le participant ', part);
        
      }
    }
    if (dataUpdate) {
      this.edges = this.edges.filter(e => e.id !== edge.id);
      this.ps.majDataGenerateur(idDispositif, dataUpdate).then( res => {
        console.log('Réponse API : ', res);
        //if (res.statut == 'ok')
      });
    }
  }

  private premierePositionLibre(dataType: 'Participant' | 'Compteur' | 'Generateur') : number {
    let res = 0;
    let positions: number[] = [];
    let found: boolean = false;
    const posX = (dataType == 'Compteur') ? this.xCompteurs : this.xGenerateurs;
    this.nodes.forEach(n => {
      if (n.point.x == posX)
        positions.push( (n.point.y - 10) / this.espacementY)
    });
    positions.sort();
    positions.forEach( (pos, index) => {
      if (! found && pos > index) {
        found = true;
        res = index;
      }
    });
    if (found)
      return res;
    else
      return positions.length + 1;
  }

  private supprimeParticipant(idNode: string, idPart: number) {
    this.ps.supprimeParticipant(idPart).then( (ok: boolean) => {
      if (ok) {
        this.nodes = this.nodes.filter(n => n.id !== idNode);
        if (this.nodes.length > 0)
          this.vflow.nodes = this.nodes;
        if (this.edges.length > 0)
          this.vflow.edges = this.edges;
        if ( (this.vflow.nodes && this.vflow.nodes.length > 0) || (this.vflow.edges && this.vflow.edges.length > 0) )
          this.vflow.panTo({x:0,y:0});
      }
    });
  }

  private supprimeDispositif(idNode: string, idDispositif: number) {
    console.log('Appel de supprimeDispositif(' + idNode + ',' + idDispositif + ')');
    this.ps.supprimeDispositif(idDispositif).then( (ok: boolean) => {
      if (ok) {
        this.nodes = this.nodes.filter(n => n.id !== idNode);
        if (this.nodes.length > 0)
          this.vflow.nodes = this.nodes;
        if (this.edges.length > 0)
          this.vflow.edges = this.edges;
        if ( (this.vflow.nodes && this.vflow.nodes.length > 0) || (this.vflow.edges && this.vflow.edges.length > 0) )
          this.vflow.panTo({x:0,y:0});
      }
    });
  }

}


<ion-grid>
  <ion-row style="margin-bottom: 20px;">
    <ion-button size="small" class="ion-float-left" (click)="enregistrerVariableFinancieres()" >Enregistrer</ion-button>
  </ion-row>
  <ion-row>
    <ion-col lg="3">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            Financement
            <!-- <ion-label class="ion-float-right">Date d'application</ion-label> -->
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-item >
              <ion-input type="number" label="CAPEX HT" labelPlacement="floating" #capex (ionChange)="majApport()"></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="OPEX HT/an" labelPlacement="floating" #opex></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Taux actualisation (en %)" labelPlacement="floating" #taux_actualisation></ion-input>
              <!-- <ion-datetime-button class="ion-datetime-button" datetime="accise_date"></ion-datetime-button>
              <ion-modal [keepContentsMounted]="true">
                <ng-template>
                  <ion-datetime id="accise_date" presentation="date" #accise_date></ion-datetime>
                </ng-template>
              </ion-modal> -->
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Taux intérêt emprunt (en %)" labelPlacement="floating" #taux_emprunt></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Part de l'apport en fonds propres (en %)" labelPlacement="floating" #part_apport (ionChange)="majApport()"></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Apport en fonds propres (en €) Calcul CAPEX et part de l'apport en %" labelPlacement="floating" #apport [disabled]="true"></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Durée emprunt (en années)" labelPlacement="floating" #duree_emprunt></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Hausse annuelle OPEX (en %)" labelPlacement="floating" #hausse_annuelle_OPEX></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Taux IFER (en €/kW)" labelPlacement="floating" #IFER></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Coefficient dégradation performance" labelPlacement="floating" #coef_degradation_performance></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Durée amortissement" labelPlacement="floating" #duree_ammortissement></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Durée simulation bilan" labelPlacement="floating" #duree_simulation></ion-input>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col lg="3">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            Revenus
            <!-- <ion-label class="ion-float-right">Date d'application</ion-label> -->
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-item >
              <ion-checkbox #use_tarif_OA>Utilisation Tarifs OA</ion-checkbox>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Revalorisation annuelle du tarif (en %)" labelPlacement="floating" #revalorisation_annuelle_OA></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Tarif vente de surplus (c€/kWh)" labelPlacement="floating" #tarif_achat_OA_VT></ion-input>
            </ion-item>
            <ion-item >
              <ion-input type="number" label="Tarif achat agrégateur surplus (c€/kWh) " labelPlacement="floating" #tarif_achat_OA_surplus></ion-input>
            </ion-item>
            <ion-item >
              <ion-checkbox #use_complement_remuneration>Utilisation complément rémunération</ion-checkbox>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
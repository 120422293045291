import { CommonModule, registerLocaleData } from '@angular/common';
import { Component, effect, ElementRef, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';

import { IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonLabel, IonList, IonListHeader,
  IonItem, IonAccordionGroup, IonAccordion, IonButtons, IonButton, IonInput, IonSelect, IonSelectOption, IonIcon,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonLoading,IonCheckbox, IonSegment, 
  IonSegmentButton, IonToggle, IonDatetime, IonModal, IonPopover, IonFabButton, IonFab, IonSpinner } from '@ionic/angular/standalone';
import { ConfigurationApiService } from 'src/app/services/configuration-api.service';
import { EntiteCompteur, EntiteConsommateurScenario, EntiteScenario } from 'src/app/services/EntiteProjet';
import { ProjetService } from 'src/app/services/projet.service';
import { MatRadioButton, MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import localeFr from '@angular/common/locales/fr';
import { timer } from 'rxjs';
registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-plan-financier-par-consommateur',
  templateUrl: './plan-financier-par-consommateur.component.html',
  styleUrls: ['./plan-financier-par-consommateur.component.scss'],
  standalone: true,
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
  imports: [IonSpinner, IonFab, IonFabButton, IonPopover, IonModal, MatRadioButton, MatRadioGroup, MatRadioModule,FormsModule,
    CommonModule, IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonButtons,
    IonLabel, IonList, IonListHeader, IonItem, IonAccordionGroup, IonAccordion, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon,
   IonSegment, IonSegmentButton, IonLoading,IonCheckbox,
    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonToggle, IonDatetime]
})

export class PlanFinancierParConsommateurComponent  implements OnInit {
  @Input() idx_scenario!: number;
  @Input() id_participant!: number;
  @Input() id_compteur!: number;
  @Input() id_projet!: number | undefined;
  @Input() id_consommateur!: number;
  @Input() scenario!: EntiteScenario;
  @Input() type_projet!: string | undefined;

  // @ViewChild('conso_globale_annuelle') conso_globale_annuelle!: IonInput;
  @ViewChild('hausse_annuelle_elec') hausse_annuelle_elec!: IonInput;
  @ViewChild('reduction_acc') reduction_acc!: IonInput;

  @ViewChild('tarifBASE') tarifBASE!: ElementRef;
  @ViewChild('tarifHP') tarifHP!: ElementRef;
  @ViewChild('tarifHC') tarifHC!: ElementRef;
  @ViewChild('tarifP') tarifP!: ElementRef;
  @ViewChild('tarifHPH') tarifHPH!: ElementRef;
  @ViewChild('tarifHCH') tarifHCH!: ElementRef;
  @ViewChild('tarifHPB') tarifHPB!: ElementRef;
  @ViewChild('tarifHCB') tarifHCB!: ElementRef;
  
  @ViewChild('accise_acc') accise_acc!: IonCheckbox;

  mode_valorisation!: string;
  consommateur! : EntiteConsommateurScenario;
  compteur! : EntiteCompteur | null;
  libelleTarif!: string | undefined;

  tarifBASE_four! : number;
  tarifHP_four! : number;
  tarifHC_four! : number;
  tarifP_four! : number;
  tarifHPH_four! : number;
  tarifHCH_four! : number;
  tarifHPB_four! : number;
  tarifHCB_four! : number;

  tarifBASE_acc! : number;
  tarifHP_acc! : number;
  tarifHC_acc! : number;
  tarifP_acc! : number;
  tarifHPH_acc! : number;
  tarifHCH_acc! : number;
  tarifHPB_acc! : number;
  tarifHCB_acc! : number;

  tarifBASE_acc_display! : number;
  tarifHP_acc_display! : number;
  tarifHC_acc_display! : number;
  tarifP_acc_display! : number;
  tarifHPH_acc_display! : number;
  tarifHCH_acc_display! : number;
  tarifHPB_acc_display! : number;
  tarifHCB_acc_display! : number;

  constructor(private cas: ConfigurationApiService, private ps: ProjetService) { 
    //console.log("composant PF constructor");
    effect( () => {   // Recharger le projetCourant sur la sollicitation du signal 
      const etat = this.ps.projetModifie();
      if (etat) {
        // console.log('Le signal ProjetModifié passe à TRUE !');
        if (this.id_projet)
          this.ps.detailProjet(this.id_projet).then(p => {
            if (p) this.scenario = p.scenarios[this.idx_scenario];
            this.iniInterface();
            
            timer(500).subscribe(() => {
              this.ps.projetModifie.set(false);
              // console.log("projetModifie => FALSE");
            });
          }); 
      }
    });
  }

  ngOnInit() {
    // console.log("composant PF");
    // console.log("scenario", this.scenario);
    // console.log("id_participant", this.id_participant);
    // console.log("id_producteur", this.id_producteur);
  }
  ngAfterViewInit() {
    this.iniInterface();
  }

  iniInterface(){
    
    if(this.scenario)
      this.scenario.consommateurs.forEach( conso => {
        if(conso.idConsommateur == this.id_consommateur)
        {        
          // this.conso_globale_annuelle.value   = -1;  // -------------A modifier avec le retour du back quand il sera prêt
          this.mode_valorisation              = conso.tarification_acc; // A modifier tarification par valorisation avec le retour du back quand il sera prêt
          this.accise_acc.checked             = conso.taxes.accise_acc;
          this.hausse_annuelle_elec.value     = conso.taxes.hausse_annuelle_elec;
          this.reduction_acc.value            = conso.reduction_acc;
          
          if (conso.tarif_acc.BASE )    this.tarifBASE_acc = this.tarifBASE_acc_display= conso.tarif_acc.BASE;
          if (conso.tarif_acc.HP )      this.tarifHP_acc   = this.tarifHP_acc_display  = conso.tarif_acc.HP;
          if (conso.tarif_acc.HC )      this.tarifHC_acc   = this.tarifHC_acc_display  = conso.tarif_acc.HC;
          if (conso.tarif_acc.POINTE )  this.tarifP_acc    = this.tarifP_acc_display   = conso.tarif_acc.POINTE;
          if (conso.tarif_acc.HPH )     this.tarifHPH_acc  = this.tarifHPH_acc_display  = conso.tarif_acc.HPH;
          if (conso.tarif_acc.HCH )     this.tarifHCH_acc  = this.tarifHCH_acc_display = conso.tarif_acc.HCH;
          if (conso.tarif_acc.HPB )     this.tarifHPB_acc  = this.tarifHPB_acc_display = conso.tarif_acc.HPB;
          if (conso.tarif_acc.HCB )     this.tarifHCB_acc  = this.tarifHCB_acc_display = conso.tarif_acc.HCB;
        }
      })
    
    if (this.id_compteur > 0) {
      this.ps.getCompteur(this.id_compteur).then(data => {
        this.compteur = data;
        this.libelleTarif = data?.plage_tarifaire[0].libelle;
        if (this.compteur?.tarif_fourniture.BASE) this.tarifBASE_four= this.compteur?.tarif_fourniture.BASE;
        if (this.compteur?.tarif_fourniture.HP)   this.tarifHP_four  = this.compteur?.tarif_fourniture.HP;
        if (this.compteur?.tarif_fourniture.HC)   this.tarifHC_four  = this.compteur?.tarif_fourniture.HC;
        if (this.compteur?.tarif_fourniture.POINTE)this.tarifP_four  = this.compteur?.tarif_fourniture.POINTE;
        if (this.compteur?.tarif_fourniture.HCB)  this.tarifHCB_four = this.compteur?.tarif_fourniture.HCB;
        if (this.compteur?.tarif_fourniture.HCH)  this.tarifHCH_four = this.compteur?.tarif_fourniture.HCH;
        if (this.compteur?.tarif_fourniture.HPB)  this.tarifHPB_four = this.compteur?.tarif_fourniture.HPB;
        if (this.compteur?.tarif_fourniture.HPH)  this.tarifHPH_four = this.compteur?.tarif_fourniture.HPH;
        // this.majInterface();  
      });
    }
  }
  
  getValue() {
    // console.log("getValue");
    if (this.tarifBASE) this.tarifBASE_acc   = this.tarifBASE.nativeElement.value;
    if (this.tarifHP)   this.tarifHP_acc   = this.tarifHP.nativeElement.value;
    if (this.tarifHC)   this.tarifHC_acc   = this.tarifHC.nativeElement.value;
    if (this.tarifP)    this.tarifP_acc   = this.tarifP.nativeElement.value;
    if (this.tarifHPH)  this.tarifHPH_acc = this.tarifHPH.nativeElement.value;
    if (this.tarifHCH)  this.tarifHCH_acc = this.tarifHCH.nativeElement.value;
    if (this.tarifHPB)  this.tarifHPB_acc = this.tarifHPB.nativeElement.value;
    if (this.tarifHCB)  this.tarifHCB_acc = this.tarifHCB.nativeElement.value;
  }
  majInterface(){
    if (this.mode_valorisation == "don")
      this.reduction_acc.value = 100;
    
    if(this.mode_valorisation == "prix"){
      if (this.tarifBASE)   this.tarifBASE.nativeElement.value = parseFloat(this.tarifBASE.nativeElement.value).toFixed(3);
      if (this.tarifBASE)   this.tarifBASE.nativeElement.value = parseFloat(this.tarifBASE.nativeElement.value).toFixed(2);
      if (this.tarifHP)   this.tarifHP.nativeElement.value = parseFloat(this.tarifHP.nativeElement.value).toFixed(3);
      if (this.tarifHP)   this.tarifHP.nativeElement.value = parseFloat(this.tarifHP.nativeElement.value).toFixed(2);
      if (this.tarifHC)   this.tarifHC.nativeElement.value = parseFloat(this.tarifHC.nativeElement.value).toFixed(3);
      if (this.tarifHC)   this.tarifHC.nativeElement.value = parseFloat(this.tarifHC.nativeElement.value).toFixed(2);
      if (this.tarifP)   this.tarifP.nativeElement.value = parseFloat(this.tarifP.nativeElement.value).toFixed(3);
      if (this.tarifP)   this.tarifP.nativeElement.value = parseFloat(this.tarifP.nativeElement.value).toFixed(2);
      if (this.tarifHPH) this.tarifHPH.nativeElement.value = parseFloat(this.tarifHPH.nativeElement.value).toFixed(3);
      if (this.tarifHPH) this.tarifHPH.nativeElement.value = parseFloat(this.tarifHPH.nativeElement.value).toFixed(2);
      if (this.tarifHCH) this.tarifHCH.nativeElement.value = parseFloat(this.tarifHCH.nativeElement.value).toFixed(3);
      if (this.tarifHCH) this.tarifHCH.nativeElement.value = parseFloat(this.tarifHCH.nativeElement.value).toFixed(2);
      if (this.tarifHPB) this.tarifHPB.nativeElement.value = parseFloat(this.tarifHPB.nativeElement.value).toFixed(3);
      if (this.tarifHPB) this.tarifHPB.nativeElement.value = parseFloat(this.tarifHPB.nativeElement.value).toFixed(2);
      if (this.tarifHCB) this.tarifHCB.nativeElement.value = parseFloat(this.tarifHCB.nativeElement.value).toFixed(3);
      if (this.tarifHCB) this.tarifHCB.nativeElement.value = parseFloat(this.tarifHCB.nativeElement.value).toFixed(2);
    }else{
      if(this.tarifBASE)this.tarifBASE.nativeElement.value  = (this.tarifBASE_four - ((this.tarifBASE_four * <number>this.reduction_acc.value) / 100)).toFixed(2); 
      else this.tarifBASE_acc_display = parseFloat((this.tarifBASE_four - ((this.tarifBASE_four * <number>this.reduction_acc.value) / 100)).toFixed(2));
      
      if(this.tarifHP)  this.tarifHP.nativeElement.value    = (this.tarifHP_four - ((this.tarifHP_four * <number>this.reduction_acc.value)/100)).toFixed(2);
      else this.tarifHP_acc_display = parseFloat((this.tarifHP_four - ((this.tarifHP_four * <number>this.reduction_acc.value) / 100)).toFixed(2));

      if(this.tarifHC)  this.tarifHC.nativeElement.value    = (this.tarifHC_four - ((this.tarifHC_four * <number>this.reduction_acc.value)/100)).toFixed(2);
      else  this.tarifHC_acc_display    = parseFloat((this.tarifHC_four - ((this.tarifHC_four * <number>this.reduction_acc.value)/100)).toFixed(2));

      if(this.tarifP)   this.tarifP.nativeElement.value     = (this.tarifP_four - ((this.tarifP_four * <number>this.reduction_acc.value)/100)).toFixed(2);
      else this.tarifP_acc_display     = parseFloat((this.tarifP_four - ((this.tarifP_four * <number>this.reduction_acc.value)/100)).toFixed(2));

      if(this.tarifHPH) this.tarifHPH.nativeElement.value   = (this.tarifHPH_four - ((this.tarifHPH_four * <number>this.reduction_acc.value)/100)).toFixed(2);
      else this.tarifHPH_acc_display   = parseFloat((this.tarifHPH_four - ((this.tarifHPH_four * <number>this.reduction_acc.value)/100)).toFixed(2));

      if(this.tarifHCH) this.tarifHCH.nativeElement.value   = (this.tarifHCH_four - ((this.tarifHCH_four * <number>this.reduction_acc.value)/100)).toFixed(2);
      else this.tarifHCH_acc_display   = parseFloat((this.tarifHCH_four - ((this.tarifHCH_four * <number>this.reduction_acc.value)/100)).toFixed(2));
      
      if(this.tarifHPB) this.tarifHPB.nativeElement.value   = (this.tarifHPB_four - ((this.tarifHPB_four * <number>this.reduction_acc.value)/100)).toFixed(2);
      else this.tarifHPB_acc_display   = parseFloat((this.tarifHPB_four - ((this.tarifHPB_four * <number>this.reduction_acc.value)/100)).toFixed(2));

      if(this.tarifHCB) this.tarifHCB.nativeElement.value   = (this.tarifHCB_four - ((this.tarifHCB_four * <number>this.reduction_acc.value)/100)).toFixed(2);
      else this.tarifHCB_acc_display   = parseFloat((this.tarifHCB_four - ((this.tarifHCB_four * <number>this.reduction_acc.value)/100)).toFixed(2));
    }
  }

  async enregistrerVariableFinancieres(){
    let tabTarifs : any;
    
    if (this.libelleTarif == "BASE") 
      tabTarifs = {BASE : this.tarifBASE.nativeElement.value};
    else if (this.libelleTarif === 'HP/HC') {
      tabTarifs = {HP : this.tarifHP.nativeElement.value, HC : this.tarifHC.nativeElement.value};
    }else if (this.libelleTarif === 'P/HCH/HPH/HCB/HPB'){
      tabTarifs = {HPH : this.tarifHPH.nativeElement.value,
                  HCH : this.tarifHCH.nativeElement.value,
                  HPB : this.tarifHPB.nativeElement.value,
                  HCB : this.tarifHCB.nativeElement.value}
      if (this.compteur?.tarif_fourniture.POINTE) 
        tabTarifs.POINTE = this.tarifP.nativeElement.value;
    }

    const data = {
      reduction_acc: parseFloat(<string>this.reduction_acc.value),
      // conso_globale_annuelle: this.conso_globale_annuelle.value,
      tarification_acc: this.mode_valorisation, // ----------------à supprimer 
      valorisation_acc: this.mode_valorisation,
      taxes : {
        accise_acc : this.accise_acc.checked,
        hausse_annuelle_elec : this.hausse_annuelle_elec.value
      },
      tarif_acc : tabTarifs
    };
    // console.log('enregistrerVariableFinancieres -> data', data);

    this.ps.majDataScenarioConsommateur(this.idx_scenario, this.id_consommateur, data).then(res => {
      //this.taux_actualisation.value = res?.producteurs[0].financement.taux_actualisation;
      if (res)
        res.consommateurs.forEach( conso => {
          if(conso.idConsommateur == this.id_consommateur)
            console.log("enregistrerVariableFinancieres", res);
        });
    });
  }
}

/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, Input, OnInit, AfterViewChecked, effect } from '@angular/core';
import { EChartsOption, getInstanceByDom } from 'echarts';
import { SankeyNodeItemOption } from './echart-modified';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { ProjetPage } from 'src/app/Pages/projet/projet.page';
import { ProjetService } from 'src/app/services/projet.service';
import { timer } from 'rxjs';
import { RepartitionParticipantProd } from 'src/app/services/EntiteProjet';

interface Flux {
  id: number | string;
  nom: string;
  prod: boolean;
  conso: boolean;
  listeDestinataires: Array<{
    id: number,
    energie: number
  }>;
  totalConsommation: number;
  energieAllouee: number;
}

@Component({
  selector: 'app-bilan-global-flux-graph-component',
  templateUrl: './bilan-global-flux-graph-component.component.html',
  styleUrls: ['./bilan-global-flux-graph-component.component.scss'],
  standalone: true,
  imports: [NgxEchartsDirective],
  providers: [ provideEcharts() ]
})
export class BilanGlobalFluxGraphComponentComponent  implements OnInit, AfterViewChecked {
  @Input() id_scenario!: number;
  
  echartFluxOption: EChartsOption = {
    tooltip: {
      trigger: 'item', 
      formatter: (params: any) => {
        if (params.dataType === 'edge') { 
          const source = params.data.source;
          const target = params.data.target;
          const value = this.ps.formatteNombre(params.data.value, 0);
          return `${source} → ${target}: ${value} kWh`;
        }
        else if (params.dataType === 'node') {
          const value = this.ps.formatteNombre(params.value, 0);
          return `${params.name}: ${value} kWh`;
        }
        return ''; 
      }
    },
    series: {
      type: 'sankey',
      layoutIterations: 0,
      emphasis: {
        focus: 'adjacency'
      },
      data: [],
      links: [ ]
    }
  };
  
  ObjInfoSankey: Array<Flux> = [];

  echartDOM: HTMLElement = document.getElementById('echart-flux-container') as HTMLElement;
  echartInstance: any = null;

  colorNodeSoutirage = '#ff0000';
  colorNodeSurplus = '#92d050';
  colorNodeProducteur = '#3333ff';
  colorNodeConsomateur = '#fd9800'; //jaune foncé

  colorFluxFromSoutirage = '#ff0000';
  colorFluxFromProdToConso = '#991A80'; // violet
  colorFluxFromProdToSurplus = '#92d050';
  colorFluxACI = '#3333ff'; // bleu foncé

  constructor(private _parent: ProjetPage, private ps: ProjetService) {
    effect(() => {
      if (this.ps.bilanEnergieModifie()) {
        this.ObjInfoSankey = [];
        this.echartFluxOption!.series = {};
        this.grapheInitatlization();
        if(this.echartInstance != null){
          this.echartInstance.setOption(this.echartFluxOption);
        }
        timer(500).subscribe(() => { 
          this.legendGeneration();
          this.ps.bilanEnergieModifie.set(false);
        });
      }
    });
   }

  ngOnInit() {
    this.grapheInitatlization();
  }

  ngAfterViewChecked(): void {
    this.legendGeneration();
  }


  onChartInit(ec: any) { // Méthode appelée à l'initialisation du graphique
    this.echartInstance = ec;
    //console.log('onChartInit()', ec.id);
  }

  onChartClick(ev: any) { // Méthode appelée lors d'un clic sur le graphique
    //console.log('onChartClick', ev);
  }

  private legendGeneration() {
    document.querySelectorAll('.legend-item').forEach(item => {
      (item as HTMLElement).style.display = 'inline-block';
    });
  }

  private calculateDynamicMargin(maxNameLength: number) {
    let leftMargin = '10%';
    let rightMargin = '10%';
  
    if (maxNameLength > 10 && maxNameLength <= 20) {
      leftMargin = '13%';
      rightMargin = '14%';
    } else if (maxNameLength > 20 && maxNameLength <= 30) {
      leftMargin = '18%';
      rightMargin = '18%';
    } else if (maxNameLength > 30) {
      leftMargin = '28%';
      rightMargin = '29%';
    }
  
    return { left: leftMargin, right: rightMargin };
  }

  private filterData(finalData: SankeyNodeItemOption[]): SankeyNodeItemOption[] {
    let nomVue = new Set<string>();
    console.log('debugger', finalData);
    let data = finalData.filter((element) => {
      if(nomVue.has(element.name as string)){
        return false;
      }else{
        nomVue.add(element.name as string);
        return true;
      }
    });
    const surplusIndex = data.findIndex(item => item.name === 'Surplus global');
    const soutirageIndex = data.findIndex(item => item.name === 'Soutirage global');
    if (surplusIndex !== -1) {
      const surplusObject = data.splice(surplusIndex, 1)[0];
      let newData = [data[soutirageIndex]];
      data.push(surplusObject);
      newData = newData.concat(data);
    }
    return data;
  }

  private checkDestinataire(id: number): boolean {
    let toCheck = this.ObjInfoSankey.find(participant => participant.id == id);
    if(toCheck != undefined){
      if(toCheck.prod && toCheck.conso){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    };
  };

  private listeDestinatairesProduction(listeParticipants:RepartitionParticipantProd[]){
    let destinataires: any = [];
    listeParticipants.forEach(participant => {
      if(participant.energie > 0){
        let object = {
          id: participant.id_consommateur,
          energie: participant.energie
        };
        destinataires.push(object);
      }
    });
    return destinataires;
  }

  private listeDestinatairesSoutirage(listeParticipants:Flux[]){
    let destinataires: Array<{
      id: number,
      energie: number
    }> | null = [];
    listeParticipants.forEach(participant => {
      if(typeof participant.id == 'number' && participant.totalConsommation - participant.energieAllouee > 0){
        let object = {
          id: participant.id,
          energie: participant.totalConsommation - participant.energieAllouee
        };
        destinataires.push(object);
      }
    });
    return destinataires;
  }

  private grapheInitatlization() {
    let data: SankeyNodeItemOption[] = [];
    let links: any[] = [];
    const projet = this._parent.projetCourant;
    const participants = projet?.participants;
    const scenarioCourant = projet?.scenarios[this.id_scenario];
    let id_nom_participant = new Map<number | string, string>();
    participants?.forEach(participant => {
      id_nom_participant.set(participant.id, participant.nom);
    });
    id_nom_participant.set('A', 'Soutirage global');
    id_nom_participant.set('B', 'Surplus global');
    scenarioCourant?.bilanEnergetique?.indicateurs.participants.forEach(participant => {
      let member = {
        id: participant.id,
        nom: id_nom_participant.get(participant.id) || '',
        prod: (participant.total_prod > 0) ? true : false,
        conso: (participant.total_conso > 0) ? true : false,
        listeDestinataires: this.listeDestinatairesProduction(participant.repartition_prod),
        totalConsommation: participant.total_conso,
        energieAllouee: participant.energie_alloue
      }
      if(participant.surplus_acc > 0){
        member.listeDestinataires.push({ id: 'B', energie: participant.surplus_acc });
      }
      this.ObjInfoSankey.push(member);
    });
    let surplusGlobal = {
      id: "B",
      nom: 'Surplus global',
      prod: false,
      conso: true,
      listeDestinataires: [],
      totalConsommation: 0,
      energieAllouee: 0
    };

    this.ObjInfoSankey.push(surplusGlobal);

    let soutirageGlobal: Flux = {
      id: "A",
      nom: 'Soutirage global',
      prod: true,
      conso: false,
      listeDestinataires: this.listeDestinatairesSoutirage(this.ObjInfoSankey),
      totalConsommation: 0,
      energieAllouee: 0
    };
    if(soutirageGlobal.listeDestinataires.length > 0){
      this.ObjInfoSankey.push(soutirageGlobal);
    }

    console.log('ObjInfoSankey', this.ObjInfoSankey);
    this.ObjInfoSankey.forEach(participant => {      
      if(participant.prod && participant.conso){
        data.push({ name: participant.nom + " (prod)", itemStyle: { color: this.colorNodeProducteur }, label: { position: 'left' } });
        data.push({ name: participant.nom + " (conso)", itemStyle: { color: this.colorNodeConsomateur }, label: { position: 'right' } });
      }else if(participant.prod){
        data.push({ name: participant.nom, itemStyle: { color: (participant.id == 'A') ? this.colorNodeSoutirage : this.colorNodeProducteur }, label: { position: 'left' } });
      }else if(participant.conso){
        data.push({ name: participant.nom, itemStyle: { color: (participant.id == 'B') ? this.colorNodeSurplus : this.colorNodeConsomateur }, label: { position: 'right' } });
      }
      participant.listeDestinataires.forEach(destinataire => {
        if(participant.prod && participant.conso){
          let color = this.colorFluxFromProdToConso;
          if(id_nom_participant.get(destinataire.id) == "Surplus global"){
            color = this.colorFluxFromProdToSurplus;
          }else if(destinataire.id == participant.id){
            color = this.colorFluxACI;
          }
          links.push({ source: participant.nom + " (prod)", target: id_nom_participant.get(destinataire.id) + ((this.checkDestinataire(destinataire.id)) ? " (conso)": ""), value: destinataire.energie, lineStyle: { color: color } });
        }else{
          let color = this.colorFluxFromProdToConso;
          if(id_nom_participant.get(destinataire.id) == "Surplus global"){
            color = this.colorFluxFromProdToSurplus;
          }else if(id_nom_participant.get(participant.id) == "Soutirage global"){
            color = this.colorFluxFromSoutirage;
          }
          links.push({ source: participant.nom, target: id_nom_participant.get(destinataire.id) + ((this.checkDestinataire(destinataire.id)) ? " (conso)": ""), value: destinataire.energie, lineStyle: { color: color } });
        }
      });
    });

    data = this.filterData(data);

    const maxNodeNameLength = data.reduce((maxLength, node) => {
      return Math.max(maxLength, node.name?.toString().length || 0);
    }, 0);
    const dynamicMargin = this.calculateDynamicMargin(maxNodeNameLength);

    this.echartFluxOption.series = {
      type: 'sankey',
      layoutIterations: 0,
      emphasis: {
        focus: 'adjacency'
      },
      data: data,
      links: links,
      left: dynamicMargin.left,
      right: dynamicMargin.right,  
      nodeWidth: 20,  
      nodeGap: 10
    };
  };

  
};
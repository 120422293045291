<ion-content [fullscreen]="true">
@if (idProjet > 0) {
  <tpv-stepper #stepper mode="lateral" (selectionChange)="changeEtape($event)">
    <!-- Variables du Projet -->
    <cdk-step #step1_1 label="Variables générales" [state]="getStepProjetState('variables')">
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-input type="text" label="Nom du Projet" name="libelle" placeholder="Le nom du projet ..." 
              fill="outline" labelPlacement="stacked" error-text="Vous devez saisir le nom du projet !"
              value="{{projetCourant?.libelle}}" (ionChange)="majProjet($event)" ngModel required>
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-select label="Type de projet" name="type" interface="popover" 
              fill="outline" labelPlacement="stacked" error-text="Vous devez choisir le type d'installation !"
              value="{{projetCourant?.type}}" (ionChange)="majProjet($event)">
              <ion-select-option *ngFor="let o of listeTypesProjet; index as i" value="{{o}}">{{ getLibelleType(i) }}</ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input class="{{ classErreurNbConsommateurs }}" type="number" label="Nombre de consommateurs" name="nbConsommateurs" placeholder="Nombre de consommateurs ..." 
              inputmode="numeric" fill="outline" labelPlacement="stacked" error-text="Vous devez saisir le nombre de consommateurs ou le nombre de consommateurs est en incohérence avec le type de projet."
              [min]="1" [max]="100"
              value="{{projetCourant?.nbConsommateurs}}" (ionChange)="majProjet($event)" ngModel required>
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input type="text" labelPlacement="stacked" name="URL_sharepoint" label="Lien Sharepoint" placeholder="Lien vers le projet Sharepoint" 
            fill="outline" (ionChange)="majProjet($event)" value="{{lien_sharepoint}}" ngModel>
            <ion-button fill="clear" slot="start" aria-label="Show/hide" href="{{lien_sharepoint}}" target="_blank">
                <ion-icon slot="icon-only" name="open-outline" aria-hidden="true"></ion-icon>
              </ion-button>
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input type="text" labelPlacement="stacked" name="URL_odoo" label="Lien Odoo" placeholder="Lien vers le projet Odoo" 
            fill="outline" (ionChange)="majProjet($event)" value="{{lien_odoo}}" ngModel>
              <ion-button fill="clear" slot="start" aria-label="Show/hide" href="{{lien_odoo}}" target="_blank">
                <ion-icon slot="icon-only" name="open-outline" aria-hidden="true"></ion-icon>
              </ion-button>
            </ion-input>
          </ion-col>
        </ion-row>
      </ion-grid>
    </cdk-step>
    <!-- participants [anciennement Fichiers] -->
    <cdk-step #step1_2 label="Participants" [state]="getStepProjetState('participants')">
          <ion-row style="margin-left: 20px;">
            <ion-button size="small" class="ion-float-left" (click)="dialogueImportProjet()"><ion-icon name="cloud-upload-outline"></ion-icon>&nbsp;&nbsp;Importer participants depuis TPV1</ion-button>
          </ion-row>
      <tpv-stepper #stepper_participants mode="menubar" (selectionChange)="changeEtapeScenarioParticipants($event)">

        <cdk-step #step2_1 label="Consommateurs">  <!-- fichiers de CONSO -->
<!--        <ion-item slot="header" class="types-fichiers" color="tertiary">
            <ion-title>Consommation</ion-title>
          </ion-item>   -->
          <div class="ion-padding" slot="content">
            <ion-card *ngFor="let doc of documentsConso()" color="light">
              <ion-card-header>
                <ion-card-title>
                  <ion-icon name="checkbox-outline" color="success"></ion-icon> &nbsp; {{ doc.nom}}
                  <ion-icon name="trash-outline" color="danger" class="ion-float-right" (click)="supprimeDocument(doc.id )"></ion-icon>
                </ion-card-title>
                <ion-card-subtitle>{{ doc.format}}</ion-card-subtitle>
              </ion-card-header>
              <div  *ngFor="let c of courbesDocument(doc.id, 'conso')">
              <ion-card-content>
                <ion-list>
                    <ion-item>
                      <ion-label>Compteur : <strong>{{ c.grandeur }}</strong></ion-label>
                      <span>
                        <ion-icon name="bar-chart" color="primary" class="pc-prod" 
                                  (click)="afficheGrapheCourbe(c.id, false)"></ion-icon>
                        <ion-icon name="download" color="primary" class="pc-prod" 
                                  (click)="uploadCsvData(c.idDispositif, $event)"
                                  *ngIf="!isLoading[c.idDispositif] && !isDownloadComplete[c.idDispositif]"></ion-icon>
                        <ion-spinner name="dots" *ngIf="isLoading[c.idDispositif]"></ion-spinner>
                        <ion-icon name="checkmark-circle-outline" style="width: 24px; height: 24px; margin-top: 4px; margin-left: 16px; cursor: pointer;"
                                  color="success" class="pc-prod" *ngIf="isDownloadComplete[c.idDispositif]"></ion-icon>
                      </span>
                    </ion-item>
                    <ion-grid>
                      <ion-row>
                        <ion-col>
                          <ion-input type="text" name="puissance" inputmode="numeric" labelPlacement="floating" color="primary"
                            [class]="verifiePuissanceSouscrite(c.idDispositif)"  
                            label="Puissance souscrite (kVA) :" placeholder="La Puissance produite souscrite(en kVA) ..." 
                            error-text="la puissance souscrite pour le compteur doit valoir entre 3 et 999999998 kVA"
                            value="{{ getPuissanceFromId(c.idDispositif) }}" (ionChange)="majCompteur($event, c.idDispositif)" disabled>
                          </ion-input>
                        </ion-col>
                        @let compteur = mapCompteurs.get(c.idDispositif);
                        <ion-col>
                          <ion-select label="Catégorie TURPE" name="categorie_turpe" interface="popover" labelPlacement="floating" fill="outline" 
                            [class]="verifieCategorieTurpe(c.idDispositif)" [value]=compteur?.config_turpe?.categorie_puissance 
                            (ionChange)="majCompteur($event, c.idDispositif)">
                            <ion-select-option *ngFor="let cat of conf.CatTurpe6()" [value]="cat">{{ cat }}</ion-select-option>
                          </ion-select>
                        </ion-col>
                        <ion-col>
                          <ion-select label="Sous-catégorie TURPE" name="sous_categorie_turpe" interface="popover" labelPlacement="floating" fill="outline" 
                            [class]="verifieSouscategorieTurpe(c.idDispositif)" [value]=compteur?.config_turpe?.sous_categorie_puissance 
                            (ionChange)="majCompteur($event, c.idDispositif)">
                            <ion-select-option *ngFor="let scat of conf.sousCatTurpe6(compteur?.config_turpe?.categorie_puissance)" [value]="scat">{{ scat }}</ion-select-option>
                          </ion-select>
                        </ion-col>
                        <ion-col>
                          <ion-select label="Mode de calcul du TURPE" name="repartition_turpe" interface="popover" labelPlacement="floating" fill="outline" value="TURPE STANDARD" (ionChange)="majCompteur($event, c.idDispositif)">
                            <ion-select-option  value="TURPE STANDARD">TURPE standard</ion-select-option>
                            <ion-select-option  value="TURPE AUTO/ALLO" [attr.disabled]="true">TURPE auto/allo</ion-select-option>
                          </ion-select>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col>
                          <ion-input type="number" name="accise" label="Accise (c€/kWh)" [value]=compteur?.accise (ionChange)="majCompteur($event, c.idDispositif)" class="input-scenario-energie" inputmode="numeric" labelPlacement="floating" color="primary"></ion-input>
                        </ion-col>
                        <ion-col>
                          <ion-input type="number" label="CTA (en %)" name="CTA" placeholder="21,93% (par défaut)" [value]=compteur?.CTA (ionChange)="majCompteur($event, c.idDispositif)" class="input-scenario-energie" inputmode="numeric" labelPlacement="floating" color="primary"></ion-input>
                        </ion-col>
                        <ion-col>
                          <ion-input type="number" label="TVA (en %)" name="TVA" (ionChange)="majCompteur($event, c.idDispositif)" [value]=compteur?.TVA class="input-scenario-energie" inputmode="numeric" labelPlacement="floating" color="primary"></ion-input>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                    <app-options-tarifaires id_compteur="{{c.idDispositif}}"></app-options-tarifaires>
                    <ion-item *ngFor="let c of ConsommateursLiesCourbe(c.id)" lines="none">
                      <ion-input type="text" name="nom" fill="outline" label="Nom du consommateur :" placeholder="Le nom du consommateur ..."
                        error-text="Vous devez saisir le nom du consommateur !" slot="start"
                        value="{{ mapParticipants.get(c.id)?.nom }}" (ionChange)="majParticipant($event, c.id)">
                      </ion-input>
                    </ion-item>
                </ion-list>
              </ion-card-content>
                  </div>
            </ion-card>
            <ion-card>
              <ion-card-header>Télécharger un nouveau fichier<div class="right">(formats de fichiers acceptés : .csv | .zip | .xlsx)</div></ion-card-header>
              <ion-card-content>
                <app-upload-file type_fichier="Consommation" formats_acceptes=".zip, .csv, .xlsx" [id_projet]="idProjet"></app-upload-file>
              </ion-card-content>
            </ion-card>
          </div>
        </cdk-step>
        
        <cdk-step #step2_2 label="Producteurs">   <!-- fichiers de PROD -->
          <!--        <ion-item slot="header" class="types-fichiers" color="secondary">
            <ion-title>Production</ion-title>
          </ion-item>   -->
          <div class="ion-padding" slot="content">
            <ion-card *ngFor="let doc of documentsProd()">
              <ion-card-header color="light">
                <ion-card-title>
                  <ion-icon name="checkbox-outline" color="success"></ion-icon> &nbsp; {{ doc.nom}}
                  <ion-icon name="trash-outline" color="danger" class="ion-float-right cursor-pointer" (click)="supprimeDocument(doc.id )"></ion-icon>
                </ion-card-title>
                <ion-card-subtitle>{{ doc.format }}</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                @for(gen of generateursAttachesDocument(doc.id); track gen) {
                <ion-list>
                  @let prod = getProducteurParGenerateur(gen.id);
                  <ion-item lines="none">
                    <ion-input type="text" name="nom" fill="outline" label="Nom du producteur :" placeholder="Le nom du producteur ..."
                      error-text="Vous devez saisir le nom du producteur !" class="ion-float-left"
                      value="{{ prod?.nom }}" (ionChange)="majParticipant($event, prod?.id || -1)">
                    </ion-input>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-input type="text" name="puissance" fill="outline" label="Puissance Crête (kWc) :" placeholder="La PC (en kWc) ..." 
                        inputmode="numeric" error-text="Vous devez saisir la puissance-crête du générateur !" class="pc-prod"
                        value="{{ gen?.puissance }}" (ionChange)="majGenerateur($event, gen.id)">
                    </ion-input>
                    <span>
                      <ion-icon name="bar-chart" color="primary" class="pc-prod" 
                                (click)="afficheGrapheCourbe(doc.listeCourbes[0], true)"></ion-icon>
                      <ion-icon name="download" color="primary" class="pc-prod" 
                                (click)="uploadCsvData(dispositifAttacheDocument(doc.id)!.id, $event)"
                                *ngIf="!isLoading[dispositifAttacheDocument(doc.id)!.id] && !isDownloadComplete[dispositifAttacheDocument(doc.id)!.id]"></ion-icon>
                      <ion-spinner name="dots" *ngIf="isLoading[dispositifAttacheDocument(doc.id)!.id]"></ion-spinner>
                      <ion-icon name="checkmark-circle-outline" style="width: 24px; height: 24px; margin-top: 4px; margin-left: 16px; cursor: pointer;"
                                color="success" class="pc-prod" *ngIf="isDownloadComplete[dispositifAttacheDocument(doc.id)!.id]"></ion-icon>
                    </span>
                  </ion-item>
                  @if ( ($count - $index) > 1) {
                  <ion-item-divider></ion-item-divider>
                  }
                </ion-list>
              }
              </ion-card-content>
            </ion-card>
            <ion-card>
              <ion-card-header>Télécharger un nouveau fichier<div class="right">(formats de fichiers acceptés : .csv | .xlsx)</div></ion-card-header>
              <ion-card-content>
                <app-upload-file type_fichier="Production" formats_acceptes=".csv, .xlsx" [id_projet]="idProjet"></app-upload-file>
              </ion-card-content>
            </ion-card>
          </div>
        </cdk-step>
        <cdk-step #step2_3 label="Synthèse">
          <app-vflow-gestion-participants></app-vflow-gestion-participants>
        </cdk-step>
      </tpv-stepper>
    </cdk-step>
    <!-- Scénarios Energie -->
    <cdk-step #step1_3 label="Scénarios énergie" [state]="getStepProjetState('energie')">
      <ion-fab horizontal="end" vertical="bottom" slot="fixed">
        <ion-fab-button (click)="dialogNewScenario(true)" matTooltip="Ajouter nouveau scénario">
          <ion-icon name="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-modal [isOpen]="newScenarioModalControl">
        <ng-template>
          <ion-header>
            <ion-toolbar> 
              <ion-title>Ajouter un nouveau scénario</ion-title>
              <ion-buttons slot="end">
                <ion-button (click)="dialogNewScenario(false)">Fermer</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content class="ion-padding">
            <app-dialog-new-scenario [id_projet]="idProjet" [status_accordion]="accordionEnergieStatus" (scenarioCreated)="onScenarioCreated()"></app-dialog-new-scenario>
          </ion-content>
        </ng-template>
      </ion-modal>
      <ion-accordion-group expand="inset" multiple="true" value="{{accordionEnergieStatus}}" (ionChange)="memoriseScenarioCourant($event)"  (keydown)="evitePropagEvent($event, 'accordeon-group-down')">
        <ion-accordion *ngFor="let sc of projetCourant?.scenarios; index as i; trackBy: itemTrackBy" value="{{i}}" (keydown)="evitePropagEvent($event, 'accordeon-down')">
          <ion-item slot="header" color="light">
            <ion-input type="text" name="libelle" fill="outline" aria-label="Libellé du scénario :" placeholder="Le libellé du scénario ..." 
              error-text="Vous devez saisir le libellé du scénario !" class="pc-prod-souhaitee"
              (click)="evitePropagEvent($event, 'input-click')" (keypress)="evitePropagEvent($event,'input-press')" (keyup)="evitePropagEvent($event,'input-up')" (keydown)="evitePropagEvent($event,'input-down')"
              value="{{ sc.libelle }}" (ionChange)="majScenario($event, sc.id)">
            </ion-input>
            <div *ngIf="countScenarios(projetCourant?.scenarios) > 1">
              <ion-icon name="trash-outline" color="danger" size="large" class="ion-float-right cursor-pointer" matTooltip="Supprimer Scenario" (click)="deleteScenario(sc.id)"></ion-icon>
            </div>
          </ion-item>
          <div class="ion-padding" slot="content">
            <tpv-stepper #stepper_energie mode="menubar" (selectionChange)="changeEtapeScenarioEnergie($event)">
              <cdk-step #step3_1 label="Producteurs"> <!-- liste des Producteurs -->
                
              <div>
                <div class="ion-float-left" style="width: 80%;">
                  <ion-select label="Mode de répartition de la production" aria-label="Sélectionnez un mode ..." name="repartitionProduction" 
                    fill="outline" label-placement="stacked" [value]="sc.repartitionProduction" (ionChange)="majScenario($event, sc.id)">
                    <ion-select-option *ngFor="let mode of listeModeRepartitionProd; index as i"  value="{{mode[0]}}" >{{mode[1]}}</ion-select-option>
                  </ion-select>
                </div>
                <div class="ion-float-right" *ngIf="showToggleOrderingProd">
                  <ion-button [color]="(disableOrdering ? 'light' : 'primary')" (click)="toggleOrdering()"><img src="/assets/reordonner.png" height="32"/></ion-button>
                </div>
              </div>
              <div style="clear: left;padding-bottom:30px;"></div>
                <app-scenario-producteurs [idx_scenario]=i></app-scenario-producteurs>
              </cdk-step>
              <cdk-step #step3_2 label="Consommateurs">  <!-- liste des Consommateurs -->
                
              <div>
                <div class="ion-float-left" style="width: 80%;">
                  <ion-select label="Mode de répartition de la consommation" aria-label="Sélectionnez un mode ..." name="repartitionConsommation" 
                    fill="outline" label-placement="stacked" [value]="sc.repartitionConsommation" (ionChange)="majScenario($event, sc.id)">
                    <!-- <ion-select-option *ngFor="let mode of listeModeRepartitionConso; index as i"  value="{{mode[0]}}" >{{mode[1]}}</ion-select-option> -->
                    <ion-select-option value="PRORATA" >Prorata</ion-select-option>
                    <ion-select-option value="PRIRITE" disabled>Priorité</ion-select-option>
                  </ion-select>
                </div>
                <div class="ion-float-right" *ngIf="sc.repartitionConsommation === 'PRIORITE'">
                  <ion-button [color]="(disableOrdering ? 'light' : 'primary')" (click)="toggleOrdering()"><img src="/assets/reordonner.png" height="32"/></ion-button>
                </div>
              </div>
              <div style="clear: left;padding-bottom:30px;"></div>

                <ion-list lines="none" class="dispositf-scenario">
                  <ion-item *ngFor="let c of filtreConsommateurs(sc.id)">
                    @let participant = mapParticipants.get(c.id);
                    @if (participant && participant.consommateur.compteurs.length > 0) {
                    <ion-list class="full-width">
                      <ion-item class="full-width"><ion-title color="tertiary">&gt; {{ participant.nom }}</ion-title></ion-item>
                      <ion-item class="full-width">
                        <ion-card *ngFor="let cpt of c.consommateur.compteurs" class="full-width">
                          <ion-card-header>
                            <ion-card-title>
                              <div class="ion-float-left" style="padding-top: 10px;">
                                {{ reductionTexte(mapDispositifs.get(cpt.id)?.document, 30) }} <strong>(PRM : {{mapDispositifs.get(cpt.id)?.code }})</strong>
                              </div>
                              <div class="ion-float-right">
                                <ion-toggle enable-on-off-labels="true" [checked]="compteurScenarioActif(sc.id, cpt.id)" class="ion-float-right" (ionChange)="activationDispositifScenario($event, sc.id, cpt.id)">Actif</ion-toggle>
                              </div>
                            </ion-card-title>
                          </ion-card-header>
                        </ion-card>
                      </ion-item>
                    </ion-list>
                    }
                  </ion-item>
                </ion-list>
              </cdk-step>
            </tpv-stepper>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </cdk-step>
    <!-- Scénarios Financier -->
    <cdk-step #step1_4 label="Scénarios financier" [state]="getStepProjetState('financier')"> 
      <ion-accordion-group expand="inset" multiple="true" [value]="accordionFinancierStatus" (ionChange)="memoriseScenarioCourant($event)">
        <ion-accordion *ngFor="let sc of projetCourant?.scenarios; index as i" value="{{i}}">
          <ion-item slot="header" color="light">
            <ion-label>{{ sc.libelle }}</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <tpv-stepper #stepper_financier *ngIf="true" mode="menubar" (selectionChange)="changeEtapeScenarioFinancier($event)">
              <cdk-step #step3_1 #step2_1 label="Producteurs">
                @let prodsActifs = filtreProducteursActifs(sc.id);
                @if (prodsActifs.length > 0) {
                  <ion-accordion-group [multiple]="true"  style="width:100%">
                    <ion-accordion *ngFor="let p of prodsActifs">
                      @if (p){
                        @let participant = mapParticipants.get(p.id);
                        @if (participant && participant.producteur.generateurs.length > 0) {
                            <ion-item slot="header" color="light">
                              <ion-col>{{participant.nom }} </ion-col>
                            </ion-item>
                            <div class="ion-padding" slot="content">
                              <app-plan-financier-par-producteur [idx_scenario]=sc.id [id_participant]=p.id [id_producteur]=participant.producteur.id [scenario]=sc > </app-plan-financier-par-producteur>
                            </div>
                        }
                      }
                    </ion-accordion>
                  </ion-accordion-group>
                } @else {
                  <p>Aucun producteur actif</p>
                }
              </cdk-step>
              <cdk-step #step3_3 label="Consommateurs">
                @let consosActifs = filtreConsommateursActifs(sc.id);
                @if (consosActifs.length > 0) {
                  <ion-accordion-group [multiple]="true"  style="width:100%">
                    <ion-accordion *ngFor="let p of consosActifs">
                      @let participant = mapParticipants.get(p.id);
                      @if (participant && participant.consommateur.compteurs.length > 0 ) {
                          <ion-item slot="header" color="light">
                            <ion-col>{{participant.nom }} </ion-col>
                          </ion-item>
                          <div class="ion-padding" slot="content">
                            <app-plan-financier-par-consommateur 
                            [idx_scenario]=sc.id 
                            [id_participant]=p.id 
                            [id_compteur]=participant.consommateur.compteurs[0].id 
                            [id_consommateur]=participant.consommateur.id 
                            [scenario]=sc 
                            [type_projet]=projetCourant?.type 
                            [id_projet]=projetCourant?.id
                            > </app-plan-financier-par-consommateur>
                          </div>
                      }
                    </ion-accordion>
                  </ion-accordion-group>
                } @else {
                  <p>Aucun consommateur actif</p>
                }
              </cdk-step> 
            </tpv-stepper>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </cdk-step>
    <!-- Résultats -->
    <cdk-step #step1_5 label="Bilans" [state]="getStepProjetState('bilan')"> 
      <ion-accordion-group expand="inset" [multiple]="true" [value]="accordionBilanStatus" (ionChange)="memoriseScenarioCourant($event)">
        <ion-accordion toggleIconSlot="start" *ngFor="let sc of projetCourant?.scenarios; index as i" value="{{i}}">
          <ion-item slot="header" color="light">
            <ion-toolbar color="light">
              <ion-label>{{ sc.libelle }} 
                <div class="date-calcul">( Calculé le {{ dateBilan(sc.id) }} )</div><br />
              </ion-label>
              @let ongletBilan = (currentStepBilan[sc.id] == 2) ? "financier" : ((currentStepBilan[sc.id] == 1) ? "facturation" : "energetique") ;
              <ion-button slot="end" size="small" color="light" (click)="calculBilan($event, sc.id)">Lancer le calcul du bilan {{ ongletBilan }}</ion-button>
            </ion-toolbar>
          </ion-item>
          <div class="ion-padding" slot="content">
            <tpv-stepper #stepper_bilan mode="menubar" (selectionChange)="changeEtapeScenarioBilan($event, sc.id)">
              <cdk-step #step4_1 label="Energie"> <!-- Scénario {{i}} => BILAN ENERGETIQUE !!! -->
                @if (sc.bilanEnergetique && sc.bilanEnergetique.indicateurs) {
                <ion-card color="light">
                  <ion-card-header>
                    <ion-card-title>
                      Bilan global
                      <ion-icon name="bar-chart" color="primary"class="ion-float-right" (click)="afficheHeatmap(sc.id)"></ion-icon>
                    </ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-grid>
                      <ion-row>
                        <ion-col size="12" size-xl="6">
                          <ion-label><b>Productivité</b></ion-label>
                          <ion-list >
                            <ion-item>
                              <ion-label>Puissance crête totale</ion-label>
                              <ion-text color="success">{{ indicateurBilanGlobal(i, 'puissance_crete', 0) }} kWc</ion-text>
                            </ion-item>
                            <ion-item>
                              <ion-label>Energie PV produite</ion-label>
                              <ion-text color="success">{{ indicateurBilanGlobal(i, 'total_prod', 0) }} kWh</ion-text>
                            </ion-item>
                            <ion-item>
                              <ion-label>Autoconsommation Totale</ion-label>
                              <ion-text color="success">{{ indicateurBilanGlobal(i, 'total_autoconso', 0) }} kWh</ion-text>
                            </ion-item>
                            <ion-item>
                              <ion-label>Consommation Totale</ion-label>
                              <ion-text color="success">{{ indicateurBilanGlobal(i, 'total_conso', 0) }} kWh</ion-text>
                            </ion-item>
                            <ion-item>
                              <ion-label>Injection Totale</ion-label>
                              <ion-text color="success">{{ indicateurBilanGlobal(i, 'total_injection', 0) }} kWh</ion-text>
                            </ion-item>
                          </ion-list>
                        </ion-col>
                        <ion-col size="12" size-xl="6">
                          <ion-label><b>Taux</b></ion-label>
                          <ion-list>
                            <ion-item>
                              <ion-label>Taux autoconsommation</ion-label>
                              <ion-text color="success">{{ indicateurBilanGlobal(i, 'TAC', 2) }} %</ion-text>
                            </ion-item>
                            <ion-item>
                              <ion-label>Taux auto-production</ion-label>
                              <ion-text color="success">{{ indicateurBilanGlobal(i, 'TAP', 2) }} %</ion-text>
                            </ion-item>
                            <ion-item>
                              <ion-label>Taux excédent</ion-label>
                              <ion-text color="success">{{ indicateurBilanGlobal(i, 'part_excedent', 2) }} %</ion-text>
                            </ion-item>
                            <ion-item class="ion-hide-xl-down">
                              <ion-label>&nbsp;</ion-label>
                              <ion-text color="success">&nbsp;</ion-text>
                            </ion-item>
                            <ion-item class="ion-hide-xl-down">
                              <ion-label>&nbsp;</ion-label>
                              <ion-text color="success">&nbsp;</ion-text>
                            </ion-item>
                          </ion-list>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="12">
                          <app-bilan-global-flux-graph-component [id_scenario]=i></app-bilan-global-flux-graph-component>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-card-content>
                </ion-card>
                <ion-card color="light">
                  <ion-card-header>
                    <ion-card-title>
                      Bilan par consommateur
                    </ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-list> 
                      <ion-grid>
                        <ion-row style="margin-right: 56px;margin-left:16px;">
                          <ion-col><b>Participant</b></ion-col>
                          <ion-col><b>Consommation</b></ion-col>
                          <ion-col><b>Part consommation</b></ion-col>
                          <ion-col><b>Energie ACI</b></ion-col>
                          <ion-col><b>Energie ACC</b></ion-col>
                          <ion-col><b>Energie totale autoconsommée</b></ion-col>
                          <ion-col><b>Part production</b></ion-col>
                          <ion-col><b>Taux auto-production</b></ion-col>
                        </ion-row>
                        <ion-row>
                          <ion-accordion-group [multiple]="true" (ionChange)="accordeonBilanChanged($event)" style="width:100%">
                          <ion-accordion value="{{pa.id}}" *ngFor="let pa of sc.bilanEnergetique?.indicateurs?.participants;">
                          @if(participationPConsommateurScenario(sc.id, pa.id)){                          
                            <ion-item slot="header" color="light">
                              <ion-col>{{ mapParticipants.get(pa.id)?.nom }}</ion-col>
                              <ion-col>{{ indicateurBilanParticipant(i, pa.id, 'total_conso', 0) }} kWh</ion-col>
                              <ion-col>{{ indicateurBilanParticipant(i, pa.id, 'part_conso', 2) }} %</ion-col>
                              <ion-col>{{ indicateurBilanParticipant(i, pa.id, 'energie_alloue_aci', 0) }} kWh</ion-col>
                              <ion-col>{{ indicateurBilanParticipant(i, pa.id, 'energie_alloue_acc', 0) }} kWh</ion-col>
                              <ion-col>{{ indicateurBilanParticipant(i, pa.id, 'energie_alloue', 0) }} kWh</ion-col>
                              <ion-col>{{ indicateurBilanParticipant(i, pa.id, 'part_prod', 2) }} %</ion-col>
                              <ion-col>{{ indicateurBilanParticipant(i, pa.id, 'TAP', 2) }} %</ion-col>
                            </ion-item>
                            <div class="ion-padding" slot="content">
                              <app-graphes-bilan-consommateur [idx_scenario]=i [id_participant]=pa.id></app-graphes-bilan-consommateur>
                            </div>
                          }
                          </ion-accordion>
                          </ion-accordion-group>
                        </ion-row>
                      </ion-grid>
                    </ion-list>
                  </ion-card-content>
                </ion-card>
                <ion-card color="light">
                  <ion-card-header>
                    <ion-card-title>
                      Bilan par producteur
                    </ion-card-title> 
                  </ion-card-header>
                  <ion-card-content>   
                    <ion-list> 
                      <ion-grid>
                        <ion-row style="margin-right: 56px;margin-left:16px;">
                        @if (sc.bilanEnergetique && sc.bilanEnergetique.indicateurs && sc.bilanEnergetique.indicateurs.participants) {
                          <ion-col><b>Participant</b></ion-col>
                          @for (pa of sc.bilanEnergetique.indicateurs.participants; track pa) {
                            @if (pa.total_prod > 0) {
                              <ion-col><b>Producteur : {{mapParticipants.get(pa.id)?.nom}} <br> Energie allouée</b></ion-col>
                              <ion-col><b>Producteur : {{mapParticipants.get(pa.id)?.nom}} <br> Part de la production</b></ion-col>
                            }
                          }
                        }
                        </ion-row>
                        <ion-row *ngFor="let pa of sc.bilanEnergetique?.indicateurs?.participants;">
                          @if (pa.total_conso > 0) {              
                              <ion-col>{{ mapParticipants.get(pa.id)?.nom }}</ion-col> 
                              @for (paProd of sc.bilanEnergetique.indicateurs.participants; track paProd) {
                                @if (paProd.total_prod > 0 && paProd.repartition_prod ) {
                                  @for (repart of paProd.repartition_prod; track repart) {
                                    @if (repart.id_consommateur === pa.id) {
                                      <ion-col>{{formatteNombre(repart.energie, 0) }} kWh</ion-col>
                                      <ion-col>{{formatteNombre(repart.part, 2) }} %</ion-col>
                                    }
                                  }
                                }
                              }
                          }
                        </ion-row>
                        <ion-row class="bord-b bord-t">          
                          <ion-col>Surplus</ion-col>
                          @for (paProd of sc.bilanEnergetique.indicateurs.participants; track paProd) {
                            @if (paProd.total_prod > 0 && paProd.repartition_prod ) {
                              <ion-col>{{formatteNombre(paProd.surplus_acc, 0) }} kWh</ion-col>
                              <ion-col>{{formatteNombre((paProd.surplus_acc / paProd.total_prod) * 100 , 2) }} %</ion-col>
                            }
                          }
                        </ion-row>
                        <ion-row >          
                          <ion-col><strong>TOTAL</strong></ion-col> 
                          @for (paProd of sc.bilanEnergetique.indicateurs.participants; track paProd) {
                            @if (paProd.total_prod > 0 && paProd.repartition_prod ) {
                              <ion-col><strong>{{formatteNombre(paProd.total_prod, 0) }} kWh</strong></ion-col>
                              <ion-col><strong>{{paProd.totalPartRepart + ((paProd.surplus_acc / paProd.total_prod) * 100) | number : '1.0-0' }} %</strong></ion-col>
                            }
                          }
                        </ion-row>
                      </ion-grid>
                    </ion-list>
                  </ion-card-content>
                </ion-card>
                } @else {
                  <p>Le bilan énergétique n'a pas encore été calculé ...</p>
                }
              </cdk-step>
              <cdk-step #step4_2 label="Facturation"> <!-- Scénario {{i}} => BILAN FACTURATION !!! -->
                @if (sc.bilanFacturation) {
                  @if (sc.bilanFacturation.indicateurs[0]) {
                  <ion-card color="light" *ngFor="let indic of sc.bilanFacturation.indicateurs">
                    <ion-card-header>
                      <ion-card-title>{{ getParticipant(indic.id_participant)?.nom }} ({{ CatTurpeParticipant(indic.id_participant)}}) </ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                      <ion-grid class="tableau-facturation">
                        <ion-row class="bordure">
                          <ion-col size="3" class="facturation-titre"><ion-title class="ion-text-center tableau-facturation" style="font-size:0.9em;">Facture annuelle</ion-title></ion-col>
                          <ion-col size="6" class="bord-gd facturation-titre bord-double-d"><ion-title class="ion-text-center tableau-facturation">Hypothèse scénario</ion-title></ion-col>
                          <ion-col size="3" class="facturation-titre"><ion-title class="ion-text-center tableau-facturation">sans PV</ion-title></ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b">
                          <ion-col class="tfc bord-gd" size="2" offset="3">Réseau</ion-col>
                          <ion-col class="tfc bord-d" size="2">ACC</ion-col>
                          <ion-col class="tfc bord-double-d" size="2">ACI</ion-col>
                          <ion-col class="tfc" size="3">Réseau</ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b bord-double-bas">
                          <ion-col size="3">Energie (kWh)</ion-col>
                          <ion-col size="2" class="tfd bord-gd"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.energie_consommee?.allo, 0) }}</strong></ion-col>
                          <ion-col size="2" class="tfd bord-d"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.energie_consommee?.ACC, 0) }}</strong></ion-col>
                          <ion-col size="2" class="tfd bord-double-d"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.energie_consommee?.ACI, 0) }}</strong></ion-col>
                          <ion-col size="3" class="tfd"><strong>{{ formateBilanFacturation(indic?.sans_solaire?.energie_consommee?.allo, 0) }}</strong></ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b">
                          <ion-col size="3" class="facturation-vert">Fourniture (€)</ion-col>
                          <ion-col size="2" class="tfd bord-gd"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.fourniture?.details?.alloconso?.montant, 0) }}</strong></ion-col>
                          <ion-col size="2" class="tfd bord-d"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.fourniture?.details?.autoconso?.montant, 0) }}</strong></ion-col>
                          <ion-col size="2" class="tfd bord-double-d"><strong>{{ formateBilanFacturation(0, 0) }}</strong></ion-col>
                          <ion-col size="3" class="tfd"><strong>{{ formateBilanFacturation(indic?.sans_solaire?.fourniture?.montant, 0) }}</strong></ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b">
                          <ion-col size="3" class="facturation-vert">C. soutirage variable (€)</ion-col>
                          
                          <ion-col size="2" class="tfd bord-gd"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.turpe?.details?.CSV?.allo?.montant, 0) }}</strong></ion-col>
                          <ion-col size="2" class="tfd bord-d"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.turpe?.details?.CSV?.auto?.montant, 0) }}</strong></ion-col>
                          <ion-col size="2" class="tfd bord-double-d"><strong>{{ formateBilanFacturation(0, 0) }}</strong></ion-col>
                          <ion-col size="3" class="tfd"><strong>{{ formateBilanFacturation(indic?.sans_solaire?.turpe?.details?.CSV?.allo?.montant, 0) }}</strong></ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b bord-double-bas">
                          <ion-col size="3" class="facturation-vert">Accise (€)</ion-col>
                          
                          <ion-col size="2" class="tfd bord-gd"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.taxes?.details?.accise?.details?.allo?.montant, 0) }}</strong></ion-col>
                          <ion-col size="2" class="tfd bord-d"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.taxes?.details?.accise?.details?.acc?.montant, 0) }}</strong></ion-col>
                          <ion-col size="2" class="tfd bord-double-d"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.taxes?.details?.accise?.details?.aci?.montant, 0) }}</strong></ion-col>
                          <ion-col size="3" class="tfd"><strong>{{ formateBilanFacturation(indic?.sans_solaire?.taxes?.details?.accise?.montant, 0) }}</strong></ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b">
                          <ion-col size="3" class="facturation-bleu">Gestion & Comptage (€)</ion-col>
                          <ion-col class="tfd bord-g " size="2">&nbsp;</ion-col>
                          <ion-col class="tfd" size="2"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.turpe?.details?.CGC?.montant, 0) }}</strong></ion-col>
                          <ion-col class="tfd bord-double-d" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd" size="3"><strong>{{ formateBilanFacturation(indic?.sans_solaire?.turpe?.details?.CGC?.montant, 0) }}</strong></ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b">
                          <ion-col size="3" class="facturation-bleu">CTA (€)</ion-col>
                          <ion-col class="tfd bord-g" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd" size="2"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.taxes?.details?.CTA?.montant, 0) }}</strong></ion-col>
                          <ion-col class="tfd bord-double-d" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd" size="3"><strong>{{ formateBilanFacturation(indic?.sans_solaire?.taxes?.details?.CTA?.montant, 0) }}</strong></ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b bord-double-bas">
                          <ion-col size="3" class="facturation-bleu">C. Soutirage fixe (€)</ion-col>
                          <ion-col class="tfd bord-g" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd" size="2"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.turpe?.details?.CSF?.montant, 0) }}</strong></ion-col>
                          <ion-col class="tfd bord-double-d" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd" size="3"><strong>{{ formateBilanFacturation(indic?.sans_solaire?.turpe?.details?.CSF?.montant, 0) }}</strong></ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b">
                          <ion-col size="3">TOTAL HTVA (€)</ion-col>
                          <ion-col class="tfd bord-g" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd" size="2"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.montant?.HTVA, 0) }}</strong></ion-col>
                          <ion-col class="tfd bord-double-d" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd" size="3"><strong>{{ formateBilanFacturation(indic?.sans_solaire?.montant?.HTVA, 0) }}</strong></ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b bord-double-bas">
                          <ion-col size="3">TVA (€)</ion-col>
                          <ion-col class="tfd bord-g" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd" size="2"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.montant?.TVA, 0) }}</strong></ion-col>
                          <ion-col class="tfd bord-double-d" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd" size="3"><strong>{{ formateBilanFacturation(indic?.sans_solaire?.montant?.TVA, 0) }}</strong></ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center bord-gd bord-b">
                          <ion-col size="3" class="facturation-gris">TOTAL TTC (€)</ion-col>
                          <ion-col class="tfd bord-g facturation-gris" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd facturation-gris" size="2"><strong>{{ formateBilanFacturation(indic?.avec_solaire?.montant?.TTC, 0) }}</strong></ion-col>
                          <ion-col class="tfd bord-double-d facturation-gris" size="2">&nbsp;</ion-col>
                          <ion-col class="tfd facturation-gris" size="3"><strong>{{ formateBilanFacturation(indic?.sans_solaire?.montant?.TTC, 0) }}</strong></ion-col>
                        </ion-row>
                      </ion-grid>
                    </ion-card-content>
                  </ion-card>
                  } @else {
                    <p>Aucun consommateur n'a été renseigné dans le scénario...</p>
                  }
                } @else {
                  <p>Le bilan facturation n'a pas encore été calculé...</p>
                }
              </cdk-step>
              <cdk-step #step4_3 label="Financier"> <!-- Scénario {{i}} => BILAN FINANCIER !!! -->
                @if (sc.bilanFinancier) {
                  <app-bilan-financier [i]=i [bilanFinancier]=sc.bilanFinancier></app-bilan-financier>
                } @else {
                  <p>Le bilan financier n'a pas encore été calculé...</p>
                }
              </cdk-step>
            </tpv-stepper>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </cdk-step>
  </tpv-stepper>
} @else {
<ion-header>
  <ion-toolbar>
    <ion-title>
      <div class="ion-float-left" style="margin-top: 8px">Liste des Projets</div>
      <ion-button size="small" class="ion-float-right"  (click)="dialogueCreationProjet()"><ion-icon name="add-circle-outline"></ion-icon>&nbsp;Nouveau Projet</ion-button>
    </ion-title>
    <!-- <ion-searchbar placeholder="Filter les projets ..."></ion-searchbar>
    <ion-segment>
      <ion-segment-button value="aucun"><ion-icon name="eye-sharp"></ion-icon></ion-segment-button>
      <ion-segment-button value="nom">Nom</ion-segment-button>
      <ion-segment-button value="date">Création</ion-segment-button>
    </ion-segment> -->
  </ion-toolbar>
</ion-header>
<ion-grid>
  <ion-row *ngFor="let p of listeProjets" class="ligne-projet cursor-pointer" (click)="afficheProjet(p.id)">
    <ion-col *ngIf="! EnvironnementProd()">ID : {{ p.id }} </ion-col>
    <ion-col><ion-label color="{{getColorType(p.type)}}">{{p.type}}</ion-label></ion-col>
    <ion-col>{{p.libelle}}</ion-col>
    <ion-col>{{p.nbConsommateurs}} consommateur(s)</ion-col>
    <ion-col >{{ p.user.prenom }} {{ p.user.nom }}</ion-col>
    <ion-col>
      <ion-button size="small" class="ion-float-right" color="danger" (click)="supprimeProjet($event, p.id, p.libelle)" style="margin-right: 8px;">
        <ion-icon name="trash-outline" color="light"></ion-icon>
      </ion-button>
<!--
      <ion-button href="/projet/{{p.id}}" size="small" class="ion-float-right" style="margin-right: 8px;"><ion-icon name="eye-sharp"></ion-icon></ion-button>
-->
    </ion-col>
  </ion-row>
</ion-grid>
}
<ion-loading #calculEnCours spinner="circles" message="Calcul en cours ..."></ion-loading>
</ion-content>